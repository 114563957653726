import React, { useState, useEffect } from 'react';
import './AgendaAmbiente.scss'
import './../../main/ultil.scss'
import Loading from './../../components/templates/Loading'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Alert from './../../components/templates/Alert'
import { Grid, Button, TextField, Slider } from '@mui/material'
import moment from 'moment'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ModalAgendarServico from './../../components/modals/AgendarServico'
import axios from 'axios'
import ModalBuscaAgendaCliente from './../../components/modals/BuscaAgendaCliente'
import ModalSelecionaPessoa from './../../components/modals/SelecionaPessoa'
import SelecionarVoucher from './../../components/modals/SelecionarVoucher'
import SelecionarVoucherCredito from './../../components/modals/SelecionarVoucherCredito'
import ModalConfirmacao from './../../components/modals/Confirmacao'
import ModalConfirmacaoAgendaIndisponivel from './../../components/modals/ConfirmacaoAgendaIndisponivel'
import ModalConfirmacaoData from './../../components/modals/ConfirmacaoData'
import ModalConfirmacaoDifVoucher from './../../components/modals/ConfirmacaoDifVoucher'
import ModalConfirmacaoCortesia from './../../components/modals/Confirmacao'
import ModalConfirmacaoCortesiaSalvar from './../../components/modals/Confirmacao'
import ModalValoresTabelaDif from './../../components/modals/ValoresTabelaDif'
import ModalConfirmarUsoVoucher from './../../components/modals/ConfirmarUsoVoucher'
import ModalFinanceiroPendenteCliente from './../../components/modals/FinanceiroPendenteCliente'
import real from './../../services/real'
import moeda from './../../services/moeda'
import PersonIcon from '@mui/icons-material/Person';
import ModalCancelarNFSE from './../../components/modals/CancelarNFSE'
import ModalSelecionarProdutosVoucher from './../../components/modals/SelecionarProdutosVoucher'
import { format } from 'cnpj'
import Autocomplete from '@mui/material/Autocomplete';
import ModalAgendaAtendente from './../../components/modals/AgendaAtendente'
import ModalAjuda from './../../components/modals/Ajuda'
import ModalAjudaCadastro from './../../components/modals/Ajuda'
import { useNavigate } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const cpf = require('node-cpf-cnpj');

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

function formataTelefone(numero) {

  if(numero){
    let texto = numero.trim()

		texto = texto.replace(/\D/g,""); 
    
    if(texto.length === 11 || texto.length === 10){
      texto = texto.replace(/^(\d{2})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 12){
      texto = texto.replace(/^(\d{3})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 8){
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }
  
    return texto
  }

  return ''
}

const initialState = {
  loading: true,
  mensagemLoading: 'Carregando Agendas...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  larguraColunas: 150,
  arrayDias: [],
  dataSelecionada: '',
  horas: [],
  agendas: {
    list: []
  },
  agendasFiltrada: {
    list: []
  },
  intervalo: 0,
  inicio_atendimento: '',
  fim_atendimento: '',
  openModalAgendarServico: false,
  agenda:{
    ordem_id: '',
    cliente_id: '',
    cliente: '',
    situacao: 'CONFIRMADO',
    observacao_geral: '',
    servicos: [],
    voucher_id: '',
    voucher: '',
    informar_financeiro: false,
  },
  agendaEditar: {
    ordem: 1,
    data_inicio: '',
    data_fim: '',
    hora_inicio: '',
    hora_fim: '',
    servico: '',
    atendente: '',
    cliente: '',
    ambiente: '',
    pularLinha: 0,
    situacao: 'CONFIRMADO',
    background_color: '',
    color: '',
    duracao: 0,
    duracao_tela: 0,
    informar_financeiro: false,
    agenda_id: '',
    item_pedido_id: ''
  },
  servicoAgenda: {
    servico: '',
    servico_id: '',
    atendente: '',
    atendente_id: '',
    ambiente: '',
    ambiente_id: '',
    data_inicio: '',
    hora_inicio: '',
    hora_inicio_tela: '',
    hora_fim: '',
    hora_fim_tela: '',
    duracao: 0,
    duracao_tela: 0,
    background_color: '',
    color: '',
    observacao: '',
    index: '',
    obrigatoriedade: false,
    valor: real(0),
    valor_total_minimo: real(0),
    valor_total_maximo: real(0),
    itemCombo: [],
    situacao: 'CONFIRMADO',
    agenda_id: '',
    voucher: false,
    recorrencia: false,
    enviar_alerta: false,
    add_preparo: false,
    add_limpeza: false,
    duracao_preparo: 0,
    duracao_limpeza: 0,
    preparo_id: '',
    limpeza_id: '',
    index_preparo: '',
    index_limpeza: '',
    dadosRecorrencia: {
      recorrenciaACada: 1,
      tipoRecorrencia: 'd',
      qtdRecorrencia: ''
    }
  },
  clientes:{
    list:[]
  },
  clientesFiltrado: {
    list: []
  },
  cliente: {
    id: '',
    nome: '',
    cpf_cnpj: '',
    pessoa_id: ''
  },
  servicos:{
    list: []
  },
  servico: {
    id: '',
    nome: '',
    unidademedida: '',
    duracao: 0
  },
  salas: {
    list: []
  },
  sala: {
    id: '',
    nome: '',
    descricao: ''
  },
  atendentes:{
    list: []
  },
  atendente:{
    nome: '',
    background_color: '',
    color: ''
  },
  atendentesFiltro: {
    list: []
  },
  filtro_atendente: {
    atendente_id: ''
  },
  alteraServico: false,
  loadingSalva: false,
  openModalAlterarAgenda: false,
  modalOpenConfirmacaoData: false,
  modalConfirmarAgendaIndisponivel: false,
  situacoes:['AGENDADO', 'CONFIRMADO', 'EM ATENDIMENTO', 'ATRASADO', 'DESMARCADO', 'FALTOU', 'FINALIZADO', 'CANCELADO'],
  travaUnidades: false,
  msgTrava: '',
  modalSelecionaPessoa: false,
  filtro: {
    nome: "",
    cpf_cnpj: "",
    flag: "",
    ativo: 'Sim',
    filtrar_por: "Nome - nome",
    value: '',
    limit: 10,
    offset: 0
  },
  loadingPessoas: false,
  filtrocliente: {
    label: 'Nome',
    name:'nome'
  },
  ambientes:{
    list: []
  },
  tab:0,
  conta: {
    id: '',
    valor_total: '0,00',
    valor_total_minimo: real(0),
    valor_total_maximo: real(0),
    qtd_parcelas: 1,
    gerar_nfse: false,
    nfse_id: "",
    parcelas: [
      {
        id: '',
        baixado: true,
        datavencimento: moment(new Date()).format('YYYY-MM-DD'),
        datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
        valorparcela: real(0),
        valorparcela_anterior: real(0),
        multajuros: real(0),
        formapagto_id: "",
        contfin_id: "",
        documento: "",
        habilita: "",
        num: 1,
        parcela_editada: false,
        index: 0,
        trava: false,
        vouchersCreditoSelecionados: [],
        vouchersCreditoSelecionadosString: '',
        vouchersProdutosSelecionados: [],
        vouchersProdutosSelecionadosString: ''
      }
    ]
  },
  conta_anterior: {
    id: '',
    valor_total: '0,00',
    valor_total_minimo: real(0),
    valor_total_maximo: real(0),
    qtd_parcelas: 1,
    gerar_nfse: false,
    nfse_id: "",
    parcelas: [
      {
        id: '',
        baixado: true,
        datavencimento: moment(new Date()).format('YYYY-MM-DD'),
        datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
        valorparcela: real(0),
        valorparcela_anterior: real(0),
        multajuros: real(0),
        formapagto_id: "",
        contfin_id: "",
        documento: "",
        habilita: "",
        num: 1,
        parcela_editada: false,
        index: 0,
        trava: false,
        vouchersCreditoSelecionados: [],
        vouchersCreditoSelecionadosString: '',
        vouchersProdutosSelecionados: [],
        vouchersProdutosSelecionadosString: ''
      }
    ]
  },
  tabela_preco: {
    id: '',
    preco_produto: {
      id: '',
      valor_maximo: '',
      valor_minimo: ''
    }
  },
  formasPagamento: {
    list: []
  },
  contasFinanceira: {
    list: []
  },
  financeiro_fechado: false,
  modal: {
    mensagem: '',
  },
  modalOpen: false,
  coresStatus: [
    {
      status: 'AGENDADO',
      cor_fundo: '#ffff00',
      cor_fonte: '#000' 
    },
    {
      status: 'CONFIRMADO',
      cor_fundo: '#00a933',
      cor_fonte: '#000' 
    },
    {
      status: 'EM ATENDIMENTO',
      cor_fundo: '#2a6099',
      cor_fonte: '#FFF' 
    },
    {
      status: 'ATRASADO',
      cor_fundo: '#800000',
      cor_fonte: '#FFF' 
    },
    {
      status: 'FINALIZADO',
      cor_fundo: '#FFF',
      cor_fonte: '#000' 
    },
  ],
  qtdParcelasEditado: 0,
  vouchersCliente: [],
  vouchersCreditoCliente: [],
  openModalBuscarVoucher: false,
  loadingVouchers: false,
  filtroVoucher: {
    voucher: ''
  },
  vouchers: {
    list: []
  },
  voucherSelecionado: {},
  mostrarFinanceiro: true,
  openModalSelecionarVoucher: false,
  editandoAgenda: false,
  permissoesContasPR: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesCaixa: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  perfilLogado: '',
  ultimoCaixa: {},
  rows: 0,
  travarBotao: false,
  dataSelecionadaTela: '',
  hora_atual: moment().format('HH:mm'),
  openModalBuscaAgendaCliente: false,
  agendasCliente: {
    list: []
  },
  filtroAgendaCliente: {
    data_inicio: '',
    data_fim: ''
  },
  modalOpenConfirmacaoDifVoucher: false,
  produtos_diff: [],
  dadosTelaVoucherCredito: {
    totalConta: real(0),
    totalVouchers: real(0),
    diferenca: real(0),
    parcela_index: 0
  },
  vouchersCreditoSelecionados: [],
  vouchersProdutosSelecionados: [],
  possui_emissao_nfse: false,
  openModalCancelarNFSE: false,
  dadosCancelar: {
    id:'',
    motivo: '',
  },
  motivosCancelamento: {
    list: []
  },
  modalConfirmacaoCortesia: false,
  modalConfirmacaoCortesiaSalvar: false,
  valor_servico_dif: [],
  modalValoresTabelaDif: false,
  produtos_voucher: [],
  modalSelecionarProdutosVoucher: false,
  servicos_selecionados: [],
  swapServicos: 'todos',
  conta_conciliada: false,
  servicos_voucher_agendamento: [],
  modalConfirmarUsoVoucher: false,
  row: {},
  utilizar_voucher: false,
  ambientesSelecionadas: [],
  openModalAgendaAtendente: false,
  agendaView: {
    agendado_por: '',
    ambiente: '',
    atendente: '',
    cliente: '',
    data_inicio: '',
    data_fim: '',
    duracao: '',
    obrigatoriedade: false,
    observacao: '',
    observacao_geral: '',
    ordem: '',
    servico: '',
    situacao: 'CONFIRMADO'
  },
  openModalSelecionarVoucherCredito: false,
  consultaRealizada: false,
  perm_agenda_sem_atend: false,
  agendas_indisponivel: [],
  openModalAjuda: false,
  markdown: '',
  openModalAjudaCadastro: false,
  markdownCadastro: '',
  ambientesFiltrado:{
    list: []
  },
  enviar_alerta: false,
  perm_alt_voucher_agenda: false,
  exibir_preparo_limpeza_agenda: false,
  financeiro_pendente: [],
  exibir_ids_pessoas: false
}

let pulaLinha = 1

const AgendaAmbiente = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const voltar = () =>{
    const {arrayDias} = state

    arrayDias.pop()

    arrayDias.unshift(moment(arrayDias[0]).subtract(1, 'd').format('YYYY-MM-DD'))

    setState((prevState) => ({
      ...prevState,
      arrayDias
    }))
  }

  const proximo = () => {
    const {arrayDias} = state

    arrayDias.shift()

    arrayDias.push(moment(arrayDias[arrayDias.length - 1]).add(1, 'd').format('YYYY-MM-DD'))

    setState((prevState) => ({
      ...prevState,
      arrayDias
    }))
  }

  const selecionarDia = async (dia) => {

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
    await consultarAgendas(acesso_atual[0].id, dia, state.intervalo)

    // montaListaAtendentes()

    localStorage.setItem('data_filtro_agenda', JSON.stringify(dia))
    localStorage.setItem('hora_filtro_agenda', JSON.stringify(state.inicio_atendimento))

    setState((prevState) => ({
      ...prevState,
      dataSelecionada: dia,
      dataSelecionadaTela: dia
    }))

  }

  const montaListaAtendentes = (agendas) => {

    // const {agendas} = state

    let atendentesFiltro = []

    for (const agenda of agendas) {
      const index = atendentesFiltro.findIndex(param => param.atendente_id === agenda.atendente_id)

      if(index === -1){
        atendentesFiltro.push({
          atendente_id: agenda.atendente_id,
          nome: agenda.atendente
        })
      }
    }

    setState((prevState) => ({
      ...prevState,
      atendentesFiltro:{
        list:atendentesFiltro
      },
      filtro_atendente: {
        atendente_id: ''
      }
    }))
  }

  const consultarAgendas = async (unidade_id, dataSelecionada, intervalo) => {
    try {
      const dados = {
        unidadesnegocio: [unidade_id],
        dataSelecionada
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/agendaAmbiente/list`, dados, getToken())

      const agendas = data.map(agenda => {
        let itensCombo = []
        if(agenda.item_pedido.produto.unidademedida === 'COMBO'){

          itensCombo = agenda.item_pedido.produto.itens_combo.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(item => {
            return {
              nome: item.produtoitem.descricao,
              duracao: item.produtoitem.servico.duracao * item.quantidade
            }
          })
        }

        return {
          id: agenda.id,
          obrigatoriedade: agenda.obrigatoriedade,
          data_inicio: moment(agenda.data_hora_inicio).format('YYYY-MM-DD HH:mm'),
          data_fim: moment(agenda.data_hora_fim).format('YYYY-MM-DD HH:mm'),
          ambiente_id: agenda.ambiente_id,
          ambiente: agenda.ambiente.nome,
          ordem: agenda.item_pedido.pedido_servico.numero,
          pedido_id: agenda.item_pedido.pedido_servico.id,
          situacao: agenda.situacao,
          pessoa_id: agenda.item_pedido.pedido_servico.pessoa.id,
          cliente: agenda.item_pedido.pedido_servico.pessoa.tipo === 'FISICA' ? (agenda.item_pedido.pedido_servico.pessoa.fisica.nome + ' ' + (agenda.item_pedido.pedido_servico.pessoa.fisica.sobrenome || '')) : (agenda.item_pedido.pedido_servico.pessoa.juridica.nomefantasia),
          cpf_cnpj: agenda.item_pedido.pedido_servico.pessoa.tipo === 'FISICA' ? agenda.item_pedido.pedido_servico.pessoa.fisica.cpf : agenda.item_pedido.pedido_servico.pessoa.juridica.cnpj,
          servico_id: agenda.item_pedido.produto.id,
          servico: agenda.item_pedido.produto.descricao,
          duracao: agenda.item_pedido.duracao,
          atendente_id: agenda.funcionario ? agenda.funcionario.id : null,
          atendente: agenda.funcionario ? (agenda.funcionario.fisica.nome + ' ' + (agenda.funcionario.fisica.sobrenome || '')) : null,
          pularLinha: agenda.item_pedido.duracao / intervalo,
          observacao_geral: agenda.item_pedido.pedido_servico.observacao || '',
          observacao: agenda.observacao || '',
          background_color: agenda.funcionario ? agenda.funcionario.cor_fundo : '#C0C0C0',
          color: agenda.funcionario ? agenda.funcionario.cor_fonte: '#000',
          agendado_por: agenda.item_pedido.pedido_servico.usuario.pessoa.fisica.nome,
          itensCombo,
          financeiro_fechado: agenda.item_pedido.pedido_servico.contapr_id ? true: false
        }
      })

      setState((prevState) => ({
        ...prevState,
        agendas: {
          list: agendas
        },
        agendasFiltrada: {
          list: agendas
        }
      }))

      montaListaAtendentes(agendas)

      return agendas

    } catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldData = (e) => {
    let dataSelecionada = e.target.value

    setState((prevState) => ({...prevState, dataSelecionada}))
  }

  const carregarAgendas = async (e) =>{
    let arrayDias = []
    let data3Dias = moment(e.target.value).subtract(3, 'd').format('YYYY-MM-DD')
    let dataSelecionada = e.target.value

    arrayDias.push(data3Dias)

    for (let i = 0; i < 6; i++) {
      arrayDias.push(moment(data3Dias).add((i+1), 'd').format('YYYY-MM-DD'))
    }

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
    let agendas = await consultarAgendas(acesso_atual[0].id, dataSelecionada, state.intervalo)

    montaListaAtendentes(agendas)

    localStorage.setItem('data_filtro_agenda', JSON.stringify(dataSelecionada))
    localStorage.setItem('hora_filtro_agenda', JSON.stringify(state.inicio_atendimento))

    setState((prevState) => ({...prevState,arrayDias, dataSelecionadaTela: dataSelecionada}))
  }

  const updateFiltroAtendente = (event) => {
    let {filtro_atendente, agendas, agendasFiltrada} = state

    filtro_atendente[event.target.name] = event.target.value

    if(event.target.value !== ''){
      agendasFiltrada = {
        list: agendas.list.filter(param => param.atendente_id === parseInt(event.target.value))
      }
    }else{
      agendasFiltrada = {
        list: agendas.list
      }
    }

    setState((prevState) => ({...prevState, filtro_atendente, agendasFiltrada}))
  }

  const updateFiltroStatus = (event) => {
    let {filtro_atendente, agendas, agendasFiltrada} = state

    filtro_atendente[event.target.name] = event.target.value

    if(event.target.value !== ''){
      agendasFiltrada = {
        list: agendas.list.filter(param => param.situacao === event.target.value)
      }
    }else{
      agendasFiltrada = {
        list: agendas.list
      }
    }

    setState((prevState) => ({...prevState, filtro_atendente, agendasFiltrada}))
  }

  const updateFieldAmbiente = (event, value) => {

    if(value.length === 0){
      setState((prevState) => ({
        ...prevState,
        ambientesFiltrado:{
          list: state.ambientes.list
        }
      }))
    }else{
      setState((prevState) => ({
        ...prevState,
        ambientesFiltrado:{
          list: value
        }
      }))
    }

    setState((prevState) => ({
      ...prevState,
      ambientesSelecionadas: value
    }))
  }

  const openConsultaCliente = async () => {
    setState((prevState) => ({
      ...prevState,
      openModalBuscaAgendaCliente: true
    }))
  }

  const handleChangeLarguraAgenda = (event, newValue) => {
    localStorage.setItem('larguraColunas', JSON.stringify(newValue))

    setState((prevState) => ({
      ...prevState,
      larguraColunas: newValue
    }))
  }

  const renderDiv = (hora, ambiente, dataSelecionadaTela) => {
    let {agendasFiltrada, hora_atual, coresStatus, permissoes, intervalo, exibir_ids_pessoas} = state

    let agenda = agendasFiltrada.list.filter(param => param.data_inicio === `${dataSelecionadaTela} ${hora}` && param.ambiente_id === ambiente.id)[0] || null
   
    if(agenda){
      pulaLinha = agenda.pularLinha

      return (
        <div 
          id={'agenda-'+agenda.id}
          className="grid-item" 
          style={{
            height: 30 * pulaLinha + 'px', 
            padding: '3px',
            borderBottom: (
              parseInt(hora.replace(':', '')) >= parseInt(hora_atual.replace(':','')) 
              && 
              parseInt(hora.replace(':', '')) <= (parseInt(hora_atual.replace(':','')) + (intervalo - 1))) 
              ? (dataSelecionadaTela === moment().format('YYYY-MM-DD') ? '2px solid red' : '')  : '',
          }}>
          <div 
            onClick={permissoes.alterar ? () => openAgenda(agenda) :  () => openAgendaAtendente(agenda)}
            style={{
              height: '100%',
              overflow: 'auto',
              backgroundColor: `${agenda.background_color}`, 
              color: `${agenda.color}`,
              border: (agenda.observacao !== '' || agenda.observacao_geral !== '') ? '3px dashed #000' : 'none',
              cursor: 'pointer',
              padding: '3px',
              fontWeight: 'bold',
              position: 'relative'
            }}
          >
            {agenda.financeiro_fechado &&
              <AttachMoneyIcon 
                style={{
                  position: 'absolute',
                  top: 0,
                  right: -8,
                  opacity: 0.4,
                  fontSize: '40px',
                  pointerEvents: 'none'
                }}
              />
            }
            
            <p style={{fontSize: '12px', marginBottom: '5px'}}>
              <span className='situacao' 
                style={{ backgroundColor: `${coresStatus.filter(param => param.status === agenda.situacao)[0].cor_fundo}`, color: `${coresStatus.filter(param => param.status === agenda.situacao)[0].cor_fonte}` }}>
                  {agenda.ordem} - {agenda.situacao}
              </span>
            </p>
            <p style={{fontSize: '12px', marginBottom: '5px', color: '#000'}}>{exibir_ids_pessoas ? `${agenda.pessoa_id} - ` : ''}{agenda.cliente}</p>
            
            {agenda.itensCombo.length > 0 &&
              <React.Fragment>
                {agenda.itensCombo.map((value, key) => {
                  return (
                    <p key={key}>{value.nome} - {value.duracao} Min.</p>
                  )
                })}
              </React.Fragment>
            }
            {agenda.itensCombo.length === 0 &&
              <p style={{fontSize: '12px', marginBottom: '10px', color: '#000'}}>{agenda.servico} - {agenda.duracao} Min.</p>
            }
            {agenda.atendente &&
              <React.Fragment>
                <p style={{marginBottom: '0px', fontSize: '12px'}}>Fisio: {agenda.atendente}</p>
                <p style={{marginBottom: '20px', fontSize: '12px'}}>{agenda.obrigatoriedade ? 'Obrigatório': 'Não Obrigatório'}</p>
              </React.Fragment>
            }
            <p style={{marginTop: '10px', bottom: '5px', left: '10px', fontSize: '12px', marginBottom: '0px'}}>Por: {agenda.agendado_por}</p>
          </div>
        </div>
      )
    }else{
      // if(ambiente.nome === '1 Acqua'){
      //   console.log('hora: ', hora)
      //   console.log('pulaLinha: ', pulaLinha)
      // }   
      if(pulaLinha === 1){
        pulaLinha = 1
        return (
          <div 
            className="grid-item"
            style={{
              borderBottom: (
                parseInt(hora.replace(':', '')) >= parseInt(hora_atual.replace(':','')) 
                && 
                parseInt(hora.replace(':', '')) <= (parseInt(hora_atual.replace(':','')) + (intervalo - 1))) 
                ? (dataSelecionadaTela === moment().format('YYYY-MM-DD') ? '2px solid red' : '')  : '',
            }}
          >
            <p></p>
          </div>
        )
      }else{
        pulaLinha = pulaLinha - 1
      }
    }
  }

  const openAgenda = async (agenda) => {

    setState((prevState) => ({
      ...prevState,
      editandoAgenda: true,
      dadosTelaVoucherCredito: {
        totalConta: real(0),
        totalVouchers: real(0),
        diferenca: real(0)
      },
      vouchersCliente: [],
      voucherSelecionado: {},
      conta_conciliada: false,
      conta_anterior: {
        id: '',
        valor_total: '0,00',
        valor_total_minimo: real(0),
        valor_total_maximo: real(0),
        qtd_parcelas: 1,
        gerar_nfse: false,
        nfse_id: "",
        parcelas: [
          {
            id: '',
            baixado: true,
            datavencimento: moment(new Date()).format('YYYY-MM-DD'),
            datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
            valorparcela: real(0),
            valorparcela_anterior: real(0),
            multajuros: real(0),
            formapagto_id: "",
            contfin_id: "",
            documento: "",
            habilita: "",
            num: 1,
            parcela_editada: false,
            index: 0,
            trava: false,
            vouchersCreditoSelecionados: [],
            vouchersCreditoSelecionadosString: '',
            vouchersProdutosSelecionados: [],
            vouchersProdutosSelecionadosString: ''
          }
        ]
      },
      valor_servico_dif: [],
      produtos_voucher: []
    }))

    await consultaServicos()

    try {
      const {data} = await axios.get(`${window._env_.REACT_APP_API_URL}/agendaAmbiente/${agenda.pedido_id}`, getToken())

      let servicos = []

      let index = 0

      for (const item of data.itens_pedido) {
        if(item.agenda_ambiente.situacao !== "DESMARCADO"){
          servicos.push({
            servico: item.produto.descricao,
            servico_id: item.produto.id,
            atendente: item.agenda_ambiente.funcionario ? item.agenda_ambiente.funcionario.fisica.nome : '',
            atendente_id: item.agenda_ambiente ? item.agenda_ambiente.funcionario_id : null,
            ambiente: item.agenda_ambiente.ambiente.nome,
            ambiente_id: item.agenda_ambiente.ambiente_id,
            data_inicio: moment(item.agenda_ambiente.data_hora_inicio).format('YYYY-MM-DD'),
            hora_inicio: moment(item.agenda_ambiente.data_hora_inicio).format('HH:mm'),
            hora_inicio_tela:  moment(item.agenda_ambiente.data_hora_inicio).format('HH:mm'),
            hora_fim:  moment(item.agenda_ambiente.data_hora_fim).format('HH:mm'),
            hora_fim_tela: moment(item.agenda_ambiente.data_hora_fim).format('HH:mm'),
            duracao: item.duracao,
            duracao_tela: item.duracao,
            background_color: '',
            color: '',
            observacao: item.agenda_ambiente.observacao,
            index,
            obrigatoriedade: item.agenda_ambiente.obrigatoriedade,
            valor: real(item.valor_servico),
            valor_total_minimo: item.tabela ? real(item.tabela.preco_produto.valor_minimo) : real(0),
            valor_total_maximo: item.tabela ? real(item.tabela.preco_produto.valor_maximo) : real(1000000),
            itemCombo: item.produto.itens_combo,
            situacao: item.agenda_ambiente.situacao,
            agenda_id: item.agenda_ambiente.id,
            item_pedido_id: item.id,
            editado: false,
            voucher: data.itens_voucher[0] ? (data.itens_voucher[0].voucher.padrao.habilita === 'PRODUTO' ? (data.itens_voucher[0].voucher.produtos.findIndex(param => param.produto_id === item.produto.id && param.valor === item.valor_servico && param.utilizado) !== -1 ? true : false) : false) : false,
            recorrencia: false,
            enviar_alerta: false,
            add_preparo: false,
            add_limpeza: false,
            duracao_preparo: 0,
            duracao_limpeza: 0,
            preparo_id: '',
            limpeza_id: '',
            index_preparo: '',
            index_limpeza: '',
            dadosRecorrencia: {
              recorrenciaACada: 1,
              tipoRecorrencia: 'd',
              qtdRecorrencia: ''
            }
          })
          index ++
        }

      }

      const agendaAux = {
        ordem_id: data.id,
        ordem: data.numero,
        cliente_id: data.pessoa_id,
        cliente: data.pessoa.tipo === 'FISICA' ? (data.pessoa.fisica.nome + (data.pessoa.fisica.sobrenome ? ` ${data.pessoa.fisica.sobrenome}`: '')) : data.pessoa.juridica.nomefantasia,
        observacao_geral: data.observacao,
        servicos,
        voucher_id: '',
        voucher: '',
        informar_financeiro: data.contapr_id ? true: false
      }

      await buscaVouchersCredito(data.pessoa_id, 'CREDITO')

      if(agendaAux.informar_financeiro){
        await consultarFormaPagto(state.perm_alt_voucher_agenda)
        await consultarContasFinanceira()
      }

      let conta = {
        id: '',
        valor_total: real(somarValores(data.itens_pedido.map(value => { return value.valor_servico}))),
        qtd_parcelas: 1,
        gerar_nfse: false,
        nfse_id: "",
        parcelas: [
          {
            id: '',
            baixado: true,
            datavencimento: "",
            datavencimento_orig: "",
            valorparcela: real(0),
            valorparcela_anterior: real(0),
            multajuros: real(0),
            formapagto_id: "",
            contfin_id: "",
            documento: "",
            habilita: "",
            num: 1,
            parcela_editada: false,
            index: 0
          }
        ]
      }
      
      let financeiro_fechado = false
      console.log(data)

      if(data.contapr_id){
        conta = {
          id: data.conta.id,
          valor_total: real(data.conta.valortotal),
          qtd_parcelas: data.conta.numparcela,
          gerar_nfse: data.conta.nfse_id ? true : false,
          nfse_id: data.conta.nfse_id,
          parcelas: data.conta.parcelas.sort((a, b) => (a.num > b.num) ? 1 : (b.num > a.num) ? -1 : 0).map((parcela, key) => {
            let vouchersCreditoSelecionados = []
            let vouchersCreditoSelecionadosString = ''
            let vouchersProdutosSelecionados = []
            let vouchersProdutosSelecionadosString = ''

            for (const valorpago of parcela.valores_pago) {
              if(valorpago.vouchers_pagamento.length > 0){
                if(valorpago.formapagto_id === 9){
                  for (const voucher_pagamento of valorpago.vouchers_pagamento) {
                    let item_voucher = data.itens_voucher.filter(param => param.voucher_id === voucher_pagamento.voucher_id)[0]
                    
                    if(item_voucher){
                      vouchersCreditoSelecionados.push({
                        voucher_id: item_voucher.voucher.id,
                        numero: item_voucher.voucher.numero,
                        saldo: item_voucher.voucher.saldo,
                        valor_a_ultilizar : parseFloat(item_voucher.valor_utilizado)
                      })
        
                      vouchersCreditoSelecionadosString += `${item_voucher.voucher.numero} - `
                    }else{
                      vouchersCreditoSelecionados.push({
                        voucher_id: voucher_pagamento.voucher_id,
                        numero: voucher_pagamento.voucher.numero,
                        saldo: voucher_pagamento.voucher.saldo,
                        valor_a_ultilizar : parseFloat(voucher_pagamento.valor_utilizado)
                      })
        
                      vouchersCreditoSelecionadosString += `${voucher_pagamento.voucher.numero} - `
                    }
                  }
                }else if(valorpago.formapagto_id === 11){
                  for (const voucher_pagamento of valorpago.vouchers_pagamento) {
                    vouchersProdutosSelecionados.push({
                      produto_voucher_id: voucher_pagamento.produto_voucher_id,
                      numero: voucher_pagamento.voucher.numero,
                      valor: voucher_pagamento.valor_utilizado,
                      valor_a_ultilizar: voucher_pagamento.valor_utilizado
                    })
                    
                    vouchersProdutosSelecionadosString += `${voucher_pagamento.voucher.numero} - `
                  }
                }
              }
            }

            return {
              id: parcela.id,
              baixado: parcela.baixado,
              datavencimento: parcela.datavencimento,
              datavencimento_orig: parcela.datavencimento,
              valorparcela: real(parcela.valorparcela),
              multajuros: real(parcela.multajuros),
              formapagto_id: parcela.valores_pago[0].formapagto_id,
              contfin_id: parcela.valores_pago[0].contfin_id,
              documento: parcela.documento ? parcela.documento : '',
              habilita: parcela.valores_pago[0].forma_pagto.habilita,
              num: parcela.num,
              parcela_editada: false,
              index: key,
              vouchersCreditoSelecionados,
              vouchersCreditoSelecionadosString,
              vouchersProdutosSelecionados,
              vouchersProdutosSelecionadosString
            }
          })
        }

        const index = data.conta.parcelas.findIndex(param => param.baixado)

        financeiro_fechado = index === -1 ? false : true

        for (const parcela of data.conta.parcelas) {
          for (const valor_pago of parcela.valores_pago) {
            if(valor_pago.mov){
              if(valor_pago.mov.conciliado && valor_pago.forma_pagto.slug !== 'dinheiro'){
                setState((prevState) => ({
                  ...prevState,
                  conta_conciliada: true
                }))
              }
            }
          }
        }
      }

      let voucherSelecionado = {}

      if(data.itens_voucher.length > 0){
        if(data.itens_voucher[0].voucher.padrao.habilita === 'PRODUTO'){
          agendaAux.voucher_id = data.itens_voucher[0].voucher.id
          agendaAux.voucher = data.itens_voucher[0].voucher.padrao.nome  + ' - ' + data.itens_voucher[0].voucher.numero
        
          voucherSelecionado = {
            ...data.itens_voucher[0].voucher,
            valor_credito_form: real(data.itens_voucher[0].voucher.valor_credito),
            saldo_form: real(data.itens_voucher[0].voucher.saldo),
            novo_saldo: data.itens_voucher[0].voucher.saldo,
            novo_saldo_form: real(data.itens_voucher[0].voucher.saldo)
          }
  
          financeiro_fechado = true
        }else if(data.itens_voucher[1]){
          if(data.itens_voucher[1].voucher.padrao.habilita === 'PRODUTO'){
            agendaAux.voucher_id = data.itens_voucher[1].voucher.id
            agendaAux.voucher = data.itens_voucher[1].voucher.padrao.nome  + ' - ' + data.itens_voucher[1].voucher.numero
          
            voucherSelecionado = {
              ...data.itens_voucher[1].voucher,
              valor_credito_form: real(data.itens_voucher[1].voucher.valor_credito),
              saldo_form: real(data.itens_voucher[1].voucher.saldo),
              novo_saldo: data.itens_voucher[1].voucher.saldo,
              novo_saldo_form: real(data.itens_voucher[1].voucher.saldo)
            }
    
            financeiro_fechado = true
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        agenda: agendaAux,
        cliente: {
          id: data.pessoa_id,
          nome: data.pessoa.tipo === 'FISICA' ? (data.pessoa.fisica.nome + (data.pessoa.fisica.sobrenome ? ` ${data.pessoa.fisica.sobrenome}`: '')) : data.pessoa.juridica.nomefantasia,
          cpf_cnpj: data.pessoa.tipo === 'FISICA' ? data.pessoa.fisica.cpf : data.pessoa.juridica.cnpj,
          pessoa_id: data.pessoa_id
        },
        conta,
        conta_anterior: {
          ...conta
        },
        financeiro_fechado,
        voucherSelecionado,
        openModalAgendarServico: true
      }))

      const indexAgenda = agendaAux.servicos.findIndex(param => param.agenda_id === agenda.id)

      await editarServico(indexAgenda, agendaAux)

      const { data: motivos } = await axios.get(`${window._env_.REACT_APP_API_URL}/nfse/consultarMotivoCancelamento`, getToken())
      
      setState((prevState) => ({
        ...prevState,
        motivosCancelamento: {
          list: motivos
        }
      }))
    } catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }

  }

  const openAgendaAtendente = (agenda) => {
    setState((prevState) => ({
      ...prevState,
      agendaView: agenda,
      openModalAgendaAtendente: true
    }))
  }

  const consultaServicos =  async () => {
    setState((prevState) => ({
      ...prevState,
      alerta: {
        open: true,
        severity: 'info',
        message: 'Carregando Serviços...'
      }
    }))

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
      const dados = {
        unidadesnegocio: [acesso_atual[0].id]
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/produtos/list`, dados, getToken())

      const produtos = []

      resp.data.filter(param => param.servico && param.destaque && param.ativo).forEach(produto => {
        produtos.push({
          id: produto.id,
          nome: produto.descricao,
          unidademedida: produto.unidademedida,
          itemCombo: produto.itemCombo,
          duracao: produto.servico.duracao,
          valor: null,
          voucher: false,
          servico: {...produto.servico}
        })
      })

      setState((prevState) => ({
        ...prevState,
        servicos: {
          list: produtos
        },
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      }))

    } catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const buscaVouchersCredito = async (pessoa_id, tipo) => {
    try {
      
      const {data} = await axios.get(`${window._env_.REACT_APP_API_URL}/voucher/buscarVoucherPessoa/${pessoa_id}/${tipo}`, getToken())

      let aux = data.filter(param => {
        if(moment().format('YYYY-MM-DD') === param.data_valid_normal || moment().format('YYYY-MM-DD') === param.data_valid_prom) return true
        
        if((moment(moment()).isBefore(param.data_valid_normal) ||  moment().isBefore(param.data_valid_prom))) return true

        return false
      })

      if(aux.length > 0){
        setState((prevState) => ({
          ...prevState,
          vouchersCreditoCliente: aux.map(value => {
            return {
              ...value,
              saldo_form: 'R$ ' + real(value.saldo),
              novo_saldo: value.saldo,
              novo_saldo_form: 'R$ ' + real(value.saldo),
            }
          }),
          vouchersCreditoSelecionados: []
        }))
      }else{
        setState((prevState) => ({
          ...prevState,
          vouchersCreditoCliente: [],
          vouchersCreditoSelecionados: []
        }))
      }

      return data
    
    }catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultarFormaPagto = async (perm_alt_voucher_agenda) => {
    try {
      const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())

      let formasAux = []
      if(!perm_alt_voucher_agenda){
        formasAux = formasPagamento.filter(param => param.ativo === true && param.slug !== 'voucher_produto')
      }else{
        formasAux = formasPagamento.filter(param => param.ativo === true)
      }

      setState((prevState) => ({
        ...prevState,
        formasPagamento: {
          list: formasAux
        }
      }))

    } catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultarContasFinanceira = async () => {
    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidadeAll/${acesso_atual[0].id}`, getToken())

      setState((prevState) => ({
        ...prevState,
        contasFinanceira: {
          list: contasFinanceira
        }
      }))

    } catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const editarServico = async (index, agenda) => {

    let servicoAgenda = {
      ...agenda.servicos[index]
    }

    servicoAgenda.hora_inicio = servicoAgenda.hora_inicio_tela
    servicoAgenda.hora_fim = servicoAgenda.hora_fim_tela

    var antes = moment(`${servicoAgenda.data_inicio}T${servicoAgenda.hora_inicio}:00.000Z`)
    var depois= moment(`${servicoAgenda.data_inicio}T${servicoAgenda.hora_fim}:00.000Z`)
    
    var minutos = depois.diff(antes, 'minutes');

    servicoAgenda.duracao = minutos

    setState((prevState) => ({
      ...prevState,
      servicoAgenda,
      servico: {
        id: agenda.servicos[index].servico_id,
        nome: agenda.servicos[index].servico,
        duracao: agenda.servicos[index].duracao
      },
      sala: {
        id: agenda.servicos[index].ambiente_id,
        nome: agenda.servicos[index].ambiente
      },
      atendente:{
        id: agenda.servicos[index].atendente_id,
        nome: agenda.servicos[index].atendente,
        background_color: agenda.servicos[index].background_color,
        color: agenda.servicos[index].color,
      },
      alteraServico: true
    }))

    // await buscarSalasAtendentes(servicoAgenda, agenda)
  }

  const buscarSalasAtendentes = async (servico, agenda) => {
    const {servicoAgenda, perm_agenda_sem_atend} = state

    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      let dados = {
        unidade_id: acesso_atual[0].id,
        servico_id: servicoAgenda.servico_id || servico.servico_id,
        pessoa_id: agenda.cliente_id,
        data: servicoAgenda.data_inicio || servico.data_inicio,
        inicio: servicoAgenda.hora_inicio || servico.hora_inicio,
        fim: servicoAgenda.hora_fim || servico.hora_fim,
        agenda_id: servicoAgenda.agenda_id || (servico ? servico.agenda_id : ''),
        ordem_id: agenda.ordem_id || '',
        duracao_preparo: servicoAgenda.duracao_preparo || 0,
        duracao_limpeza: servicoAgenda.duracao_limpeza || 0,
        servicos: agenda.servicos.map(servico => {
          return {
            agenda_id: servico.agenda_id || '',
            item_pedido_id: servico.item_pedido_id || ''
          }
        })
      }

      const {data} = await axios.post(`${window._env_.REACT_APP_API_URL}/agendaAmbiente/consultarSalasAtendetes`, dados, getToken())

      if(!perm_agenda_sem_atend){
        if(data.funcionarios.length === 0 && data.salas.length === 0){
          setState((prevState) => ({ ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Não existem atendentes e salas disponíveis para esse horário'
            }
          }))
  
          return
        }else if(data.funcionarios.length === 0){
          setState((prevState) => ({ ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Não existem atendentes disponíveis para esse horário'
            }
          }))
  
          return
        }else if(data.salas.length === 0){
          setState((prevState) => ({ ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Não existem salas disponíveis para esse horário'
            }
          }))
          return
        }
      }else{
        if(data.salas.length === 0){
          setState((prevState) => ({ ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Não existem salas disponíveis para esse horário'
            }
          }))
          return
        }
      }

      setState((prevState) => ({ ...prevState,
        salas: {
          list: data.salas.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0))
        },
        atendentes: {
          list: data.funcionarios.sort((a, b) => (a.fisica.nome > b.fisica.nome) ? 1 : ((b.fisica.nome > a.fisica.nome) ? -1 : 0)).map(value => {
            return {
              id: value.id,
              nome: value.fisica.nome + ' ' + (value.fisica.sobrenome || ''),
              background_color: '#87CEFA',
              color: '#000'
            }
          })
        }
      }))

    } catch (error) {
      console.log(error)
      setState((prevState) => ({ ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleCloseAlerta = () =>{
    setState((prevState) => ({
      ...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const consultarAmbientes = async () =>{
    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/ambiente/list`, dados, getToken())

      const ambientes = []

      data.filter(param => param.ativo).sort((a, b) => (a.ordem > b.ordem) ? 1 : (b.ordem > a.ordem) ? -1 : 0).forEach(ambiente => {
        ambientes.push({
          id: ambiente.id,
          nome: ambiente.nome,
          descricao: ambiente.descricao,
          ordem: ambiente.ordem,
          unidade: ambiente.unidade.numero + ' - ' + ambiente.unidade.descricao,
          unidade_id: ambiente.unidade_id,
          ativo: ambiente.ativo
        })
      })

      setState((prevState) => ({
        ...prevState,
        ambientes: {
          list: ambientes
        },
        ambientesFiltrado: {
          list: ambientes
        },
      }))
    } catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultarConfiguracao = async (unidade_id) => {
    try {

      const { data: configuracao } = await axios.get(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/${unidade_id}`, getToken())

      if(!configuracao){
        setState((prevState) => ({...prevState,
          travaUnidades: true,
          msgTrava: 'Essa unidade não possui o intervalo da agenda configurado!'
        }))

        return false
      }

      if(!configuracao.intervalo_agenda){
        setState((prevState) => ({...prevState,
          travaUnidades: true,
          msgTrava: 'Essa unidade não possui o intervalo da agenda configurado!'
        }))

        return false 
      }

      if(!configuracao.inicio_atendimento){
        setState((prevState) => ({...prevState,
          travaUnidades: true,
          msgTrava: 'Essa unidade não possui o horario de inicio do atendimento configurado!'
        }))

        return false 
      }

      if(!configuracao.fim_atendimento){
        setState((prevState) => ({...prevState,
          travaUnidades: true,
          msgTrava: 'Essa unidade não possui o horario de fim do atendimento configurado!'
        }))

        return false 
      }

      let config = {
        intervalo: configuracao.intervalo_agenda,
        inicio_atendimento: configuracao.inicio_atendimento,
        fim_atendimento: configuracao.fim_atendimento,
        possui_emissao_nfse: configuracao.habilitar_emissao,
        perm_agenda_sem_atend: configuracao.perm_agenda_sem_atend,
        perm_notificacao_agenda: configuracao.perm_notificacao_agenda,
        perm_alt_voucher_agenda: configuracao.perm_alt_voucher_agenda,
        exibir_preparo_limpeza_agenda: configuracao.exibir_preparo_limpeza_agenda,
        exibir_ids_pessoas: configuracao.exibir_ids_pessoas
      }

      setState((prevState) => ({...prevState,
        ...config
      }))

      return config

    } catch (error) {
      console.log(error)
      setState((prevState) => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const atualizaHora = () => {
    setState((prevState) => ({
      ...prevState,
      hora_atual: moment().format('HH:mm')
    }))
  }

  const openCadServico = () => {
    setState((prevState) => ({
      ...prevState,
      editandoAgenda: false,
      alteraServico: false,
      vouchersCreditoSelecionados: [],
      vouchersProdutosSelecionados: [],
      dadosTelaVoucherCredito: {
        totalConta: real(0),
        totalVouchers: real(0),
        diferenca: real(0)
      },
      vouchersCliente: [],
      vouchersCreditoCliente: [],
      openModalAgendarServico: true,
      servicoAgenda: {
        servico: '',
        servico_id: '',
        atendente: '',
        atendente_id: '',
        ambiente: '',
        ambiente_id: '',
        data_inicio: moment().format('YYYY-MM-DD'),
        hora_inicio: '',
        hora_fim: '',
        duracao: 0,
        duracao_tela: 0,
        background_color: '',
        color: '',
        observacao: '',
        index: '',
        obrigatoriedade: false,
        agenda_id: '',
        item_pedido_id: '',
        situacao: 'CONFIRMADO',
        recorrencia: false,
        enviar_alerta: false,
        add_preparo: false,
        add_limpeza: false,
        duracao_preparo: 0,
        duracao_limpeza: 0,
        preparo_id: '',
        limpeza_id: '',
        index_preparo: '',
        index_limpeza: '',
        dadosRecorrencia: {
          recorrenciaACada: 1,
          tipoRecorrencia: 'd',
          qtdRecorrencia: ''
        }
      },
      agenda:{
        ordem_id: '',
        cliente_id: '',
        cliente: '',
        situacao: 'CONFIRMADO',
        observacao_geral: '',
        servicos: [],
        informar_financeiro: false,
        voucher_id: '',
        voucher: ''
      },
      salas: {
        list: []
      },
      sala: {
        id: '',
        nome: '',
        descricao: ''
      },
      atendentes:{
        list: []
      },
      atendente:{
        nome: '',
        background_color: '',
        color: ''
      },
      cliente: {
        id: '',
        nome: '',
        cpf_cnpj: '',
        pessoa_id: ''
      }
    }))
  }

  const verificaFecharModal = () => {
    setState((prevState) => ({
      ...prevState,
      modal: {
        mensagem: "Deseja mesmo fechar a agenda? As alterações não seram salvas!",
      },
      modalOpen: true,
    }))
  }

  const updateFieldCliente = async (event, value) => {
    const {agenda} = state
    if(!value){
      setState((prevState) => ({
        ...prevState,
        cliente: {
          cliente_id: '',
          nome: '',
          cpf: '',
          pessoa_id: ''
        }
      }))

      return false
    }

    agenda.cliente = value.nome
    agenda.cliente_id = value.pessoa_id
    
    await buscaVouchers(value.pessoa_id, 'PRODUTO')
    await buscaVouchersCredito(value.pessoa_id, 'CREDITO')
    await buscaPendencias(value.pessoa_id)
    
    setState((prevState) => ({
      ...prevState,
      agenda,
      cliente: value
    }))
    
    // await consultaServicos()
  }

  const buscaPendencias = async (pessoa_id, unidade_id) => {
    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      let filtro = {
        tipo: "RECEBER",
        descricao: "",
        data_inicial: "",
        data_final: "",
        data_por: "VENCIMENTO",
        situacoes: [{
          id: 'A_VENCER',
          value: 'A VENCER',
          descricao: 'A Vencer'
        },
        {
          id: 'VENCIDO',
          value: 'VENCIDO',
          descricao: 'Vencido'
        }],
        formas_pagto: [],
        pessoa_id,
        unidadesnegocio: [acesso_atual[0].id],
        nome: "",
        limit: 100,
        offset: 0
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/parcelaConta/listPrincipal`, filtro, getToken())

      setState((prevState) => ({
        ...prevState,
        financeiro_pendente: data.list.map(value => {
          return {
            parcela_id: value.parcela_id,
            descricao: value.descricao,
            datavencimento: value.datavencimento,
            valor_parcela: value.valorparcela,
            situacao: value.situacao
          }
        })
      }))

      // const {data} = await axios.get(`${window._env_.REACT_APP_API_URL}/voucher/buscarVoucherPessoa/${pessoa_id}/${tipo}`, getToken())
      
    } catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const buscaVouchers = async (pessoa_id, tipo) => {
    try {
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Buscando Vouchers'
        }
      }))

      const {data} = await axios.get(`${window._env_.REACT_APP_API_URL}/voucher/buscarVoucherPessoa/${pessoa_id}/${tipo}`, getToken())

      let aux = data.filter(param => {

        if(moment().format('YYYY-MM-DD') === param.data_valid_normal || moment().format('YYYY-MM-DD') === param.data_valid_prom) return true

        if(moment(moment()).isBefore(param.data_valid_normal) ||  moment().isBefore(param.data_valid_prom)) return true
        
        return false
      })

      if(aux.length > 0){
        setState((prevState) => ({
          ...prevState,
          vouchersCliente: aux,
          openModalSelecionarVoucher: true,
          alerta: {
            open: false,
            severity: 'info',
            message: ''
          }
        }))
      }else{
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Cliente não possui voucher'
          }
        }))
        await consultaServicos()
      }
    
    }catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldServico = async (event, value) => {
    const {servicoAgenda, agenda, voucherSelecionado} = state

    servicoAgenda.atendente = ''
    servicoAgenda.atendente_id = ''
    servicoAgenda.ambiente = ''
    servicoAgenda.ambiente_id = ''
    servicoAgenda.valor = 0
    servicoAgenda.valor_total_minimo = 0
    servicoAgenda.valor_total_maximo = 0
    servicoAgenda.itemCombo = []
    servicoAgenda.hora_inicio = ''
    servicoAgenda.hora_inicio_tela = ''
    servicoAgenda.hora_fim = ''
    servicoAgenda.hora_fim_tela = ''
    servicoAgenda.duracao = 0
    servicoAgenda.duracao_tela = 0
    servicoAgenda.voucher = false
    
    if(!value){
      servicoAgenda.servico = ''
      servicoAgenda.duracao = ''
      servicoAgenda.servico_id = ''
    
      setState((prevState) => ({
        ...prevState,
        servicoAgenda,
        servico: {
          id: '',
          nome: '',
          unidademedida: '',
          duracao: 0
        }
      }))
    }else{
      if(value.itemCombo){
        if(value.itemCombo.length > 0){
          const index_pricipal = value.itemCombo.findIndex(param => param.principal)
  
          if(index_pricipal !== -1){
            let item_principal = value.itemCombo.filter(param => param.principal)
            servicoAgenda.duracao = item_principal[0].produtoitem.servico.duracao *item_principal[0].quantidade
          }
          
        }
      }else{
        servicoAgenda.duracao = value.duracao
      }

      servicoAgenda.duracao_tela = value.duracao
      servicoAgenda.servico = value.nome
      servicoAgenda.servico_id = value.id
      servicoAgenda.itemCombo = value.itemCombo || []
      servicoAgenda.voucher = value.voucher

      let resp = {}

      if(agenda.voucher_id){

        let index_voucher = voucherSelecionado.produtos.findIndex(param => param.produto_id === value.id && !param.utilizado)

        if(index_voucher !== -1){
          let data_hoje = new Date()
          let data_prom = voucherSelecionado.data_valid_prom
        
          if(moment(data_prom).isAfter(data_hoje)){
            resp = await consultarTabelaPrecoID(voucherSelecionado.padrao.tab_prom_id, parseInt(value.id)) //Tab prom
          }
  
          if(!resp.preco_produto){
            resp = await consultarTabelaPrecoID(voucherSelecionado.padrao.tab_normal_id, parseInt(value.id))
          }
  
          // if(!resp.preco_produto){
          //   setState((prevState) => ({
          //     ...prevState,
          //     alerta: {
          //       open: true,
          //       severity: 'warning',
          //       message: 'Não foi encontrada nenhuma tabela de preço para esse serviço.'
          //     }
          //   }))
          //   return 
          // }
        }else{
          resp = await consultarTabelaPreco(parseInt(value.id),moment().format('YYYY-MM-DD'))
        }
  
      }else{
        resp = await consultarTabelaPreco(parseInt(value.id),moment().format('YYYY-MM-DD'))
      }

      if(resp.preco_produto){
        servicoAgenda.valor = value.valor ? real(value.valor) : real(resp.preco_produto.valor_maximo)
        servicoAgenda.valor_total_minimo = real(resp.preco_produto.valor_minimo)
        servicoAgenda.valor_total_maximo = real(resp.preco_produto.valor_maximo)
      }else{
        servicoAgenda.valor = value.valor ? real(value.valor) : real(0)
        servicoAgenda.valor_total_minimo = real(0)
        servicoAgenda.valor_total_maximo = real(0)
      }

      if(agenda.voucher_id){

        let index_voucher = voucherSelecionado.produtos.findIndex(param => param.produto_id === value.id && !param.utilizado)

        if(index_voucher !== -1){
          servicoAgenda.voucher = true
          servicoAgenda.valor = real(voucherSelecionado.produtos[index_voucher].valor)
        }

      }

      if(value.servico){
        if(value.servico.produto_preparo_id){
          servicoAgenda.add_preparo = true
          servicoAgenda.duracao_preparo = value.servico.duracao_preparo
          servicoAgenda.preparo_id = value.servico.produto_preparo_id
        }else{
          servicoAgenda.add_preparo = false
          servicoAgenda.duracao_preparo = 0
          servicoAgenda.preparo_id = ''
        }
      }else{
        servicoAgenda.add_preparo = false
        servicoAgenda.duracao_preparo = 0
        servicoAgenda.preparo_id = ''
      }

      if(value.servico){
        if(value.servico.produto_limpeza_id){
          servicoAgenda.add_limpeza = true
          servicoAgenda.duracao_limpeza = value.servico.duracao_limpeza
          servicoAgenda.limpeza_id = value.servico.produto_limpeza_id
        }else{
          servicoAgenda.add_limpeza = false
          servicoAgenda.duracao_limpeza = 0
          servicoAgenda.limpeza_id = ''
        }
      }else{
        servicoAgenda.add_limpeza = false
        servicoAgenda.duracao_limpeza = 0
        servicoAgenda.limpeza_id = ''
      }

      setState((prevState) => ({
        ...prevState,
        travarBotao: false,
        servicoAgenda,
        servico: value
      }))
    }

    setState((prevState) => ({
      ...prevState,
      salas: {
        list: []
      },
      sala: {
        id: '',
        nome: '',
        descricao: ''
      },
      atendentes:{
        list: []
      },
      atendente:{
        nome: '',
        background_color: '',
        color: ''
      },
    }))
  }

  const consultarTabelaPrecoID = async (tabela_id, produto_id) => {
    try {
      const { data: tabelaPreco } = await axios.get(`${window._env_.REACT_APP_API_URL}/tabelaPreco/${tabela_id}`, getToken())

      let tabela_preco = {
        ...tabelaPreco,
        preco_produto: tabelaPreco.itens.filter(param => param.produto_id === produto_id)[0]
      }

      setState((prevState) => ({
        ...prevState,
        tabela_preco
      }))

      return tabela_preco

    } catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultarTabelaPreco = async (produto_id, dia) => {
    try {
      
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      let dados = {
        data: dia
      }

      const {data} = await axios.post(`${window._env_.REACT_APP_API_URL}/tabelaPreco/produtoData/${produto_id}/${acesso_atual[0].id}`, dados, getToken())

      setState((prevState) => ({
        ...prevState,
        tabela_preco: data
      }))

      return data

    } catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldSala = (event, value) => {
    const {servicoAgenda} = state
    if(!value){
      servicoAgenda.ambiente = ''
      servicoAgenda.ambiente_id = ''

      setState((prevState) => ({
        ...prevState,
        servicoAgenda,
        sala: {
          id: '',
          nome: ''
        }
      }))

    }else{
      servicoAgenda.ambiente = value.nome
      servicoAgenda.ambiente_id = value.id
  
      setState((prevState) => ({
        ...prevState,
        servicoAgenda,
        sala: value,
      }))
    }

  }

  const updateFieldAtendente = (event, value) => {
    const {servicoAgenda} = state
    if(!value){
      servicoAgenda.atendente = ''
      servicoAgenda.atendente_id = ''
      servicoAgenda.background_color = ''
      servicoAgenda.color = ''

      setState((prevState) => ({
        ...prevState,
        servicoAgenda,
        atendente: {
          id: '',
          nome: '',
          background_color: '',
          color: ''
        }
      }))
    }else{
      servicoAgenda.atendente = value.nome
      servicoAgenda.atendente_id = value.id
      servicoAgenda.background_color = value.background_color
      servicoAgenda.color = value.color
  
      setState((prevState) => ({
        ...prevState,
        servicoAgenda,
        atendente: value,
      }))
    }
  }

  const updateFieldServicoAgenda = async (event) => {
    const {servicoAgenda, conta_conciliada, recorrencia, agenda} = state

    const name = event.target.name
    const value = event.target.value

    console.log(agenda)
    console.log(servicoAgenda)

    if(name === 'situacao'){
      if(value === 'DESMARCADO'){
        if(conta_conciliada){
          setState((prevState) => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Essa ordem de serviço possui o seu financeiro conciliado, não é possivel desmarcar o atendimento!'
            },
            loadingSalva: false
          }))
    
          return
        }
      }

      if((value === 'FINALIZADO' || value === 'DESMARCADO') && recorrencia){
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Não é possivel lançar um recorrência de agendas finalizada/desmarcado!'
          },
          loadingSalva: false
        }))
  
        return
      }

      let index_preparo = agenda.servicos.findIndex(param => param.index_preparo === servicoAgenda.index)
      let index_limpeza = agenda.servicos.findIndex(param => param.index_limpeza === servicoAgenda.index)

      console.log(index_preparo)
      console.log(index_limpeza)

      if(servicoAgenda.index !== '' && (index_preparo !== -1 || index_limpeza !== -1)){
        if(value !== 'DESMARCADO' && value !== "CONFIRMADO"){
          setState((prevState) => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'A Situação do Preparo/Limpeza não pode ser alterado!'
            },
            loadingSalva: false
          }))
    
          return
        }
      }

    }

    servicoAgenda[name] = value

    if(name !== 'observacao' && name !== 'situacao' && name !== 'valor'){
      servicoAgenda.atendente = ''
      servicoAgenda.atendente_id = ''
      servicoAgenda.ambiente = ''
      servicoAgenda.ambiente_id =''

      setState((prevState) => ({
        ...prevState,
        salas: {
          list: []
        },
        sala: {
          id: '',
          nome: '',
          descricao: ''
        },
        atendentes:{
          list: []
        },
        atendente:{
          nome: '',
          background_color: '',
          color: ''
        },
      }))
    }

    if(name === 'hora_inicio'){
      servicoAgenda.hora_inicio_tela = value
    }

    if(name === 'hora_fim'){
      servicoAgenda.hora_fim_tela = value
    }

    setState((prevState) => ({
      ...prevState,
      servicoAgenda
    }))
  }

  const addServico = async (hidden) =>{
    let {servicoAgenda, agenda, inicio_atendimento, fim_atendimento, conta, voucherSelecionado, perm_agenda_sem_atend, servico,
    vouchersCreditoSelecionados, intervalo, servicos, servicos_selecionados, swapServicos, conta_anterior, financeiro_fechado} = state

    if(servicoAgenda.servico_id === ""){
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Informar o serviço!'
        },
        loadingSalva: false
      }))

      // document.getElementById('servico').focus();
      return
    }

    if(servicoAgenda.data_inicio === ""){
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Informar a data do atendimento!'
        },
        loadingSalva: false
      }))

      document.getElementById('data_inicio').focus();
      return
    }

    if(servicoAgenda.hora_inicio === ""){
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Informar a hora do atendimento!'
        },
        loadingSalva: false
      }))

      document.getElementById('hora_inicio').focus();
      return
    }

    if(servicoAgenda.hora_fim === ""){
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Informar o final do atendimento!'
        },
        loadingSalva: false
      }))

      document.getElementById('hora_fim').focus();
      return
    }

    let minutos = servicoAgenda.hora_inicio.split(':')[1]

    if((minutos % intervalo) !== 0){
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Não é possivel agendar o serviço para esse horario, a hora precisa terminar com multiplo de ' + intervalo 
        },
        loadingSalva: false
      }))

      document.getElementById('hora_inicio').focus();
      return
    }

    if(servicoAgenda.ambiente_id === ""){
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Informar o Local de Atendimento!'
        },
        loadingSalva: false
      }))

      document.getElementById('ambiente').focus();
      return
    }

    if(servicoAgenda.atendente_id === "" && !perm_agenda_sem_atend){
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Informar o Atendente!'
        },
        loadingSalva: false
      }))

      document.getElementById('atendente').focus();
      return
    }

    if(servicoAgenda.situacao === "" || !servicoAgenda.situacao){
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Informar a Situação!'
        },
        loadingSalva: false
      }))

      document.getElementById('situacao').focus();
      return
    }

    if(servicoAgenda.add_preparo){
      if(parseInt(servicoAgenda.duracao_preparo) === 0){
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar a duração do preparo!'
          },
          loadingSalva: false
        }))
        return
      }

      if((parseInt(servicoAgenda.duracao_preparo) % intervalo) !== 0){
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Não é possivel agendar o preparo com essa duração, a duração do preparo precisa terminar com multiplo de ' + intervalo 
          },
          loadingSalva: false
        }))
        return
      }
    }

    if(servicoAgenda.add_limpeza){
      if(parseInt(servicoAgenda.duracao_limpeza) === 0){
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar a duração da limpeza!'
          },
          loadingSalva: false
        }))
        return
      }

      if((parseInt(servicoAgenda.duracao_limpeza) % intervalo) !== 0){
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Não é possivel agendar o preparo com essa duração, a duração do preparo precisa terminar com multiplo de ' + intervalo 
          },
          loadingSalva: false
        }))
        return
      }
    }

    let desconto = 0
    let acrescimo = 0

    if(servicoAgenda.recorrencia){
      if(servicoAgenda.dadosRecorrencia.recorrenciaACada === ""){
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar o período da recorrência!'
          },
          loadingSalva: false
        }))
  
        return
      }

      if(servicoAgenda.dadosRecorrencia.tipoRecorrencia === ""){
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar o tipo da recorrência!'
          },
          loadingSalva: false
        }))
  
        return
      }

      if(servicoAgenda.dadosRecorrencia.qtdRecorrencia === ""){
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar o número de recorrências!'
          },
          loadingSalva: false
        }))
  
        return
      }
    }

    if(servicoAgenda.itemCombo){
      if(servicoAgenda.itemCombo.length > 0){
        let itens = servicoAgenda.itemCombo.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        
        const index_pricipal = itens.findIndex(param => param.principal)

        if(index_pricipal !== -1){


          let itens_antes = itens.slice(0, index_pricipal)

          let itens_depois = itens.slice(index_pricipal + 1, itens.length)

          desconto = somarValores(itens_antes.map(value => {return value.produtoitem.servico.duracao * value.quantidade}))
          acrescimo = somarValores(itens_depois.map(value => {return value.produtoitem.servico.duracao * value.quantidade}))
          
        }
        
      }
    }

    const hora_inicio = moment(`${servicoAgenda.data_inicio} ${servicoAgenda.hora_inicio_tela}`).subtract(desconto, 'minutes').format('HH:mm')
    const hora_fim = moment(`${servicoAgenda.data_inicio} ${servicoAgenda.hora_fim_tela}`).add(acrescimo, 'minutes').format('HH:mm')

    if(servicoAgenda.duracao + desconto + acrescimo < intervalo){
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: `A duração do serviço não pode ser inferior a ${intervalo} minutos`
        },
        loadingSalva: false
      }))
      return
    }

    servicoAgenda.hora_inicio = hora_inicio
    servicoAgenda.hora_fim = hora_fim
    servicoAgenda.duracao = servicoAgenda.duracao + desconto + acrescimo

    let inicio_atendimento_form = parseInt(inicio_atendimento.replace(':', ''))
    let fim_atendimento_form = parseInt(fim_atendimento.replace(':', ''))

    let hora_inicio_form = parseInt(servicoAgenda.hora_inicio.replace(':', ''))
    let hora_fim_form = parseInt(servicoAgenda.hora_fim.replace(':', ''))

    if((hora_inicio_form < inicio_atendimento_form) || (hora_fim_form < inicio_atendimento_form) || (hora_inicio_form > fim_atendimento_form) || (hora_fim_form > fim_atendimento_form)){
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Esse atendimento está fora do horario!'
        },
        loadingSalva: false
      }))

      return
    }

    const indexServ = agenda.servicos.findIndex(param => {
      if(param.data_inicio === servicoAgenda.data_inicio){
        if(
          (servicoAgenda.hora_inicio.replace(':', '') > param.hora_inicio.replace(':', '') && servicoAgenda.hora_inicio.replace(':', '') < param.hora_fim.replace(':', ''))
          || (servicoAgenda.hora_fim.replace(':', '') > param.hora_inicio.replace(':', '') && servicoAgenda.hora_fim.replace(':', '') < param.hora_fim.replace(':', ''))
          ){
          return true
        }

        return false
      }

      return false
    })

    if(indexServ !== -1){
      if(agenda.servicos[indexServ].index !== servicoAgenda.index){
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Já existe um atendimento para esse horario!'
          },
          loadingSalva: false
        }))
  
        return
      }
    }
    
    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    if(servicoAgenda.add_preparo){
      if(servicoAgenda.index === ''){
        let hora_inicio = moment(servicoAgenda.data_inicio + ' ' + servicoAgenda.hora_inicio).subtract(servicoAgenda.duracao_preparo, 'minutes').format('HH:mm')
  
        let index_preparo = agenda.servicos.findIndex(param => (param.servico_id === servicoAgenda.preparo_id && param.data_inicio === servicoAgenda.data_inicio && param.hora_inicio === hora_inicio))
      
        if(index_preparo === -1){
          let servico_id = servicoAgenda.preparo_id
          
          if(!servico_id){
            if(acesso_atual[0].id === 9){
              servico_id = 2291
            }else if(acesso_atual[0].id === 46){
              servico_id = 2290
            }
          }

          let preparo = {
            servico: 'Preparo',
            servico_id,
            atendente: servicoAgenda.atendente,
            atendente_id: servicoAgenda.atendente_id,
            ambiente: servicoAgenda.ambiente,
            ambiente_id: servicoAgenda.ambiente_id,
            data_inicio: servicoAgenda.data_inicio,
            hora_inicio,
            hora_inicio_tela: hora_inicio,
            hora_fim: servicoAgenda.hora_inicio,
            hora_fim_tela: servicoAgenda.hora_inicio,
            duracao: servicoAgenda.duracao_preparo,
            duracao_tela: servicoAgenda.duracao_preparo,
            background_color: '',
            color: '',
            observacao: '',
            index: agenda.servicos.length,
            obrigatoriedade: false,
            valor: real(0),
            valor_total_minimo: real(0),
            valor_total_maximo: real(0),
            itemCombo: [],
            situacao: 'CONFIRMADO',
            agenda_id: '',
            voucher: false,
            recorrencia: servicoAgenda.recorrencia,
            enviar_alerta: false,
            add_preparo: false,
            add_limpeza: false,
            editado: true,
            duracao_preparo: 0,
            duracao_limpeza: 0,
            preparo_id: '',
            limpeza_id: '',
            index_preparo: '',
            index_limpeza: '',
            dadosRecorrencia: servicoAgenda.dadosRecorrencia
          }
    
          agenda.servicos.push(preparo)
          servicoAgenda.index_preparo = preparo.index
        }
      }else{
        let index_preparo = agenda.servicos.findIndex(param => param.index === servicoAgenda.index_preparo)
  
        if(index_preparo !== -1){
          let hora_inicio = moment(servicoAgenda.data_inicio + ' ' + servicoAgenda.hora_inicio).subtract(servicoAgenda.duracao_preparo, 'minutes').format('HH:mm')
  
          agenda.servicos[index_preparo].data_inicio = servicoAgenda.data_inicio
          agenda.servicos[index_preparo].hora_inicio = hora_inicio
          agenda.servicos[index_preparo].hora_inicio_tela = hora_inicio
          agenda.servicos[index_preparo].hora_fim = servicoAgenda.hora_inicio
          agenda.servicos[index_preparo].hora_fim_tela = servicoAgenda.hora_inicio
          agenda.servicos[index_preparo].duracao = servicoAgenda.duracao_preparo
          agenda.servicos[index_preparo].duracao_tela = servicoAgenda.duracao_preparo
          agenda.servicos[index_preparo].dadosRecorrencia = servicoAgenda.dadosRecorrencia
          agenda.servicos[index_preparo].recorrencia = servicoAgenda.recorrencia
        }else{
          let hora_inicio = moment(servicoAgenda.data_inicio + ' ' + servicoAgenda.hora_inicio).subtract(servicoAgenda.duracao_preparo, 'minutes').format('HH:mm')

          let servico_id = servicoAgenda.preparo_id
          
          if(!servico_id){
            if(acesso_atual[0].id === 9){
              servico_id = 2291
            }else if(acesso_atual[0].id === 46){
              servico_id = 2290
            }
          }

          let preparo = {
            servico: 'Preparo',
            servico_id,
            atendente: servicoAgenda.atendente,
            atendente_id: servicoAgenda.atendente_id,
            ambiente: servicoAgenda.ambiente,
            ambiente_id: servicoAgenda.ambiente_id,
            data_inicio: servicoAgenda.data_inicio,
            hora_inicio,
            hora_inicio_tela: hora_inicio,
            hora_fim: servicoAgenda.hora_inicio,
            hora_fim_tela: servicoAgenda.hora_inicio,
            duracao: servicoAgenda.duracao_preparo,
            duracao_tela: servicoAgenda.duracao_preparo,
            background_color: '',
            color: '',
            observacao: '',
            index: agenda.servicos.length,
            obrigatoriedade: false,
            valor: real(0),
            valor_total_minimo: real(0),
            valor_total_maximo: real(0),
            itemCombo: [],
            situacao: 'CONFIRMADO',
            agenda_id: '',
            voucher: false,
            recorrencia: servicoAgenda.recorrencia,
            enviar_alerta: false,
            add_preparo: false,
            add_limpeza: false,
            editado: true,
            duracao_preparo: 0,
            duracao_limpeza: 0,
            preparo_id: '',
            limpeza_id: '',
            index_preparo: '',
            index_limpeza: '',
            dadosRecorrencia: servicoAgenda.dadosRecorrencia
          }
    
          agenda.servicos.push(preparo)
          servicoAgenda.index_preparo = preparo.index
        }
      }
    }

    let index_servico = ''
    let servico_novo = false

    if(servicoAgenda.index === ''){
      servicoAgenda.index = agenda.servicos.length
      index_servico = agenda.servicos.length
      agenda.servicos.push(servicoAgenda)
      servico_novo = true
    }else{
      agenda.servicos[servicoAgenda.index] = servicoAgenda
      index_servico = servicoAgenda.index
    }

    if(servicoAgenda.add_limpeza){
      if(servico_novo){
        let hora_fim = moment(servicoAgenda.data_inicio + ' ' + servicoAgenda.hora_fim).add(servicoAgenda.duracao_limpeza, 'minutes').format('HH:mm')
  
        let index_limpeza = agenda.servicos.findIndex(param => (param.servico_id === servicoAgenda.limpeza_id && param.data_inicio === servicoAgenda.data_inicio && param.hora_fim === hora_fim))
  
        if(index_limpeza === -1){
          let servico_id = servicoAgenda.preparo_id
          
          if(!servico_id){
            if(acesso_atual[0].id === 9){
              servico_id = 2292
            }else if(acesso_atual[0].id === 46){
              servico_id = 2287
            }
          }

          let limpeza = {
            servico: 'Limpeza',
            servico_id,
            atendente: servicoAgenda.atendente,
            atendente_id: servicoAgenda.atendente_id,
            ambiente: servicoAgenda.ambiente,
            ambiente_id: servicoAgenda.ambiente_id,
            data_inicio: servicoAgenda.data_inicio,
            hora_inicio: servicoAgenda.hora_fim,
            hora_inicio_tela: servicoAgenda.hora_fim,
            hora_fim,
            hora_fim_tela: hora_fim,
            duracao: servicoAgenda.duracao_limpeza,
            duracao_tela: servicoAgenda.duracao_limpeza,
            background_color: '',
            color: '',
            observacao: '',
            index: agenda.servicos.length,
            obrigatoriedade: false,
            valor: real(0),
            valor_total_minimo: real(0),
            valor_total_maximo: real(0),
            itemCombo: [],
            situacao: 'CONFIRMADO',
            agenda_id: '',
            voucher: false,
            recorrencia: servicoAgenda.recorrencia,
            editado: true,
            enviar_alerta: false,
            add_preparo: false,
            add_limpeza: false,
            duracao_preparo: 0,
            duracao_limpeza: 0,
            preparo_id: '',
            limpeza_id: '',
            index_preparo: '',
            index_limpeza: '',
            dadosRecorrencia: servicoAgenda.dadosRecorrencia
          }
  
          agenda.servicos[index_servico].index_limpeza = limpeza.index
    
          agenda.servicos.push(limpeza)
        }
      }else{
        let index_limpeza = agenda.servicos.findIndex(param => param.index === servicoAgenda.index_limpeza)
  
        if(index_limpeza !== -1){
          let hora_fim = moment(servicoAgenda.data_inicio + ' ' + servicoAgenda.hora_fim).add(servicoAgenda.duracao_limpeza, 'minutes').format('HH:mm')
  
          agenda.servicos[index_limpeza].data_inicio = servicoAgenda.data_inicio
          agenda.servicos[index_limpeza].hora_inicio = servicoAgenda.hora_fim
          agenda.servicos[index_limpeza].hora_inicio_tela = servicoAgenda.hora_fim
          agenda.servicos[index_limpeza].hora_fim = hora_fim
          agenda.servicos[index_limpeza].hora_fim_tela = hora_fim
          agenda.servicos[index_limpeza].duracao = servicoAgenda.duracao_limpeza
          agenda.servicos[index_limpeza].duracao_tela = servicoAgenda.duracao_limpeza
          agenda.servicos[index_limpeza].dadosRecorrencia = servicoAgenda.dadosRecorrencia
          agenda.servicos[index_limpeza].recorrencia = servicoAgenda.recorrencia
        
        }else{
          let hora_fim = moment(servicoAgenda.data_inicio + ' ' + servicoAgenda.hora_fim).add(servicoAgenda.duracao_limpeza, 'minutes').format('HH:mm')

          let servico_id = servicoAgenda.preparo_id
          
          if(!servico_id){
            if(acesso_atual[0].id === 9){
              servico_id = 2292
            }else if(acesso_atual[0].id === 46){
              servico_id = 2287
            }
          }

          let limpeza = {
            servico: 'Limpeza',
            servico_id,
            atendente: servicoAgenda.atendente,
            atendente_id: servicoAgenda.atendente_id,
            ambiente: servicoAgenda.ambiente,
            ambiente_id: servicoAgenda.ambiente_id,
            data_inicio: servicoAgenda.data_inicio,
            hora_inicio: servicoAgenda.hora_fim,
            hora_inicio_tela: servicoAgenda.hora_fim,
            hora_fim,
            hora_fim_tela: hora_fim,
            duracao: servicoAgenda.duracao_limpeza,
            duracao_tela: servicoAgenda.duracao_limpeza,
            background_color: '',
            color: '',
            observacao: '',
            index: agenda.servicos.length,
            obrigatoriedade: false,
            valor: real(0),
            valor_total_minimo: real(0),
            valor_total_maximo: real(0),
            itemCombo: [],
            situacao: 'CONFIRMADO',
            agenda_id: '',
            voucher: false,
            recorrencia: servicoAgenda.recorrencia,
            editado: true,
            enviar_alerta: false,
            add_preparo: false,
            add_limpeza: false,
            duracao_preparo: 0,
            duracao_limpeza: 0,
            preparo_id: '',
            limpeza_id: '',
            index_preparo: '',
            index_limpeza: '',
            dadosRecorrencia: servicoAgenda.dadosRecorrencia
          }
  
          agenda.servicos[index_servico].index_limpeza = limpeza.index
    
          agenda.servicos.push(limpeza)
        }
      }
    }

    if(servicoAgenda.situacao === 'DESMARCADO'){
      let index_voucher_credito = conta.parcelas.findIndex(param => {
        if(param.vouchersCreditoSelecionados){
          if(param.vouchersCreditoSelecionados.length > 0){
            return true
          }
        }
        if(param.vouchersProdutosSelecionados){
          if(param.vouchersProdutosSelecionados.length > 0){
            return true
          }
        }
        return false
      })

      if(index_voucher_credito === -1 && !agenda.voucher_id){
        conta = {
          id: '',
          valor_total: '0,00',
          valor_total_minimo: real(0),
          valor_total_maximo: real(0),
          qtd_parcelas: 1,
          gerar_nfse: false,
          nfse_id: "",
          parcelas: [
            {
              id: '',
              baixado: true,
              datavencimento: moment(new Date()).format('YYYY-MM-DD'),
              datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
              valorparcela: real(0),
              valorparcela_anterior: real(0),
              multajuros: real(0),
              formapagto_id: "",
              contfin_id: "",
              documento: "",
              habilita: "",
              num: 1,
              parcela_editada: false,
              index: 0,
              trava: false,
              vouchersCreditoSelecionados: [],
              vouchersCreditoSelecionadosString: '',
              vouchersProdutosSelecionados: [],
              vouchersProdutosSelecionadosString: ''
            }
          ]
        }
      }

      if(agenda.voucher_id && servicoAgenda.voucher){
        conta.parcelas[0].valorparcela = real(parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) - parseFloat(servicoAgenda.valor.replaceAll(".", "").replace(",", ".")))
      }
    }

    conta.valor_total_minimo = real(somarValores(agenda.servicos.filter(param => param.situacao !== 'DESMARCADO').map(value => {return parseFloat(value.valor_total_minimo.replaceAll(".", "").replace(",", "."))})))
    conta.valor_total_maximo = real(somarValores(agenda.servicos.filter(param => param.situacao !== 'DESMARCADO').map(value => {return parseFloat(value.valor_total_maximo.replaceAll(".", "").replace(",", "."))})))
    conta.valor_total = real(somarValores(agenda.servicos.filter(param => param.situacao !== 'DESMARCADO').map(value => {return parseFloat(value.valor.replaceAll(".", "").replace(",", "."))})))

    agenda.servicos[servicoAgenda.index].editado = true
    
    if(agenda.voucher_id){
      if(!servicoAgenda.editado){
        if(servicoAgenda.voucher){
          agenda.informar_financeiro = true
          let index_servico = servicos.list.findIndex(param => param.id === servicoAgenda.servico_id)
          let index_selecionado  = servicos_selecionados.findIndex(param => param.produto_id === servicoAgenda.servico_id)
    
          if(index_servico !== -1){
            servicos.list.splice(index_servico, 1)
          }
    
          if(index_selecionado !== -1){
            servicos_selecionados.splice(index_selecionado, 1)
          }
  
          if((parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) === 0)){
            conta.parcelas[0].valorparcela = real(parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) + parseFloat(servicoAgenda.valor.replaceAll(".", "").replace(",", ".")))
            conta.parcelas[0].valorparcela_anterior = real(parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) + parseFloat(servicoAgenda.valor.replaceAll(".", "").replace(",", ".")))
            conta.parcelas[0].trava = true
            conta.parcelas[0].parcela_editada = true
            conta.parcelas[0].formapagto_id = '9'
            conta.parcelas[0].baixado = true
            conta.parcelas[0].num = 1
            conta.parcelas[0].datavencimento = moment(new Date()).format('YYYY-MM-DD')
          }else if((parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) !== 0) && parseInt(conta.parcelas[0].formapagto_id) === 9){
            conta.parcelas[0].valorparcela = real(parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) + parseFloat(servicoAgenda.valor.replaceAll(".", "").replace(",", ".")))
            conta.parcelas[0].valorparcela_anterior = real(parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) + parseFloat(servicoAgenda.valor.replaceAll(".", "").replace(",", ".")))
          }else if((parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) === 0) && parseInt(conta.parcelas[0].formapagto_id) === 9){
            conta.parcelas[0].valorparcela = real(parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) + parseFloat(servicoAgenda.valor.replaceAll(".", "").replace(",", ".")))
            conta.parcelas[0].valorparcela_anterior = real(parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) + parseFloat(servicoAgenda.valor.replaceAll(".", "").replace(",", ".")))
          }else{
            conta.parcelas.unshift({
              id: '',
              baixado: true,
              datavencimento: moment(new Date()).format('YYYY-MM-DD'),
              datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
              valorparcela: servicoAgenda.valor,
              valorparcela_anterior: servicoAgenda.valor,
              multajuros: real(0),
              formapagto_id: 9,
              contfin_id: "",
              documento: "",
              habilita: "",
              num: 1,
              parcela_editada: true,
              index: 0,
              trava: true,
              vouchersCreditoSelecionados: [],
              vouchersCreditoSelecionadosString: '',
              vouchersProdutosSelecionados: [],
              vouchersProdutosSelecionadosString: ''
            })
  
            for (let i = 0; i < conta.parcelas.length; i++) {
              conta.parcelas[i].index = i
            }
          }

          setState((prevState) => ({
            ...prevState,
            qtdParcelasEditado: 1
          }))
        }else{
          if(conta.parcelas.length === 1){
            if(parseInt(conta.parcelas[0].formapagto_id) === 9){
              conta.parcelas.push({
                id: '',
                baixado: true,
                datavencimento: moment(new Date()).format('YYYY-MM-DD'),
                datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
                valorparcela: servicoAgenda.valor,
                valorparcela_anterior: servicoAgenda.valor,
                multajuros: real(0),
                formapagto_id: "",
                contfin_id: "",
                documento: "",
                habilita: "",
                num: conta.parcelas.length + 1,
                parcela_editada: false,
                index: conta.parcelas.length,
                trava: false,
                vouchersCreditoSelecionados: [],
                vouchersCreditoSelecionadosString: '',
                vouchersProdutosSelecionados: [],
                vouchersProdutosSelecionadosString: ''
              })
  
              for (let i = 0; i < conta.parcelas.length; i++) {
                conta.parcelas[i].index = i
              }
            }
          }else{
            conta.parcelas[1].valorparcela = real(parseFloat(conta.parcelas[1].valorparcela.replaceAll(".", "").replace(",", ".")) + parseFloat(servicoAgenda.valor.replaceAll(".", "").replace(",", ".")))
            conta.parcelas[1].valorparcela_anterior = real(parseFloat(conta.parcelas[1].valorparcela.replaceAll(".", "").replace(",", ".")) + parseFloat(servicoAgenda.valor.replaceAll(".", "").replace(",", ".")))
          }
        }
      }else{
        if(!financeiro_fechado){
          conta.parcelas = [
            {
              id: '',
              baixado: true,
              datavencimento: moment(new Date()).format('YYYY-MM-DD'),
              datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
              valorparcela: real(0),
              valorparcela_anterior: real(0),
              multajuros: real(0),
              formapagto_id: "",
              contfin_id: "",
              documento: "",
              habilita: "",
              num: 1,
              parcela_editada: false,
              index: 0,
              trava: false,
              vouchersCreditoSelecionados: [],
              vouchersCreditoSelecionadosString: '',
              vouchersProdutosSelecionados: [],
              vouchersProdutosSelecionadosString: ''
            }
          ]
  
          let valor_vouchers = 0
          let valor_nao_vouchers = 0 
  
          for (const servico of agenda.servicos) {
            if(servico.situacao !== 'DESMARCADO'){
              if(servico.voucher){
                valor_vouchers += parseFloat(servico.valor.replaceAll(".", "").replace(",", "."))
              }else{
                valor_nao_vouchers += parseFloat(servico.valor.replaceAll(".", "").replace(",", "."))
              }
            }
          }      
  
          if(valor_vouchers > 0){
            agenda.informar_financeiro = true
            if((parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) === 0)){
              conta.parcelas[0].valorparcela = real(valor_vouchers)
              conta.parcelas[0].valorparcela_anterior = real(valor_vouchers)
              conta.parcelas[0].trava = true
              conta.parcelas[0].parcela_editada = true
              conta.parcelas[0].formapagto_id = '9'
              conta.parcelas[0].baixado = true
              conta.parcelas[0].num = 1
              conta.parcelas[0].datavencimento = moment(new Date()).format('YYYY-MM-DD')
            }else if((parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) !== 0) && parseInt(conta.parcelas[0].formapagto_id) === 9){
              conta.parcelas[0].valorparcela = real(valor_vouchers)
              conta.parcelas[0].valorparcela_anterior = real(valor_vouchers)
            }else if((parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) === 0) && parseInt(conta.parcelas[0].formapagto_id) === 9){
              conta.parcelas[0].valorparcela = real(valor_vouchers)
              conta.parcelas[0].valorparcela_anterior = real(valor_vouchers)
            }else{
              conta.parcelas.unshift({
                id: '',
                baixado: true,
                datavencimento: moment(new Date()).format('YYYY-MM-DD'),
                datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
                valorparcela: real(valor_vouchers),
                valorparcela_anterior: real(valor_vouchers),
                multajuros: real(0),
                formapagto_id: 9,
                contfin_id: "",
                documento: "",
                habilita: "",
                num: 1,
                parcela_editada: true,
                index: 0,
                trava: true,
                vouchersCreditoSelecionados: [],
                vouchersCreditoSelecionadosString: '',
                vouchersProdutosSelecionados: [],
                vouchersProdutosSelecionadosString: ''
              })
    
              for (let i = 0; i < conta.parcelas.length; i++) {
                conta.parcelas[i].index = i
              }
            }
  
            setState((prevState) => ({
              ...prevState,
              qtdParcelasEditado: 1
            }))
          }
  
          if(valor_nao_vouchers > 0){
            if(conta.parcelas.length === 1){
              if(parseInt(conta.parcelas[0].formapagto_id) === 9){
                conta.parcelas.push({
                  id: '',
                  baixado: true,
                  datavencimento: moment(new Date()).format('YYYY-MM-DD'),
                  datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
                  valorparcela: real(valor_nao_vouchers),
                  valorparcela_anterior: real(valor_nao_vouchers),
                  multajuros: real(0),
                  formapagto_id: "",
                  contfin_id: "",
                  documento: "",
                  habilita: "",
                  num: conta.parcelas.length + 1,
                  parcela_editada: false,
                  index: conta.parcelas.length,
                  trava: false,
                  vouchersCreditoSelecionados: [],
                  vouchersCreditoSelecionadosString: '',
                  vouchersProdutosSelecionados: [],
                  vouchersProdutosSelecionadosString: ''
                })
    
                for (let i = 0; i < conta.parcelas.length; i++) {
                  conta.parcelas[i].index = i
                }
              }else{
                conta.parcelas[0].valorparcela = real(valor_nao_vouchers)
                conta.parcelas[0].valorparcela_anterior = real(valor_nao_vouchers)
              }
            }else{
              conta.parcelas[1].valorparcela = real(valor_nao_vouchers)
              conta.parcelas[1].valorparcela_anterior = real(valor_nao_vouchers)
            }
          }
  
          if(valor_vouchers > 0 & valor_nao_vouchers > 0){
            conta.parcelas = [
              {
                id: '',
                baixado: true,
                datavencimento: moment(new Date()).format('YYYY-MM-DD'),
                datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
                valorparcela: real(valor_vouchers),
                valorparcela_anterior: real(valor_vouchers),
                multajuros: real(0),
                formapagto_id: "9",
                contfin_id: "",
                documento: "",
                habilita: "",
                num: 1,
                parcela_editada: true,
                index: 0,
                trava: true,
                vouchersCreditoSelecionados: [],
                vouchersCreditoSelecionadosString: '',
                vouchersProdutosSelecionados: [],
                vouchersProdutosSelecionadosString: ''
              },
              {
                id: '',
                baixado: true,
                datavencimento: moment(new Date()).format('YYYY-MM-DD'),
                datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
                valorparcela: real(valor_nao_vouchers),
                valorparcela_anterior: real(valor_nao_vouchers),
                multajuros: real(0),
                formapagto_id: "",
                contfin_id: "",
                documento: "",
                habilita: "",
                num: 2,
                parcela_editada: false,
                index: 1,
                trava: false,
                vouchersCreditoSelecionados: [],
                vouchersCreditoSelecionadosString: '',
                vouchersProdutosSelecionados: [],
                vouchersProdutosSelecionadosString: ''
              }
            ]
          }
        }
      }
    }else{
      let soma_parcelas = parseFloat((somarValores(conta.parcelas.map(value => { return parseFloat(value.valorparcela.replaceAll(".", "").replace(",", "."))}))).toFixed(2))
      soma_parcelas = soma_parcelas || 0
      
      let total = parseFloat(conta.valor_total.replaceAll(".", "").replace(",", "."))
      
      console.log('total:', total)
      console.log('soma_parcelas:', soma_parcelas)

      let diferenca = total - soma_parcelas
      let dif_contrario = diferenca * -1

      console.log('diferenca: ', diferenca)

      if(diferenca > 0){
        if(conta.parcelas.length === 1){
          if((parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) === 0)){
            conta.parcelas[0] = {
              baixado: true,
              datavencimento: moment(new Date()).format('YYYY-MM-DD'),
              datavencimento_orig: "",
              valorparcela: real(diferenca),
              valorparcela_anterior: real(diferenca),
              multajuros: real(0),
              formapagto_id: '',
              contfin_id: '',
              documento: '',
              habilita: '',
              num: 1,
              parcela_editada: false,
              index: 0,
              trava: false,
              vouchersCreditoSelecionados: [],
              vouchersCreditoSelecionadosString: '',
              vouchersProdutosSelecionados: [],
              vouchersProdutosSelecionadosString: ''
            }
          }else{
            conta.parcelas.push({
              baixado: true,
              datavencimento: moment(new Date()).format('YYYY-MM-DD'),
              datavencimento_orig: "",
              valorparcela: real(diferenca),
              valorparcela_anterior: real(diferenca),
              multajuros: real(0),
              formapagto_id: '',
              contfin_id: '',
              documento: '',
              habilita: '',
              num: conta.parcelas.length + 1,
              parcela_editada: false,
              index: conta.parcelas.length,
              trava: false,
              vouchersCreditoSelecionados: [],
              vouchersCreditoSelecionadosString: '',
              vouchersProdutosSelecionados: [],
              vouchersProdutosSelecionadosString: ''
            })
          }
        }else{
          conta.parcelas.push({
            baixado: true,
            datavencimento: moment(new Date()).format('YYYY-MM-DD'),
            datavencimento_orig: "",
            valorparcela: real(diferenca),
            valorparcela_anterior: real(diferenca),
            multajuros: real(0),
            formapagto_id: '',
            contfin_id: '',
            documento: '',
            habilita: '',
            num: conta.parcelas.length + 1,
            parcela_editada: false,
            index: conta.parcelas.length,
            trava: false,
            vouchersCreditoSelecionados: [],
            vouchersCreditoSelecionadosString: '',
            vouchersProdutosSelecionados: [],
            vouchersProdutosSelecionadosString: ''
          })
        }
        conta.qtd_parcelas = conta.parcelas.length
      }else if(diferenca < 0){
        if(conta.parcelas.length === 1){
          conta.parcelas[0].valorparcela = real(parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) + diferenca)
          conta.parcelas[0].valorparcela_anterior = real(parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) + diferenca)
          
          if(conta.parcelas[0].vouchersCreditoSelecionados.length === 1){
            // vouchersCreditoSelecionados[0].valor_a_ultilizar = vouchersCreditoSelecionados[0].valor_a_ultilizar + diferenca
            conta.parcelas[0].vouchersCreditoSelecionados[0].saldo = conta.parcelas[0].vouchersCreditoSelecionados[0].saldo - diferenca
            conta.parcelas[0].vouchersCreditoSelecionados[0].valor_a_ultilizar = conta.parcelas[0].vouchersCreditoSelecionados[0].valor_a_ultilizar + diferenca
          }else{

          }

        }else{
          let parcelas_nao_voucher = conta.parcelas.filter(param => parseInt(param.formapagto_id) !== 9)
          let parcelas_voucher = conta.parcelas.filter(param => parseInt(param.formapagto_id) === 9)

          if(parcelas_nao_voucher.length === 1){
            if(parseFloat(conta.parcelas[parcelas_nao_voucher[0].index].valorparcela.replaceAll(".", "").replace(",", ".")) > dif_contrario){
              conta.parcelas[parcelas_nao_voucher[0].index].valorparcela = real(parseFloat(conta.parcelas[parcelas_nao_voucher[0].index].valorparcela.replaceAll(".", "").replace(",", ".")) + diferenca)
              conta.parcelas[parcelas_nao_voucher[0].index].valorparcela_anterior = real(parseFloat(conta.parcelas[parcelas_nao_voucher[0].index].valorparcela.replaceAll(".", "").replace(",", ".")) + diferenca)
            }else{
              if(parcelas_voucher.length === 1){
                conta.parcelas.splice(parcelas_nao_voucher[0].index, 1)
                conta.parcelas[0].valorparcela = conta.valor_total
                conta.parcelas[0].valorparcela_anterior = conta.valor_total
                conta.parcelas[0].vouchersCreditoSelecionados[0].valor_a_ultilizar = parseFloat(conta.valor_total.replaceAll(".", "").replace(",", "."))
              }
            }
          }else{
            let sobra = 0

            parcelas_nao_voucher = parcelas_nao_voucher.sort((a, b) => (parseFloat(a.valorparcela.replaceAll(".", "").replace(",", ".")) > parseFloat(b.valorparcela.replaceAll(".", "").replace(",", "."))) ? 1 : ((parseFloat(b.valorparcela.replaceAll(".", "").replace(",", ".")) > parseFloat(a.valorparcela.replaceAll(".", "").replace(",", "."))) ? -1 : 0))

            for (const parcela of parcelas_nao_voucher) {
              let valor_parcela = parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", "."))
                            
              if(valor_parcela > ((dif_contrario/parcelas_nao_voucher.length) + sobra)){
                conta.parcelas[parcela.index].valorparcela = real((valor_parcela - (dif_contrario/parcelas_nao_voucher.length)) - sobra)
                conta.parcelas[parcela.index].valorparcela_anterior = real((valor_parcela - (dif_contrario/parcelas_nao_voucher.length)) - sobra)
                
                sobra = 0
              }else{
                sobra += valor_parcela
                let index = conta.parcelas.findIndex(param => param.index === parcela.index)

                conta.parcelas.splice(index, 1)
              }
            }

            if(sobra > 0){
              conta.parcelas[0].valorparcela = conta.valor_total
              conta.parcelas[0].valorparcela_anterior = conta.valor_total
              conta.parcelas[0].vouchersCreditoSelecionados[0].valor_a_ultilizar = parseFloat(conta.valor_total.replaceAll(".", "").replace(",", "."))
              sobra = 0
            }
          }
        }

      }
    }

    if(!hidden){
      setState((prevState) => ({
        ...prevState,
        servicoAgenda: {
          servico: '',
          servico_id: '',
          atendente: '',
          atendente_id: '',
          ambiente: '',
          ambiente_id: '',
          data_inicio: moment().format('YYYY-MM-DD'),
          hora_inicio: '',
          hora_fim: '',
          hora_inicio_tela: '',
          hora_fim_tela: '',
          duracao: 0,
          duracao_tela: 0,
          background_color: '',
          color: '',
          observacao: '',
          index: '',
          valor: real(0),
          valor_total_minimo: real(0),
          valor_total_maximo: real(0),
          obrigatoriedade: false,
          situacao: 'CONFIRMADO',
          agenda_id: '',
          item_pedido_id: '',
          voucher: false,
          recorrencia: false,
          enviar_alerta: false,
          add_preparo: false,
          add_limpeza: false,
          duracao_preparo: 0,
          duracao_limpeza: 0,
          preparo_id: '',
          limpeza_id: '',
          index_preparo: '',
          index_limpeza: '',
          dadosRecorrencia: {
            recorrenciaACada: 1,
            tipoRecorrencia: 'd',
            qtdRecorrencia: ''
          }
        },
        salas: {
          list: []
        },
        atendentes: {
          list: []
        },
        servico: {
          id: '',
          nome: '',
          duracao: 0
        },
        atendente:{
          id: '',
          nome: '',
          background_color: '',
          color: ''
        },
        sala: {
          id: '',
          nome: ''
        },
        alteraServico: false
      }))
    }

    conta.qtd_parcelas = conta.parcelas.length
            
    if(parseFloat(conta.valor_total.replaceAll(".", "").replace(",", ".")) === 0){
      agenda.informar_financeiro = false
    }

    if(conta.valor_total !== conta_anterior.valor_total && conta_anterior.id !== ''){
      agenda.financeiro_fechado = false
    }


    setState((prevState) => ({
      ...prevState,
      agenda,
      conta,
      servicos,
      vouchersCreditoSelecionados,
      servicos_selecionados
    }))

    if(voucherSelecionado.produtos){
      let index_produto_voucher = voucherSelecionado.produtos.findIndex(param => param.produto_id === servicoAgenda.servico_id)

      if(index_produto_voucher !== -1 && state.utilizar_voucher){
        voucherSelecionado.produtos[index_produto_voucher].utilizado = true
      }

      if(swapServicos === 'voucher'){
        setState((prevState) => ({
          ...prevState,
          servicos: {
            list: voucherSelecionado.produtos.filter(param => (!param.utilizado && param.produto.servico)).map(value => {
              return {
                id: value.produto.id,
                nome: value.produto.descricao,
                unidademedida: value.produto.unidademedida,
                itemCombo: value.produto.itemCombo,
                duracao: value.produto.servico.duracao,
                valor: value.valor,
                voucher: true
              }
            })
          }
        }))
      }

      setState((prevState) => ({
        ...prevState,
        voucherSelecionado
      }))
    }
  }

  const removerServico = (index) => {
    const {agenda, servicos, servicos_selecionados, conta, voucherSelecionado, swapServicos} = state

    let itens = agenda.servicos

    let index_servico = itens.findIndex(param => param.index === index)
      
    if(itens[index_servico].voucher){
      conta.parcelas[0].valorparcela = real(parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) - parseFloat(itens[index_servico].valor.replaceAll(".", "").replace(",", ".")))

      if(parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) === 0){
        if(conta.parcelas.length === 1){
          conta.parcelas[0].trava = false
          conta.parcelas[0].parcela_editada = false
          conta.parcelas[0].formapagto_id = ''
          conta.parcelas[0].baixado = true
        }else{
          conta.parcelas.slice(0, 1)
        }
      }

      servicos.list.push({
        id: itens[index_servico].servico_id,
        nome: itens[index_servico].servico,
        unidademedida: '',
        itemCombo: itens[index_servico].itemCombo,
        duracao: itens[index_servico].duracao,
        valor: parseFloat(itens[index_servico].valor.replaceAll(".", "").replace(",", ".")),
        voucher: true
      })

      servicos_selecionados.push({
        produto_id: itens[index_servico].servico_id,
        produto: itens[index_servico].servico,
        valor: parseFloat(itens[index_servico].valor.replaceAll(".", "").replace(",", ".")),
        valor_form: 'R$ ' + real(parseFloat(itens[index_servico].valor.replaceAll(".", "").replace(",", ".")),),
        unidademedida: '',
        duracao: itens[index_servico].duracao,
        itemCombo: itens[index_servico].itemCombo
      })
    }

    if(voucherSelecionado.produtos){
      let index_produto_voucher = voucherSelecionado.produtos.findIndex(param => param.produto_id === itens[index_servico].servico_id)

      if(index_produto_voucher !== -1){
        voucherSelecionado.produtos[index_produto_voucher].utilizado = false
      }

      if(swapServicos === 'voucher'){
        setState((prevState) => ({
          ...prevState,
          servicos: {
            list: voucherSelecionado.produtos.filter(param => (!param.utilizado && param.produto.servico)).map(value => {
              return {
                id: value.produto.id,
                nome: value.produto.descricao,
                unidademedida: value.produto.unidademedida,
                itemCombo: value.produto.itemCombo,
                duracao: value.produto.servico.duracao,
                valor: value.valor,
                voucher: true
              }
            })
          }
        }))
      }

      setState((prevState) => ({
        ...prevState,
        voucherSelecionado
      }))
    }

    if(itens[index_servico].index_preparo !== ''){
      let index_preparo = itens.findIndex(param => param.index === itens[index_servico].index_preparo)

      if(index_preparo !== -1){
        itens.splice(index_preparo, 1)
      }
    }

    index_servico = itens.findIndex(param => param.index === index_servico)

    if(itens[index_servico].index_limpeza !== ''){
      let index_limpeza = itens.findIndex(param => param.index === itens[index_servico].index_limpeza)
      
      if(index_limpeza !== -1){
        itens.splice(index_limpeza, 1)
      }
    }

    index_servico = itens.findIndex(param => param.index === index_servico)

    itens.splice(index_servico, 1)

    for (let i = 0; i < itens.length; i++) {
      itens[i].index = i
    }

    agenda.servicos = itens

    conta.valor_total_minimo = real(somarValores(agenda.servicos.filter(param => param.situacao !== 'DESMARCADO').map(value => {return parseFloat(value.valor_total_minimo.replaceAll(".", "").replace(",", "."))})))
    conta.valor_total_maximo = real(somarValores(agenda.servicos.filter(param => param.situacao !== 'DESMARCADO').map(value => {return parseFloat(value.valor_total_maximo.replaceAll(".", "").replace(",", "."))})))
    conta.valor_total = real(somarValores(agenda.servicos.filter(param => param.situacao !== 'DESMARCADO').map(value => {return parseFloat(value.valor.replaceAll(".", "").replace(",", "."))})))

    setState((prevState) => ({
      ...prevState,
      conta,
      agenda,
      servicos_selecionados
    })) 
  }

  const salvarAgenda = async (value) => {
    if(value){
      setState((prevState) => ({
        ...prevState,
        loadingSalva: true
      }))

      const {agenda, conta, servicoAgenda, formasPagamento } = state
  
      if(servicoAgenda.servico !== ''){
        addServico(false)
      }     
  
      if(agenda.cliente_id === ""){
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar o cliente!'
          },
          loadingSalva: false
        }))
  
        document.getElementById('cliente').focus();
        return
      }
  
      if(agenda.servicos.length === 0){
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Informar ao menos um serviço!'
          },
          loadingSalva: false
        }))
  
        document.getElementById('servico').focus();
        return
      }
  
      if(agenda.informar_financeiro){
        const verificaCaixa = verificaCaixaInicial(state)
  
        if(!verificaCaixa.libera){
          setState((prevState) => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: verificaCaixa.mensagem
            },
            loadingSalva: false
          }))
    
          return
        }
        
        for (let i = 0; i < conta.parcelas.length; i++) {
          if(conta.parcelas[i].datavencimento === ""){
            setState((prevState) => ({
              ...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Informar a data de vemcimento da parcela ${conta.parcelas[i].num}!`
              },
              tab: 1,
              loadingSalva: false
            }))
            return
          }
  
          if(conta.parcelas[i].valorparcela === ""){
            setState((prevState) => ({
              ...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Informar o valor da parcela ${conta.parcelas[i].num}!`
              },
              tab: 1,
              loadingSalva: false
            }))
            return
          }
  
          if(conta.parcelas[i].formapagto_id === ""){
            setState((prevState) => ({
              ...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Informar a forma de pagamento da parcela ${conta.parcelas[i].num}!`
              },
              tab: 1,
              loadingSalva: false
            }))
            return
          }
  
          if(conta.parcelas[i].contfin_id === "" && parseInt(conta.parcelas[i].formapagto_id) !== 9 && parseInt(conta.parcelas[i].formapagto_id) !== 11){
            setState((prevState) => ({
              ...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Informar a conta da parcela ${conta.parcelas[i].num}!`
              },
              tab: 1,
              loadingSalva: false
            }))
            return
          }
  
          let valor_parcela = parseFloat(conta.parcelas[i].valorparcela.replaceAll(".", "").replace(",", "."))
          let valor_desconto = parseFloat(conta.parcelas[i].multajuros.replaceAll(".", "").replace(",", "."))
  
          if(valor_desconto > 0){
            setState((prevState) => ({
              ...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Não é possivel lançar um acrescimo na parcela.'
              },
              loadingSalvar: false
            }))
            return
          }
  
          valor_desconto = valor_desconto * -1 
  
          if(valor_desconto > valor_parcela){
            setState((prevState) => ({
              ...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'O valor do desconto não pode ser superior ao valor da parcela.'
              },
              loadingSalvar: false
            }))
            return
          }
  
        }
      }
     
      if(agenda.informar_financeiro){
        for (let i = 0; i < conta.parcelas.length; i++) {
          
          let index_forma = formasPagamento.list.findIndex(param => param.id === parseInt(conta.parcelas[i].formapagto_id))
  
          if(formasPagamento.list[index_forma].slug === "cortesia" && parseFloat(conta.parcelas[i].multajuros.replaceAll(".", "").replace(",", ".")) === 0){
            setState((prevState) => ({
              ...prevState,
              modal: {
                mensagem: 'Você selecionou a forma de pagamento "Cortesia" para lançar o desconto por favor informar o valor no campo "Desconto", deseja continuar?',
              },
              modalConfirmacaoCortesiaSalvar: true
            }))
            return false
          }
        }
      }

      await confirmarSalvar(true)
      
    }else{
      handleCloseModalAgendarServico()
    }
  }

  const verificaCaixaInicial = (state) => {
    if (!state.ultimoCaixa || !state.logado)  return {
      mensagem: '',
      libera: true
    }
    
    const { ultimoCaixa, perfilLogado } = state

    if (
      perfilLogado === 'caixa' &&
      (!Object.keys(ultimoCaixa).length || (ultimoCaixa && ultimoCaixa.fechado))
    ) {
      return {
        mensagem: 'É necessário abrir um caixa para realizar o lançamento financeiro',
        libera: false
      }
    }

    if (
      perfilLogado === 'caixa' &&
      ultimoCaixa &&
      !ultimoCaixa.fechado
    ) {

      const dataCaixa = moment(ultimoCaixa.dataabertura).utcOffset('-03:00').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      const hoje = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

      if (hoje.diff(dataCaixa, 'days') > 0) {
        return {
          mensagem: 'O caixa não é de hoje, é necessario fechar o anterior e abrir um novo',
          libera: false
        }
      }
    }

    return {
      mensagem: '',
      libera: true
    }
  }

  const confirmarSalvar = async (value) => {
    if(value){
      setState((prevState) => ({
        ...prevState,
        modal: {
          mensagem: '',
        },
        modalConfirmacaoCortesiaSalvar: false
      }))

      const {agenda, conta, conta_anterior } = state

      try {
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
  
        let dados = {
          ordem_id: agenda.ordem_id,
          unidade_id: acesso_atual[0].id,
          pessoa_id: agenda.cliente_id,
          observacao_geral: agenda.observacao_geral,
          servicos: agenda.servicos.map(servico => {
            return {
              agenda_id: servico.agenda_id,
              item_pedido_id: servico.item_pedido_id,
              produto_id: servico.servico_id,
              ambiente_id: servico.ambiente_id,
              atendente_id: servico.atendente_id || null,
              data_inicio: servico.data_inicio,
              hora_fim: servico.hora_fim,
              hora_inicio: servico.hora_inicio,
              duracao: servico.duracao,
              observacao: servico.observacao,
              obrigatoriedade: servico.obrigatoriedade,
              valor: parseFloat(servico.valor.replaceAll(".", "").replace(",", ".")),
              situacao: servico.situacao,
              editado: servico.editado,
              voucher: servico.voucher ? true : false,
              recorrencia: servico.recorrencia,
              enviar_alerta: servico.enviar_alerta,
              add_preparo: servico.add_preparo,
              add_limpeza: servico.add_limpeza,
              duracao_preparo: servico.duracao_preparo,
              duracao_limpeza: servico.duracao_limpeza,
              preparo_id: servico.preparo_id,
              limpeza_id: servico.limpeza_id,
              index_preparo: '',
              index_limpeza: '',
              dadosRecorrencia: {
                qtdRecorrencia: parseInt(servico.dadosRecorrencia.qtdRecorrencia),
                recorrenciaACada: parseInt(servico.dadosRecorrencia.recorrenciaACada),
                tipoRecorrencia: servico.dadosRecorrencia.tipoRecorrencia
              },
              atendente: servico.atendente,
              ambiente: servico.ambiente
            }
          })
        }

        for (let i = 0; i < dados.servicos.length; i++) {
          const element = dados.servicos[i];
          
          if(!element.recorrencia){
            delete element.dadosRecorrencia
          }else{
            let dias_agendas = []

            for (let i = 0; i < element.dadosRecorrencia.qtdRecorrencia; i++) {
  
              let data_agenda = moment(element.data_inicio).add((i * element.dadosRecorrencia.recorrenciaACada), element.dadosRecorrencia.tipoRecorrencia).format('YYYY-MM-DD') 

              const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

              let dados = {
                unidade_id: acesso_atual[0].id,
                servico_id: element.produto_id,
                pessoa_id: agenda.cliente_id,
                data: data_agenda,
                inicio: element.hora_inicio,
                fim: element.hora_fim,
                agenda_id: element.agenda_id || '',
                ordem_id: '',
                duracao_preparo: element.duracao_preparo || 0,
                duracao_limpeza: element.duracao_limpeza || 0,
                servicos: agenda.servicos.map(servico => {
                  return {
                    agenda_id: servico.agenda_id || '',
                    item_pedido_id: servico.item_pedido_id || ''
                  }
                })
              }
              
              let resp = await verificaSalasAtendentes(dados)

              let index_atendente = resp.funcionarios.findIndex(param => param.id === element.atendente_id)

              let {servicos} = state

              let dia_agenda = {
                data_agenda,
                hora_inicio: element.hora_inicio,
                servico: servicos.list.filter(param => param.id === element.produto_id)[0].nome,
                servico_id: element.produto_id, 
                sala_disponivel: true,
                atendente_disponivel: true,
                atendente: {
                  id: element.atendente_id,
                  nome: element.atendente
                },
                sala:{
                  id: element.ambiente_id,
                  nome: element.ambiente
                },
                atendentes: resp.funcionarios.map(value => {
                  return {
                    id: value.id,
                    nome: value.fisica.nome
                  }
                }),
                salas: resp.salas.map(value => {
                  return {
                    id: value.id,
                    nome: value.nome
                  }
                }),
              }
              
              if(index_atendente === -1){
                dia_agenda.atendente_disponivel = false
                dia_agenda.atendente = {
                  id: '',
                  nome: ''
                }
              }

              let index_ambiente = resp.salas.findIndex(param => param.id === element.ambiente_id)

              if(index_ambiente === -1){
                dia_agenda.sala_disponivel = false
                dia_agenda.sala = {
                  id: '',
                  nome: ''
                }
              }

              dias_agendas.push({...dia_agenda})
            }
                        
            let agendas_indisponivel = dias_agendas.filter(param => !param.sala_disponivel || !param.atendente_disponivel)
            
            if(agendas_indisponivel.length > 0){
              setState((prevState) => ({
                ...prevState,
                modalConfirmarAgendaIndisponivel: true,
                agendas_indisponivel: agendas_indisponivel
              }))
              return
            }
          }

          dados.servicos[i] = element
        }
  
        if(state.logado.caixa){
          const ultimo = JSON.parse(localStorage.getItem('ultimoCaixa'))
          dados.caixa = ultimo.id
        }
    
        if(agenda.voucher_id !== ''){
          dados.voucher_id = agenda.voucher_id
        }
  
        if(dados.ordem_id === '' || !dados.ordem_id){
          delete dados.ordem_id
        }
  
        if(agenda.informar_financeiro){
          dados.conta = {
            id: conta.id,
            valortotal: parseFloat(conta.valor_total.replaceAll(".", "").replace(",", ".")),
            gerar_nfse: conta.gerar_nfse,
            parcelas: conta.parcelas.map(parcela => {
              let vouchers_credito = parcela.vouchersCreditoSelecionados

              for (const value of parcela.vouchersProdutosSelecionados) {
                vouchers_credito.push({
                  voucher_id: state.voucherSelecionado.id,
                  valor_a_ultilizar: value.valor,
                  produtos_voucher: [{
                    id: value.produto_voucher_id
                  }]
                })
              }

              return {
                id: parcela.id,
                datavencimento: parcela.datavencimento,
                valorparcela: parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")),
                multajuros: parseFloat(parcela.multajuros.replaceAll(".", "").replace(",", ".")) || 0,
                num: parcela.num,
                contfin_id: parcela.contfin_id ? parseInt(parcela.contfin_id) : null,
                formapagto_id: parseInt(parcela.formapagto_id),
                baixado: parcela.baixado,
                vouchers_credito
              }
            })
          }
          
          if(dados.conta.id === '' || !dados.conta.id){
            delete dados.conta.id
          }
  
          for (let i = 0; i < dados.conta.parcelas.length; i++) {
            
            if(dados.conta.parcelas[i].id === '' || !dados.conta.parcelas[i].id){
              delete dados.conta.parcelas[i].id
            }
  
            if(dados.conta.parcelas[i].vouchers_credito){
              if(dados.conta.parcelas[i].vouchers_credito.length === 0){
                delete dados.conta.parcelas[i].vouchers_credito
              }
            }
          }
  
          let soma_parcelas = parseFloat((somarValores(dados.conta.parcelas.map(value => {return value.valorparcela}))).toFixed(2))
  
          if(parseFloat(soma_parcelas.toFixed(2)) > dados.conta.valortotal && dados.conta.valortotal !== 0){
  
            if(!dados.voucher_id){
              setState((prevState) => ({
                ...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: 'O valor total das parcelas é maior que o valor total da conta!'
                },
                loadingSalva: false,
                tab: 1
              }))
        
              return
            }else{
              if(dados.conta.parcelas.length === 1){
                dados.conta.parcelas[0].valorparcela = dados.conta.valortotal
              }
            }
  
          }
  
          if(soma_parcelas < dados.conta.valortotal){
            setState((prevState) => ({
              ...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'O valor total das parcelas é menor que o valor total da conta!'
              },
              loadingSalva: false,
              tab: 1
            }))
      
            return
          }
  
          for (let i = 0; i < dados.conta.parcelas.length; i++) {
            if(dados.conta.parcelas[i].formapagto_id === 9){
              delete dados.conta.parcelas[i].contfin_id
            }
          }
        }
        
        if(conta_anterior.id !== '' && conta.id === ''){
          dados.conta_anterior = {
            id: conta_anterior.id,
            parcelas: conta_anterior.parcelas.map(parcela => {
              return {
                id: parcela.id,
              }
            })
          }
        }
  
        const index_finalizado = dados.servicos.findIndex(param => param.situacao === 'FINALIZADO')
  
        if(index_finalizado !== -1){
          if(!agenda.informar_financeiro){
  
            agenda.informar_financeiro = true
  
            await consultarFormaPagto(state.perm_alt_voucher_agenda)
            await consultarContasFinanceira()
  
            conta.parcelas[0].valorparcela = conta.valor_total
  
            setState((prevState) => ({
              ...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Para finalizar o atendimento é necessário informar os dados de pagamento!`
              },
              loadingSalva: false,
              agenda,
              conta,
              tab: 1
            }))
            return
          }
        }

        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Cadastrando Agenda...'
          }
        }))
  
        if(agenda.ordem_id){ 
          const {data} = await axios.put(`${window._env_.REACT_APP_API_URL}/agendaAmbiente/${agenda.ordem_id}`, dados, getToken())
        
          if(agenda.informar_financeiro){
            for (const parcela of data.parcelasSalvas) {
              let dadosGera = {
                parcelas : [parcela]
              }
    
              setState((prevState) => ({
                ...prevState,
                alerta: {
                  open: true,
                  severity: 'info',
                  message: 'Gerando o financeiro...'
                }
              }))
    
              if(parcela.forma_pgto === 'boleto'){
                await axios.post(`${window._env_.REACT_APP_API_URL}/boleto`, dadosGera, getToken())
              }else if (parcela.forma_pgto === 'pix'){
                // await axios.post(`${window._env_.REACT_APP_API_URL}/pix`, dadosGera, getToken())
              }
            }
          }
        }else{
          const {data} = await axios.post(`${window._env_.REACT_APP_API_URL}/agendaAmbiente`, dados, getToken())
          
          localStorage.setItem('ultima_agenda', JSON.stringify(data.agendasResp[0].id))

          if(agenda.informar_financeiro){
            for (const parcela of data.parcelasSalvas) {
              let dadosGera = {
                parcelas : [parcela]
              }
    
              setState((prevState) => ({
                ...prevState,
                alerta: {
                  open: true,
                  severity: 'info',
                  message: 'Gerando o financeiro...'
                }
              }))
    
              if(parcela.forma_pgto === 'boleto'){
                await axios.post(`${window._env_.REACT_APP_API_URL}/boleto`, dadosGera, getToken())
              }else if (parcela.forma_pgto === 'pix'){
                // await axios.post(`${window._env_.REACT_APP_API_URL}/pix`, dadosGera, getToken())
              }
            }
          }
        }
  
        handleCloseModalAgendarServico()
  
        localStorage.setItem('data_filtro_agenda', JSON.stringify(dados.servicos[0].data_inicio))
        localStorage.setItem('hora_filtro_agenda', JSON.stringify(dados.servicos[0].hora_inicio))
        
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: 'Cadastro realizado com sucesso.'
          },
          loadingSalva: false
        }))
        
        handleModalCancelarNFSE()
        window.location.reload()
                
      } catch (error) {
        console.log(error)
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          loadingSalva: false
        }))
      }
    }else{
      handleModalCancelarNFSE()
      setState((prevState) => ({
        ...prevState,
        modal: {
          mensagem: '',
        },
        modalConfirmacaoCortesiaSalvar: false,
        loadingSalva: false
      }))
    }
  }

  const handleCloseModalAgendarServico = async () => {
    setState((prevState) => ({
      ...prevState,
      agenda:{
        ordem_id: '',
        cliente: '',
        situacao: 'CONFIRMADO',
        observacao_geral: '',
        servicos: [],
        informar_financeiro: false,
        voucher_id: '',
        voucher: ''
      },
      servicoAgenda: {
        servico: '',
        servico_id: '',
        atendente: '',
        atendente_id: '',
        ambiente: '',
        ambiente_id: '',
        data_inicio: moment().format('YYYY-MM-DD'),
        hora_inicio: '',
        hora_fim: '',
        hora_inicio_tela: '',
        hora_fim_tela: '',
        duracao: 0,
        duracao_tela: 0,
        background_color: '',
        color: '',
        observacao: '',
        index: '',
        obrigatoriedade: false,
        valor: real(0),
        valor_total_minimo: real(0),
        valor_total_maximo: real(0),
        situacao: 'CONFIRMADO',
        agenda_id: '',
        item_pedido_id: '',
        recorrencia: false,
        enviar_alerta: false,
        add_preparo: false,
        add_limpeza: false,
        duracao_preparo: 0,
        duracao_limpeza: 0,
        preparo_id: '',
        limpeza_id: '',
        index_preparo: '',
        index_limpeza: '',
        dadosRecorrencia: {
          recorrenciaACada: 1,
          tipoRecorrencia: 'd',
          qtdRecorrencia: ''
        }
      },
      salas: {
        list: []
      },
      atendentes: {
        list: []
      },
      servico: {
        nome: '',
        duracao: 0
      },
      atendente:{
        nome: '',
        background_color: '',
        color: ''
      },
      sala: {
        nome: ''
      },
      cliente: {
        nome: '',
        cpf: ''
      },
      conta: {
        id: '',
        valor_total: real(0),
        valor_total_minimo: real(0),
        valor_total_maximo: real(0),
        qtd_parcelas: 1,
        gerar_nfse: false,
        nfse_id: "",
        parcelas: [
          {
            id: '',
            baixado: true,
            datavencimento: "",
            datavencimento_orig: "",
            valorparcela: real(0),
            valorparcela_anterior: real(0),
            multajuros: real(0),
            formapagto_id: "",
            contfin_id: "",
            documento: "",
            habilita: "",
            num: "",
            parcela_editada: false,
            index: 0
          }
        ]
      },
      tab: 0,
      openModalAgendarServico: false,
      financeiro_fechado: false
    }))
    
    await filtrarCliente()
  }

  const verificaSalasAtendentes = async (dados) => {
    try {

      console.log(dados)

      const {data} = await axios.post(`${window._env_.REACT_APP_API_URL}/agendaAmbiente/consultarSalasAtendetes`, dados, getToken())

      console.log(data)

      return data

    } catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleModalCancelarNFSE = () => {
    setState((prevState) => ({
      ...prevState,
      openModalCancelarNFSE: false,
      dadosCancelar: {
        id:'',
        motivo: ''
      }
    }))
  }

  const filtrarCliente = async () =>{
    setState((prevState) => ({
      ...prevState,
      loadingPessoas: true,
    }))

    try {
     
      let {filtro, filtrocliente} = state

      filtro = {
        flag: '',
        ativo: filtro.ativo,
        filtrar_por: filtro.filtrar_por,
        value: filtro.value,
        nome: "",
        cpf_cnpj: "",
        limit: filtro.limit,
        tipo: "FISICA",
        offset: filtro.offset * filtro.limit
      }
      
      filtro[filtrocliente.name] = filtro.value ? filtro.value : ''
      
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      filtro.unidadesnegocio = [acesso_atual[0].id]

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listPrincipal`, filtro, getToken())

      const pessoas = []

      data.list.forEach(pessoa => {
        pessoas.push({
          pessoa_id: pessoa.id,
          tipo: pessoa.tipo,
          nome: pessoa.nome + (pessoa.sobrenome ? ` ${pessoa.sobrenome}` : ''),
          sobrenome: pessoa.sobrenome,
          numero: formataTelefone(pessoa.numero),
          cpf_cnpj: pessoa.cpf_cnpj,
          flags: pessoa.flags.map(flag => {return flag.flag}),
          ativo: pessoa.ativo
        })
      })

      setState((prevState) => ({
        ...prevState,
        clientesFiltrado: {
          list: pessoas
        },
        loadingPessoas: false,
        rows: data.rows,
      }))

    } catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        loadingPessoas: false,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateDuracao = (event) => {
    const {servicoAgenda, inicio_atendimento, fim_atendimento} = state

    var antes = moment(`${servicoAgenda.data_inicio}T${servicoAgenda.hora_inicio}:00.000Z`)
    var depois= moment(`${servicoAgenda.data_inicio}T${servicoAgenda.hora_fim}:00.000Z`)
    
    var minutos = depois.diff(antes, 'minutes');

    servicoAgenda.duracao = minutos

    let inicio_atendimento_form = parseInt(inicio_atendimento.replace(':', ''))
    let fim_atendimento_form = parseInt(fim_atendimento.replace(':', ''))

    let hora_fim_form = parseInt(event.target.value.replace(':', ''))

    if(hora_fim_form < inicio_atendimento_form || hora_fim_form > fim_atendimento_form){
      setState((prevState) => ({
        ...prevState,

        alerta: {
          open: true,
          severity: 'warning',
          message: 'Esse atendimento está fora do horario!'
        },
        loadingSalva: false
      }))

      // document.getElementById('hora_fim').focus();
      return
    }

    setState((prevState) => ({
      ...prevState,
      servicoAgenda
    }))
  }

  const updateFieldAgenda = (event) => {
    const {agenda} = state
    agenda[event.target.name] = event.target.value
    setState((prevState) => ({...prevState, agenda}))
  }

  const ajustaHoraFim = (event) => {
    const { servicoAgenda, inicio_atendimento, fim_atendimento } = state

    const data = servicoAgenda.data_inicio + " " + servicoAgenda.hora_inicio

    let duracao_aux = 0

    if(servicoAgenda.itemCombo){
      if(servicoAgenda.itemCombo.length > 0){
        const index_pricipal = servicoAgenda.itemCombo.findIndex(param => param.principal)
  
        if(index_pricipal !== -1){
          let item_principal = servicoAgenda.itemCombo.filter(param => param.principal)
  
          duracao_aux = item_principal[0].produtoitem.servico.duracao *item_principal[0].quantidade
          
        }else{
          setState((prevState) => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Esse combo não possui item principal cadastrado!'
            }
          }))
  
          return
          
        }
      }else{
        duracao_aux = servicoAgenda.duracao
      }
    }else{
      duracao_aux = servicoAgenda.duracao
    }
    
    let hora = moment(data).add((duracao_aux), 'minutes')
    servicoAgenda.hora_fim = (moment(hora).format('HH:mm'))    
    servicoAgenda.hora_fim_tela = servicoAgenda.hora_fim

    let inicio_atendimento_form = parseInt(inicio_atendimento.replace(':', ''))
    let fim_atendimento_form = parseInt(fim_atendimento.replace(':', ''))

    let hora_inicio_form = parseInt(event.target.value.replace(':', ''))

    if(hora_inicio_form < inicio_atendimento_form || hora_inicio_form > fim_atendimento_form){
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Esse atendimento está fora do horario!'
        },
        loadingSalva: false
      }))
    }

    setState((prevState) => ({
      ...prevState,
      servicoAgenda
    }))
  }

  const buscarPessoa = async () => {
    await filtrarCliente()
    setState((prevState) => ({
      ...prevState,
      modalSelecionaPessoa: true
    }))
  }

  const updateFieldObrigatoriedade = () => {
    const {servicoAgenda} = state

    servicoAgenda.obrigatoriedade = !servicoAgenda.obrigatoriedade

    setState((prevState) => ({
      ...prevState,
      servicoAgenda
    }))
  }

  const updateFieldEnviarAlerta = () => {
    const {servicoAgenda} = state

    servicoAgenda.enviar_alerta = !servicoAgenda.enviar_alerta

    setState((prevState) => ({
      ...prevState,
      servicoAgenda
    }))
  }

  const updateFieldAddPreparo = () => {
    const {servicoAgenda, agenda} = state

    servicoAgenda.add_preparo = !servicoAgenda.add_preparo
    servicoAgenda.atendente = ''
    servicoAgenda.atendente_id = ''
    servicoAgenda.ambiente = ''
    servicoAgenda.ambiente_id =''

    if(!servicoAgenda.add_preparo){
      if(servicoAgenda.index_preparo !== ''){
        let index_preparo = agenda.servicos.findIndex(param => param.index === servicoAgenda.index_preparo)

        agenda.servicos.splice(index_preparo, 1)
        servicoAgenda.index_preparo = ''
      }
    }

    setState((prevState) => ({
      ...prevState,
      servicoAgenda,
      agenda,
      salas: {
        list: []
      },
      sala: {
        id: '',
        nome: '',
        descricao: ''
      },
      atendentes:{
        list: []
      },
      atendente:{
        nome: '',
        background_color: '',
        color: ''
      },
    }))
  }

  const updateFieldAddLimpeza = () => {
    const {servicoAgenda, agenda} = state

    servicoAgenda.add_limpeza = !servicoAgenda.add_limpeza
    servicoAgenda.atendente = ''
    servicoAgenda.atendente_id = ''
    servicoAgenda.ambiente = ''
    servicoAgenda.ambiente_id =''

    if(!servicoAgenda.add_limpeza){
      if(servicoAgenda.index_limpeza !== ''){
        let index_limpeza = agenda.servicos.findIndex(param => param.index === servicoAgenda.index_limpeza)

        agenda.servicos.splice(index_limpeza, 1)
        servicoAgenda.index_limpeza = ''
      }
    }

    setState((prevState) => ({
      ...prevState,
      servicoAgenda,
      salas: {
        list: []
      },
      sala: {
        id: '',
        nome: '',
        descricao: ''
      },
      atendentes:{
        list: []
      },
      atendente:{
        nome: '',
        background_color: '',
        color: ''
      },
    }))
  }

  const handleChange = (event, newValue) => {
    setState((prevState) => ({
      ...prevState,
      tab: newValue
    }))
  }

  const updateFieldParcela = async (event, index) => {
    const {conta, formasPagamento, agenda, dadosTelaVoucherCredito, vouchersCreditoCliente} = state

    let value = event.target.value
    let name = event.target.name

    if(name === 'datavencimento'){
      conta.parcelas[index][name] = value
    }else{
      conta.parcelas[index][name] = value
    }

    if(name === 'formapagto_id'){
      if(value !== ''){
        const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(value))[0]
        
        if(formaPagtoSelecionada.slug === "voucher"){
          let totalConta = parseFloat(conta.parcelas[index].valorparcela.replaceAll(".", "").replace(",", "."))

          dadosTelaVoucherCredito.totalConta = real(totalConta)
          dadosTelaVoucherCredito.totalVouchers = real(0) 
          dadosTelaVoucherCredito.diferenca = real(totalConta)
          dadosTelaVoucherCredito.parcela_index = index

          if(vouchersCreditoCliente.length === 0){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'info',
                message: 'Buscando Vouchers'
              }
            }))

            let vouchers = await buscaVouchersCredito(agenda.cliente_id, 'CREDITO')

            if(vouchers.length > 0){
              setState(prevState => ({...prevState,
                openModalSelecionarVoucherCredito: true,
                alerta: {
                  open: false,
                  severity: 'info',
                  message: ''
                }
              }))
            }else{
              let {conta} = state
      
              conta.parcelas = [
                {
                  id: '',
                  baixado: true,
                  datavencimento: moment(new Date()).format('YYYY-MM-DD'),
                  datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
                  valorparcela: conta.valor_total,
                  multajuros: real(0),
                  formapagto_id: "",
                  contfin_id: "",
                  documento: "",
                  habilita: "",
                  num: 1,
                  parcela_editada: false,
                  index: 0,
                  trava: false,
                  vouchersCreditoSelecionados: [],
                  vouchersCreditoSelecionadosString: '',
                  vouchersProdutosSelecionados: [],
                  vouchersProdutosSelecionadosString: ''
                }
              ]

              conta.numparcela = 1
              conta.qtd_parcelas = 1

              setState(prevState => ({...prevState,
                conta,
                alerta: {
                  open: true,
                  severity: 'info',
                  message: 'Cliente não possui voucher'
                }
              }))
            }
          }else{
            setState(prevState => ({...prevState,
              openModalSelecionarVoucherCredito: true,
            }))
          }

          conta.parcelas[index].baixado = true
          
        }else if(formaPagtoSelecionada.slug === "voucher_produto"){
          let totalConta = parseFloat(conta.parcelas[index].valorparcela.replaceAll(".", "").replace(",", "."))

          dadosTelaVoucherCredito.totalConta = real(totalConta)
          dadosTelaVoucherCredito.totalVouchers = real(0) 
          dadosTelaVoucherCredito.diferenca = real(totalConta)
          dadosTelaVoucherCredito.parcela_index = index

          setState((prevState) => ({
            ...prevState,
            dadosTelaVoucherCredito,
            modalSelecionarProdutosVoucher: true
          }))

          conta.parcelas[index].baixado = true
        }else{
          if(index === 0){
            setState(prevState => ({...prevState,
              vouchersCreditoSelecionados: [],
              vouchersProdutosSelecionados: [],
              dadosTelaVoucherCredito: {
                totalConta: real(0),
                totalVouchers: real(0),
                diferenca: real(0),
                parcela_index: 0
              },
              vouchersCreditoSelecionadosString: '',
              vouchersCliente: []
            }))
          }
        }

        conta.parcelas[index].habilita = formaPagtoSelecionada.habilita
        conta.parcelas[index].contfin_id = ""
      }else{
        conta.parcelas[index].habilita = ""
        conta.parcelas[index].contfin_id = ""
      }
    }

    setState(prevState => ({...prevState, conta, dadosTelaVoucherCredito }))
  }

  const marcaParcelas = (event, index) => {
    
    const {conta, formasPagamento} = state
        
    const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(event.target.value))[0]
    
    for (let i = index; i < conta.parcelas.length; i++) {
      if(formaPagtoSelecionada){
        conta.parcelas[i].formapagto_id = event.target.value
        conta.parcelas[i].habilita = formaPagtoSelecionada.habilita
        conta.parcelas[i].contfin_id = ""
      }
    }

    setState((prevState) => ({...prevState, conta }))
  }

  const updateFieldConta = (event) => {
    const {conta} = state
    conta[event.target.name] = event.target.value
    setState((prevState) => ({...prevState, conta}))
  }

  const recalcularParcela = (event, index) => {
    const conta = { ...state.conta }

    const parcelaEditada = {...conta.parcelas[index]}

    if(parcelaEditada.valorparcela_anterior !== event.target.value){
      let valorTotal = parseFloat(conta.valor_total.replaceAll(".", "").replace(",", "."))
      let qtdParcelasEditado = state.qtdParcelasEditado
      
      if (!parcelaEditada.parcela_editada) {
        conta.parcelas[index].parcela_editada = true
        qtdParcelasEditado += 1
  
        setState((prevState) => ({...prevState,
          qtdParcelasEditado
        }))
      }
  
      if(!conta.parcelas[0].trava){
        
        const parcelasEditada = conta.parcelas.filter(param => param.parcela_editada)
        const somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")) }))
        const diferenca = valorTotal - somaParcelasEditada
        const qtdParcelasNaoEditada = conta.qtd_parcelas - qtdParcelasEditado
              
        let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
        valorDividido = parseFloat(valorDividido)
  
        let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)

        sobra = parseFloat(sobra.toFixed(2))
  
        for (let i = 0; i < conta.qtd_parcelas; i++) {
          if (!conta.parcelas[i].parcela_editada) {
            conta.parcelas[i].valorparcela = moeda(valorDividido)
          }
        }
  
        if (sobra !== 0) {
          conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
        }
  
      }
  
      if(conta.parcelas[0].trava){
        // let valor_restante = valorTotal - parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", "."))
        
        valorTotal = valorTotal - parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", "."))
        const parcelasEditada = conta.parcelas.filter(param => param.parcela_editada)
  
        let somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")) }))
        somaParcelasEditada = somaParcelasEditada - parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", "."))
        
        const diferenca = valorTotal - somaParcelasEditada
        
        const qtdParcelasNaoEditada = conta.qtd_parcelas - qtdParcelasEditado
                
        let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
        valorDividido = parseFloat(valorDividido)
  
        let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)
  
        sobra = parseFloat(sobra.toFixed(2))
          
        for (let i = 1; i < conta.qtd_parcelas; i++) {
          if (!conta.parcelas[i].parcela_editada) {
            conta.parcelas[i].valorparcela = moeda(valorDividido)
          }
        }
  
        if (sobra !== 0) {
          conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
        }
      
      }
  
      conta.parcelas[index].valorparcela_anterior = event.target.value
  
      setState((prevState) => ({...prevState, conta }))
    }
  }

  const ajustarArray = (qtdParcela, array, valorDividido, sobra) => {
    const qtdSobra = parseInt(sobra.toString().replace('-', '').replace('0.0', ''))

    const arrayFalse = array.filter(param => param.parcela_editada === false)
    const arrayTrue = array.filter(param => param.parcela_editada === true)

    for (let i = 0; i < qtdSobra; i++) {
      arrayFalse[arrayFalse.length - (i + 1)].valorparcela = moeda((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
      arrayFalse[arrayFalse.length - (i + 1)].valorparcela_anterior = moeda((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
    }

    for (let i = 0; i < arrayTrue.length; i++) {
      arrayFalse.push(arrayTrue[i])
    }

    return arrayFalse
  }

  const mudarParcelas = async (event) => {
    const {conta, agenda} = state

    if(parseInt(conta.numparcela) !==parseInt(event.target.value)){
      let qtdParcelasInfo = 1
  
      if(parseInt(event.target.value) > 0){
        if(conta.parcelas.findIndex(param => parseInt(param.formapagto_id) === 9) !== -1 && parseInt(event.target.value) === 1){
          qtdParcelasInfo = 2
        }else{
          qtdParcelasInfo = parseInt(event.target.value)
        }
      }
  
      let array = []
      const valorTotal = parseFloat(conta.valor_total.replaceAll(".", "").replace(",", "."))
  
      if(!conta.parcelas[0].trava){
        let valorDividido = valorTotal / qtdParcelasInfo
        valorDividido = parseFloat(valorDividido.toFixed(2))
    
        let sobra = valorTotal - (valorDividido * qtdParcelasInfo)
    
        sobra = parseFloat(sobra.toFixed(2))
    
        for (let i = 0; i < qtdParcelasInfo; i++) {
          let parcela = {
            id: conta.parcelas[i] ? conta.parcelas[i].id : '',
            baixado: true,
            datavencimento: moment().format('YYYY-MM-DD'),
            valorparcela: real(valorDividido),
            valorparcela_anterior: real(valorDividido),
            multajuros: real(0),
            formapagto_id: "",
            documento: "",
            num: i + 1,
            parcela_editada: false,
            index: i,
            trava: false,
            vouchersCreditoSelecionados: [],
            vouchersCreditoSelecionadosString: '',
            vouchersProdutosSelecionados: [],
            vouchersProdutosSelecionadosString: ''
          }
  
          if(parcela.id === '' || !parcela.id){
            delete parcela.id
          }
  
          array.push({
            ...parcela
          })
        }
  
        if (sobra !== 0) {
          array = ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
        }
  
        conta.numparcela = qtdParcelasInfo
        conta.qtd_parcelas = qtdParcelasInfo
      }
  
      if(conta.parcelas[0].trava){
        array.push({...conta.parcelas[0]})
  
        let valor_restante = valorTotal -  parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", "."))
        qtdParcelasInfo -= 1
  
        let valorDividido = valor_restante / qtdParcelasInfo
  
        valorDividido = parseFloat(valorDividido.toFixed(2))
              
        let sobra = valor_restante - (valorDividido * qtdParcelasInfo)
  
        sobra = parseFloat(sobra.toFixed(2))
  
        for (let i = 0; i < qtdParcelasInfo; i++) {
          array.push({
            baixado: true,
            datavencimento: moment().format('YYYY-MM-DD'),
            valorparcela: real(valorDividido),
            valorparcela_anterior: real(valorDividido),
            multajuros: real(0),
            formapagto_id: "",
            documento: "",
            num: i + 2,
            parcela_editada: false,
            index: i + 1,
            trava: false,
            vouchersCreditoSelecionados: [],
            vouchersCreditoSelecionadosString: '',
            vouchersProdutosSelecionados: [],
            vouchersProdutosSelecionadosString: ''
          })
  
          if (sobra !== 0) {
            array = ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
          }
        }
  
        conta.numparcela = qtdParcelasInfo + 1
        conta.qtd_parcelas = qtdParcelasInfo + 1
  
      }
      
      conta.parcelas = array
      conta.qtd_parcelas = conta.parcelas.length
  
      await buscaVouchersCredito(agenda.cliente_id, 'CREDITO')
      
      setState((prevState) => ({ 
        ...prevState,
        conta,
        qtdParcelasEditado: conta.parcelas.findIndex(param => parseInt(param.formapagto_id) === 9) !== -1 ? 1 : 0,
        dadosTelaVoucherCredito: {
          totalConta: real(0),
          totalVouchers: real(0),
          diferenca: real(0),
          parcela_index: 0
        }
      }))
    }

  }

  const verificaValor = (event) => {
    const {servicoAgenda, valor_servico_dif} = state

    let index_servico = valor_servico_dif.findIndex(param => param.servico_id === servicoAgenda.servico_id)

    let valor_minimo = 0
    let valor_maximo = 0

    console.log(servicoAgenda)

    if(index_servico === -1){
      valor_minimo = parseFloat(servicoAgenda.valor_total_minimo.replaceAll(".", "").replace(",", "."))
      valor_maximo = parseFloat(servicoAgenda.valor_total_maximo.replaceAll(".", "").replace(",", "."))
    }else{
      valor_minimo = valor_servico_dif[index_servico].valor_tabela_minimo
      valor_maximo = valor_servico_dif[index_servico].valor_tabela_maximo
    }

    const valor_informado = parseFloat(event.target.value.replaceAll(".", "").replace(",", "."))
    if((valor_informado > valor_maximo) && valor_maximo > 0){
      setState((prevState) => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'O valor informado ultrapassa o valor máximo do serviço.'
        }
      }))

      servicoAgenda.valor = servicoAgenda.valor_total_maximo
    }

    if(valor_informado < valor_minimo){
      setState((prevState) => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'O valor informado é inferiro ao valor mínimo do serviço.'
        }
      }))

      servicoAgenda.valor = servicoAgenda.valor_total_maximo
    }

    setState((prevState) => ({...prevState,
      servicoAgenda
    }))
  }

  const updateFieldInformarFinanceiro = async () => {
    const {agenda, conta} = state
    
    agenda.informar_financeiro = !agenda.informar_financeiro

    const valor_total = somarValores(agenda.servicos.map(value => {return parseFloat(value.valor.replaceAll(".", "").replace(",", "."))}))

    conta.qtd_parcelas = 1
    conta.parcelas = [{
      id: '',
      baixado: true,
      datavencimento: moment().format('YYYY-MM-DD'),
      datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
      valorparcela: real(valor_total),
      valorparcela_anterior: real(valor_total),
      multajuros: real(0),
      formapagto_id: "",
      contfin_id: "",
      documento: "",
      habilita: "",
      num: 1,
      parcela_editada: false,
      index: 0,
      trava: false,
      vouchersCreditoSelecionados: [],
      vouchersCreditoSelecionadosString: '',
      vouchersProdutosSelecionados: [],
      vouchersProdutosSelecionadosString: ''
    }]

    if(agenda.informar_financeiro){
      await consultarFormaPagto(state.perm_alt_voucher_agenda)
      await consultarContasFinanceira()
    }
    
    setState((prevState) => ({...prevState, agenda, conta}))
  }

  const openCadastroCliente = () => {
    localStorage.setItem('ultima_tela_cliente', '/agenda_ambiente');

    navigate("/cliente/cadastro");
    return;
  }

  const verificaDataParcela = (event, index) => {
    const {conta} = state
        
    for (let i = (index + 1); i < conta.parcelas.length; i++) {
      let count = i - index

      let novaData = moment(event.target.value).add(count, 'months').format('YYYY-MM-DD')

      conta.parcelas[i].datavencimento = novaData
      conta.parcelas[i].datavencimento_orig = novaData
    }

    setState((prevState) => ({ ...prevState, conta }))
  }

  const removerVoucher = async () => {
    const {agenda, conta} = state
    
    let index_voucher = agenda.servicos.findIndex(param => param.voucher)

    if(index_voucher !== -1){
      setState((prevState) => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Não é possível remover o voucher, existe um atendimento usando esse voucher, por favor remover o atendimento!'
        }
      }))
      return
    }

    agenda.voucher_id = ''
    agenda.voucher = ''
    agenda.informar_financeiro = false
    // agenda.servicos = []

    conta.parcelas[0].trava = false
    conta.parcelas[0].parcela_editada = false
    conta.parcelas[0].formapagto_id = ''
    conta.parcelas[0].baixado = true

    setState((prevState) => ({...prevState,
      agenda,
      voucherSelecionado: {},
      servicoAgenda: {
        servico: '',
        servico_id: '',
        atendente: '',
        atendente_id: '',
        ambiente: '',
        ambiente_id: '',
        data_inicio: moment().format('YYYY-MM-DD'),
        hora_inicio: '',
        hora_inicio_tela: '',
        hora_fim: '',
        hora_fim_tela: '',
        duracao: 0,
        duracao_tela: 0,
        background_color: '',
        color: '',
        observacao: '',
        index: '',
        obrigatoriedade: false,
        valor: real(0),
        valor_total_minimo: real(0),
        valor_total_maximo: real(0),
        itemCombo: [],
        situacao: 'CONFIRMADO',
        agenda_id: '',
        recorrencia: false,
        enviar_alerta: false,
        add_preparo: false,
        add_limpeza: false,
        duracao_preparo: 0,
        duracao_limpeza: 0,
        preparo_id: '',
        limpeza_id: '',
        index_preparo: '',
        index_limpeza: '',
        dadosRecorrencia: {
          recorrenciaACada: 1,
          tipoRecorrencia: 'd',
          qtdRecorrencia: ''
        }
      },
      servico: {
        id: '',
        nome: '',
        unidademedida: '',
        duracao: 0
      },
      salas: {
        list: []
      },
      sala: {
        id: '',
        nome: '',
        descricao: ''
      },
      atendentes:{
        list: []
      },
      atendente:{
        nome: '',
        background_color: '',
        color: ''
      },
      qtdParcelasEditado: 0,
    }))
  }

  const updateFiltroPessoa = async (event) =>{
    if(event.target.value.length >= 3){
      await consultarPessoa(event.target.value)
    }else if(event.target.value.length < 3){
      setState(prevState => ({...prevState,
        clientesFiltrado: {
          list: []
        }
      }))
    }
  }

  const consultarPessoa = async (nome) =>{
    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }
  
      let filtroPessoa = {
        unidadesnegocio: dados.unidadesnegocio,
        ativo: 'Sim',
        nome: nome,
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listSearch`, filtroPessoa, getToken())

      const pessoas = data.map(pessoa => {
        return {
          pessoa_id: pessoa.id,
          nome: pessoa.nome + (pessoa.sobrenome ? ` ${pessoa.sobrenome}` : ''),
        }
      })

      setState((prevState) => ({...prevState,
        clientesFiltrado: {
          list: pessoas.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)
        }
      }))

    } catch (error) {
      console.log(error)
      setState((prevState) => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultarTabela = async (event) => {
    const {servicoAgenda, servico, agenda, financeiro_fechado} = state

    const value = event.target.value

    if(agenda.voucher_id === '' && !financeiro_fechado){
      try {
        let resp = await consultarTabelaPreco(parseInt(servico.id), value)
              
        if(!resp.preco_produto){
          setState((prevState) => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Não foi encontrada nenhuma tabela de preço para esse serviço.'
            }
          }))
          servicoAgenda.valor = real(0)
          servicoAgenda.valor_total_minimo = real(0)
          servicoAgenda.valor_total_maximo = real(0)
                    
          setState((prevState) => ({...prevState,
            travarBotao: true
          }))
  
        }else{
          servicoAgenda.valor = real(resp.preco_produto.valor_maximo)
          servicoAgenda.valor_total_minimo = real(resp.preco_produto.valor_minimo)
          servicoAgenda.valor_total_maximo = real(resp.preco_produto.valor_maximo)
  
          setState((prevState) => ({...prevState,
            travarBotao: false
          }))
        }
      
  
        setState((prevState) => ({...prevState,
          servicoAgenda
        }))
      } catch (error) {
        console.log(error)
        setState((prevState) => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }

    if(moment().format('YYYY') !== moment(value).format('YYYY')){
      setState((prevState) => ({...prevState,
        modal: {
          mensagem: 'O ano informado na data do atendimento é diferente do ano atual.',
        },
        modalOpenConfirmacaoData: true,
      }))
    }

    if(value === moment().format('YYYY-MM-DD')){
      servicoAgenda.situacao = 'CONFIRMADO'

      setState((prevState) => ({...prevState,
        servicoAgenda
      }))
    }else{
      servicoAgenda.situacao = 'AGENDADO'

      setState((prevState) => ({...prevState,
        servicoAgenda
      }))
    }

    return
  }

  const openVouchers = (index) =>{
    const {financeiro_fechado, dadosTelaVoucherCredito, conta} = state

    if(!financeiro_fechado){
      dadosTelaVoucherCredito.totalConta = conta.valortotal
      dadosTelaVoucherCredito.totalVouchers = real(somarValores(conta.parcelas[index].vouchersCreditoSelecionados.map(value => {return value.valor_a_ultilizar}))) 
      dadosTelaVoucherCredito.diferenca = real(parseFloat(dadosTelaVoucherCredito.totalConta.replaceAll(".", "").replace(",", ".")) - parseFloat(dadosTelaVoucherCredito.totalVouchers.replaceAll(".", "").replace(",", ".")))
      dadosTelaVoucherCredito.parcela_index = index

      setState((prevState) => ({...prevState,
        openModalSelecionarVoucherCredito: true,
        vouchersCreditoSelecionados: conta.parcelas[index].vouchersCreditoSelecionados,
        dadosTelaVoucherCredito
      }))
    }else{
      setState((prevState) => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Financeiro fechado, não é possivel alterar os vouchers'
        }
      }))
    }
  }

  const openVouchersProdutos = () =>{
    const {financeiro_fechado} = state
    if(!financeiro_fechado){
      
    }else{
      setState((prevState) => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Financeiro fechado, não é possivel alterar os vouchers'
        }
      }))
    }
  }

  const updateFieldGerarNfseConta = (index) => {
    const {conta} = state

    conta.gerar_nfse = !conta.gerar_nfse

    setState((prevState) => ({...prevState, conta}))
  }

  const verificaCortesia = (event, index) => {
    let {formasPagamento, conta} = state
        
    let index_forma = formasPagamento.list.findIndex(param => param.id === parseInt(event.target.value))
        
    if(index_forma !== -1){
      if(formasPagamento.list[index_forma].slug === "cortesia" && parseFloat(conta.parcelas[index].multajuros.replaceAll(".", "").replace(",", ".")) === 0){
        setState((prevState) => ({
          ...prevState,
          modal: {
            mensagem: 'Você selecionou a forma de pagamento "Cortesia" para lançar o desconto por favor informar o valor no campo "Desconto", deseja continuar?',
          },
          modalConfirmacaoCortesia: true
        }))
      }
    }
  }

  const swapServicos = async () => {
    let {swapServicos, voucherSelecionado} = state

    if(swapServicos === 'todos'){
      
      setState((prevState) => ({...prevState,
        servicos: {
          list: voucherSelecionado.produtos.filter(param => (!param.utilizado && param.produto.servico)).map(value => {
            return {
              id: value.produto.id,
              nome: value.produto.descricao,
              unidademedida: value.produto.unidademedida,
              itemCombo: value.produto.itemCombo,
              duracao: value.produto.servico.duracao,
              valor: value.valor,
              voucher: true
            }
          })
        },
        swapServicos: 'voucher'
      }))

      setState((prevState) => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Lista com Todos os Serviço do Voucher'
        }
      }))
      
    }else{
      setState((prevState) => ({...prevState,
        swapServicos: 'todos'
      }))

      await consultaServicos()

      setState((prevState) => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Lista com Todos os Serviço'
        }
      }))
    }
  }

  const updateRecorrencia = () => {
    let {servicoAgenda, agenda} = state

    if(!servicoAgenda.recorrencia && (servicoAgenda.situacao === 'FINALIZADO' || servicoAgenda.situacao === 'DESMARCADO')){
      setState((prevState) => ({...prevState, 
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Não é possivel lançar um recorrência de agendas finalizada/desmarcado!'
        },
        loadingSalva: false
      }))

      return
    }

    if(agenda.voucher_id !== '' && !servicoAgenda.recorrencia){
      setState((prevState) => ({...prevState, 
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Não é possivel lançar um recorrência com um voucher selecionado'
        },
        loadingSalva: false
      }))

      return
    }

    if(!servicoAgenda.recorrencia){
      agenda.informar_financeiro = false
    }

    servicoAgenda.recorrencia = !servicoAgenda.recorrencia

    setState((prevState) => ({...prevState, 
      servicoAgenda,
      agenda
    }))
  }

  const updateFieldRecorrencia = (event) => {
    let {servicoAgenda} = state
    
    servicoAgenda.dadosRecorrencia[event.target.name] = event.target.value

    setState((prevState) => ({...prevState, 
      servicoAgenda
    }))
  }

  const handleCloseModalPessoa = () => {
    setState((prevState) => ({
      ...prevState,
      modalSelecionaPessoa: false,
      clientes:{
        list:[]
      },
      clientesFiltrado: {
        list: []
      },
    }))
  }

  const updateFiltro = (event) => {
    const filtro = state.filtro

    if (event.target.name === 'filtrar_por') {    
      setState((prevState) => ({
        ...prevState,
        filtrocliente: {
          label: event.target.value.split(' - ')[0],
          name: event.target.value.split(' - ')[1]
        }
      }))

      filtro[event.target.name] = event.target.value

      setState((prevState) => ({
        ...prevState,
        filtro
      }))
    }else{
      filtro[event.target.name] = event.target.value
      
      setState((prevState) => ({
        ...prevState,
        filtro
      }))
    }
  }

  const selecionarPessoa = async (row) => {

    const { agenda } = state

    agenda.cliente = row.nome
    agenda.cliente_id = row.pessoa_id

    await buscaVouchers(row.pessoa_id, 'PRODUTO')
    await buscaVouchersCredito(row.pessoa_id, 'CREDITO')

    setState((prevState) => ({
      ...prevState,
      cliente: {
        pessoa_id: row.pessoa_id,
        tipo: row.tipo,
        flags: row.flags,
        nome: row.nome,
        cpf_cnpj: row.cpf_cnpj,
        ativo: row.ativo
      },
      modalSelecionaPessoa: false,
      agenda
    }))
  }

  const handleChangePage = async (event, newPage) =>{
    const {filtro} = state
    filtro.offset = newPage
    setState((prevState) => ({
      ...prevState,
      filtro
    }))

    await filtrarCliente()
  }

  const handleChangeRowsPerPage = async (event) => {
    const {filtro} = state
    filtro.limit = parseInt(event.target.value)
    setState((prevState) => ({
      ...prevState,
      filtro
    }))

    await filtrarCliente()
  }

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      modalOpen: false,
      modalOpenConfirmacaoDifVoucher: false,
      modalConfirmacaoCortesia: false,
      modalConfirmacaoCortesiaSalvar: false
    }))
  }

  const confirmarFecharModal = (value) =>{
    if(value){
      handleCloseModalAgendarServico()
      handleClose()
    }else{
      handleClose()
    }
  }

  const handleCloseData = () => {
    setState((prevState) => ({
      ...prevState,
      modal: {
        mensagem: '',
      },
      modalOpenConfirmacaoData: false,
    }))
  }

  const confirmarFecharModalData = (value) => {
    setState((prevState) => ({
      ...prevState,
      modal: {
        mensagem: '',
      },
      modalOpenConfirmacaoData: false,
    }))

    return
  }

  const confirmarAlterarValores = (value) => {
    const {produtos_diff, agenda, servicoAgenda, conta} = state
    if(value){
      handleClose()
    
      let servicos = agenda.servicos

      for (const value of produtos_diff) {
        servicos[value.index].valor = real(value.valor_voucher)
      }

      agenda.servicos = servicos

      setState((prevState) => ({
        ...prevState,
        agenda,
        produtos_diff: []
      }))

      conta.parcelas = [
        {
          id: '',
          baixado: true,
          datavencimento: moment(new Date()).format('YYYY-MM-DD'),
          datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
          valorparcela: real(0),
          valorparcela_anterior: real(0),
          multajuros: real(0),
          formapagto_id: "",
          contfin_id: "",
          documento: "",
          habilita: "",
          num: 1,
          parcela_editada: false,
          index: 0,
          trava: false,
          vouchersCreditoSelecionados: [],
          vouchersCreditoSelecionadosString: '',
          vouchersProdutosSelecionados: [],
          vouchersProdutosSelecionadosString: ''
        }
      ]

      let valor_vouchers = 0
      let valor_nao_vouchers = 0 

      for (const servico of agenda.servicos) {
        if(servico.situacao !== 'DESMARCADO'){
          if(servico.voucher){
            valor_vouchers += parseFloat(servico.valor.replaceAll(".", "").replace(",", "."))
          }else{
            valor_nao_vouchers += parseFloat(servico.valor.replaceAll(".", "").replace(",", "."))
          }
        }
      }      

      if(valor_vouchers > 0){
        agenda.informar_financeiro = true
        if((parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) === 0)){
          conta.parcelas[0].valorparcela = real(valor_vouchers)
          conta.parcelas[0].valorparcela_anterior = real(valor_vouchers)
          conta.parcelas[0].trava = true
          conta.parcelas[0].parcela_editada = true
          conta.parcelas[0].formapagto_id = '9'
          conta.parcelas[0].baixado = true
          conta.parcelas[0].num = 1
          conta.parcelas[0].datavencimento = moment(new Date()).format('YYYY-MM-DD')
        }else if((parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) !== 0) && parseInt(conta.parcelas[0].formapagto_id) === 9){
          conta.parcelas[0].valorparcela = real(valor_vouchers)
          conta.parcelas[0].valorparcela_anterior = real(valor_vouchers)
        }else if((parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) === 0) && parseInt(conta.parcelas[0].formapagto_id) === 9){
          conta.parcelas[0].valorparcela = real(valor_vouchers)
          conta.parcelas[0].valorparcela_anterior = real(valor_vouchers)
        }else{
          conta.parcelas.unshift({
            id: '',
            baixado: true,
            datavencimento: moment(new Date()).format('YYYY-MM-DD'),
            datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
            valorparcela: real(valor_vouchers),
            valorparcela_anterior: real(valor_vouchers),
            multajuros: real(0),
            formapagto_id: 9,
            contfin_id: "",
            documento: "",
            habilita: "",
            num: 1,
            parcela_editada: true,
            index: 0,
            trava: true,
            vouchersCreditoSelecionados: [],
            vouchersCreditoSelecionadosString: '',
            vouchersProdutosSelecionados: [],
            vouchersProdutosSelecionadosString: ''
          })

          for (let i = 0; i < conta.parcelas.length; i++) {
            conta.parcelas[i].index = i
          }
        }

        setState((prevState) => ({
          ...prevState,
          qtdParcelasEditado: 1
        }))
      }

      if(valor_nao_vouchers > 0){
        if(conta.parcelas.length === 1){
          if(parseInt(conta.parcelas[0].formapagto_id) === 9){
            conta.parcelas.push({
              id: '',
              baixado: true,
              datavencimento: moment(new Date()).format('YYYY-MM-DD'),
              datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
              valorparcela: real(valor_nao_vouchers),
              valorparcela_anterior: real(valor_nao_vouchers),
              multajuros: real(0),
              formapagto_id: "",
              contfin_id: "",
              documento: "",
              habilita: "",
              num: conta.parcelas.length + 1,
              parcela_editada: false,
              index: conta.parcelas.length,
              trava: false,
              vouchersCreditoSelecionados: [],
              vouchersCreditoSelecionadosString: '',
              vouchersProdutosSelecionados: [],
              vouchersProdutosSelecionadosString: ''
            })

            for (let i = 0; i < conta.parcelas.length; i++) {
              conta.parcelas[i].index = i
            }
          }else{
            conta.parcelas[0].valorparcela = real(valor_nao_vouchers)
            conta.parcelas[0].valorparcela_anterior = real(valor_nao_vouchers)
          }
        }else{
          conta.parcelas[1].valorparcela = real(valor_nao_vouchers)
          conta.parcelas[1].valorparcela_anterior = real(valor_nao_vouchers)
        }
      }

      if(valor_vouchers > 0 & valor_nao_vouchers > 0){
        conta.parcelas = [
          {
            id: '',
            baixado: true,
            datavencimento: moment(new Date()).format('YYYY-MM-DD'),
            datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
            valorparcela: real(valor_vouchers),
            valorparcela_anterior: real(valor_vouchers),
            multajuros: real(0),
            formapagto_id: "9",
            contfin_id: "",
            documento: "",
            habilita: "",
            num: 1,
            parcela_editada: true,
            index: 0,
            trava: true,
            vouchersCreditoSelecionados: [],
            vouchersCreditoSelecionadosString: '',
            vouchersProdutosSelecionados: [],
            vouchersProdutosSelecionadosString: ''
          },
          {
            id: '',
            baixado: true,
            datavencimento: moment(new Date()).format('YYYY-MM-DD'),
            datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
            valorparcela: real(valor_nao_vouchers),
            valorparcela_anterior: real(valor_nao_vouchers),
            multajuros: real(0),
            formapagto_id: "",
            contfin_id: "",
            documento: "",
            habilita: "",
            num: 2,
            parcela_editada: false,
            index: 1,
            trava: false,
            vouchersCreditoSelecionados: [],
            vouchersCreditoSelecionadosString: '',
            vouchersProdutosSelecionados: [],
            vouchersProdutosSelecionadosString: ''
          }
        ]
      }
      
      if(servicoAgenda.servico_id !== ''){
        addServico(true)
      }

      conta.valor_total = real(somarValores(conta.parcelas.map(parcela => { return parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")) })))
      conta.numparcela = conta.parcelas.length

      setState((prevState) => ({
        ...prevState,
        conta
      }))

    }else{
      agenda.voucher_id = ''
      agenda.voucher = ''
      agenda.informar_financeiro = false

      for (let i = 0; i < agenda.servicos.length; i++) {
        agenda.servicos[i].voucher = false
      }

      setState((prevState) => ({
        ...prevState,
        conta: {
          id: '',
          valor_total: '0,00',
          valor_total_minimo: real(0),
          valor_total_maximo: real(0),
          qtd_parcelas: 1,
          gerar_nfse: false,
          nfse_id: "",
          parcelas: [
            {
              id: '',
              baixado: true,
              datavencimento: moment(new Date()).format('YYYY-MM-DD'),
              datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
              valorparcela: real(0),
              valorparcela_anterior: real(0),
              multajuros: real(0),
              formapagto_id: "",
              contfin_id: "",
              documento: "",
              habilita: "",
              num: 1,
              parcela_editada: false,
              index: 0,
              trava: false,
              vouchersCreditoSelecionados: [],
              vouchersCreditoSelecionadosString: '',
              vouchersProdutosSelecionados: [],
              vouchersProdutosSelecionadosString: ''
            }
          ]
        },
        agenda
      }))

      handleClose()
    }
  }

  const handleCloseSelecionarVoucher = async () =>{
    handleCloseSelecionarConfirmarUsoVoucher()
    await consultaServicos()
    setState((prevState) => ({
      ...prevState,
      openModalSelecionarVoucher: false
    }))
  }

  const handleCloseSelecionarConfirmarUsoVoucher = () => {
    setState((prevState) => ({
      ...prevState,
      servicos_voucher_agendamento: [],
      modalConfirmarUsoVoucher: false,
      row: {}
    }))
  }

  const selecionarVoucher = async (row) => {
    const {agenda} = state

    let servicos_voucher_agendamento = []

    for (const produto of row.produtos) {
      for (const servico_agenda of agenda.servicos) {
        if(produto.produto_id === servico_agenda.servico_id){
          let index = servicos_voucher_agendamento.findIndex(param => param.item_pedido_id === servico_agenda.item_pedido_id)
          if(index === -1){
            servicos_voucher_agendamento.push({
              ...produto,
              item_pedido_id: servico_agenda.item_pedido_id
            })
          }
        }
      }
    }

    if(servicos_voucher_agendamento.length > 0){
      setState((prevState) => ({
        ...prevState,
        servicos_voucher_agendamento,
        modalConfirmarUsoVoucher: true,
        row
      }))
      return false
    }

    await continuaSelecionarVoucher(row)
  }

  

  const finalizarSelecaoVoucher = async () => {
    let {dadosTelaVoucherCredito, conta, vouchersCreditoSelecionados, agenda} = state

    let diferenca = parseFloat(dadosTelaVoucherCredito.diferenca.replaceAll(".", "").replace(",", "."))
    let totalVouchers = parseFloat(dadosTelaVoucherCredito.totalVouchers.replaceAll(".", "").replace(",", "."))

    if(totalVouchers > 0){
      if(diferenca > 0){
        let formas_vouchers = conta.parcelas.filter(param => parseInt(param.formapagto_id) === 9)

        if(conta.parcelas.length === formas_vouchers.length){
          conta.parcelas[dadosTelaVoucherCredito.parcela_index].valorparcela = dadosTelaVoucherCredito.totalVouchers
    
          conta.qtd_parcelas = 2
    
          conta.parcelas.push({
            id: '',
            baixado: true,
            datavencimento: moment(new Date()).format('YYYY-MM-DD'),
            datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
            valorparcela: real(diferenca),
            multajuros: real(0),
            formapagto_id: "",
            contfin_id: "",
            documento: "",
            habilita: "",
            num: 2,
            parcela_editada: false,
            index: 1,
            trava: false,
            vouchersCreditoSelecionados: [],
            vouchersCreditoSelecionadosString: '',
            vouchersProdutosSelecionados: [],
            vouchersProdutosSelecionadosString: ''
          })
        }else{
          conta.parcelas[dadosTelaVoucherCredito.parcela_index].valorparcela = real(totalVouchers)

          for (let i = 0; i < conta.parcelas.length; i++) {
            if(parseInt(conta.parcelas[i].formapagto_id) !== 9){
              let diferenca_div = diferenca / (conta.parcelas.length - formas_vouchers.length)

              conta.parcelas[i].valorparcela = real(parseFloat((diferenca_div).toFixed(2)))
            }
          }
        }

        setState((prevState) => ({
          ...prevState,
          dadosTelaVoucherCredito: {
            totalConta: real(totalVouchers),
            totalVouchers: real(totalVouchers),
            diferenca: real(0),
            parcela_index: 0
          }
        }))
      }
    }else{
      conta.parcelas[0].formapagto_id = ''
    }

    if(vouchersCreditoSelecionados.length > 0){

      let valor_servico_dif = []

      for (const voucherSelecionado of vouchersCreditoSelecionados) {
        let data_hoje = moment(new Date()).format('YYYY-MM-DD')
        let data_prom = voucherSelecionado.data_valid_prom
  
        if(moment(data_prom).isAfter(data_hoje)){

          for (const servico of agenda.servicos) {
            
            let resp = await consultarTabelaPrecoID(voucherSelecionado.padrao.tab_prom_id, servico.servico_id) //Tab prom

            if(!resp.preco_produto){
              resp = await consultarTabelaPrecoID(voucherSelecionado.padrao.tab_normal_id, servico.servico_id) //Tab normal
            }

            let valor_servico = parseFloat(servico.valor.replaceAll(".", "").replace(",", "."))
            
            let valor_maximo_tabela = 0

            if(resp.preco_produto){
              valor_maximo_tabela = resp.preco_produto.valor_maximo
            }else{
              valor_maximo_tabela = valor_servico
            }

            if(valor_servico > valor_maximo_tabela){
              valor_servico_dif.push({
                voucher_id: voucherSelecionado.voucher_id,
                numero: voucherSelecionado.numero,
                padrao: voucherSelecionado.padrao,
                valor_atual: valor_servico,
                valor_tabela_maximo: valor_maximo_tabela,
                valor_tabela_minimo: resp.preco_produto.valor_minimo,
                valor_atual_form: real(valor_servico),
                valor_tabela_form: real(valor_maximo_tabela),
                servico_id: servico.servico_id,
                servico: servico.servico,
                atendente: servico.atendente,
              })
            }
          }
        
        }
      }

      if(valor_servico_dif.length > 0){
        setState((prevState) => ({
          ...prevState,
          valor_servico_dif,
          modalValoresTabelaDif: true
        }))
      }
    }
  
    setState((prevState) => ({
      ...prevState,
      openModalSelecionarVoucherCredito: false,
      conta
    }))
  }

  const finalizarSelecaoVoucherProduto = async () => {
    let {dadosTelaVoucherCredito, conta} = state

    let diferenca = parseFloat(dadosTelaVoucherCredito.diferenca.replaceAll(".", "").replace(",", "."))
    let totalVouchers = parseFloat(dadosTelaVoucherCredito.totalVouchers.replaceAll(".", "").replace(",", "."))

    if(totalVouchers > 0){
      if(diferenca > 0){
        let formas_vouchers = conta.parcelas.filter(param => parseInt(param.formapagto_id) === 11)

        if(conta.parcelas.length === formas_vouchers.length){
          conta.parcelas[dadosTelaVoucherCredito.parcela_index].valorparcela = dadosTelaVoucherCredito.totalVouchers
    
          conta.qtd_parcelas = 2
    
          conta.parcelas.push({
            id: '',
            baixado: true,
            datavencimento: moment(new Date()).format('YYYY-MM-DD'),
            datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
            valorparcela: real(diferenca),
            multajuros: real(0),
            formapagto_id: "",
            contfin_id: "",
            documento: "",
            habilita: "",
            num: 2,
            parcela_editada: false,
            index: 1,
            trava: false,
            vouchersCreditoSelecionados: [],
            vouchersCreditoSelecionadosString: '',
            vouchersProdutosSelecionados: [],
            vouchersProdutosSelecionadosString: ''
          })
        }else{
          conta.parcelas[dadosTelaVoucherCredito.parcela_index].valorparcela = real(totalVouchers)

          for (let i = 0; i < conta.parcelas.length; i++) {
            if(parseInt(conta.parcelas[i].formapagto_id) !== 9){
              let diferenca_div = diferenca / (conta.parcelas.length - formas_vouchers.length)

              conta.parcelas[i].valorparcela = real(parseFloat((diferenca_div).toFixed(2)))
            }
          }
        }

        setState((prevState) => ({
          ...prevState,
          dadosTelaVoucherCredito: {
            totalConta: real(totalVouchers),
            totalVouchers: real(totalVouchers),
            diferenca: real(0),
            parcela_index: 0
          }
        }))
      }
    }else{
      conta.parcelas[0].formapagto_id = ''
    }

    setState((prevState) => ({
      ...prevState,
      modalSelecionarProdutosVoucher: false,
      conta
    }))
  }

  const selecionarVoucherCredito = async (row) => {
    let {conta, dadosTelaVoucherCredito, vouchersCreditoCliente} = state

    let vouchersCreditoSelecionados = conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersCreditoSelecionados
      
    let index = vouchersCreditoSelecionados.findIndex(param => param.voucher_id === row.id)
        
    let valor_total = parseFloat(dadosTelaVoucherCredito.totalConta.replaceAll(".", "").replace(",", "."))
    let totalVouchers = parseFloat(dadosTelaVoucherCredito.totalVouchers.replaceAll(".", "").replace(",", "."))
    let diferenca = parseFloat(dadosTelaVoucherCredito.diferenca.replaceAll(".", "").replace(",", "."))
    
    let index_voucher = vouchersCreditoCliente.findIndex(param => param.id === row.id)
        
    if(index === -1){
      let valor_a_ultilizar = 0
      totalVouchers += row.novo_saldo > diferenca ? diferenca : row.novo_saldo
      valor_a_ultilizar = row.novo_saldo > diferenca ? diferenca : row.novo_saldo
      vouchersCreditoCliente[index_voucher].novo_saldo_form = 'R$ ' + real(vouchersCreditoCliente[index_voucher].novo_saldo - valor_a_ultilizar)
      vouchersCreditoCliente[index_voucher].novo_saldo = vouchersCreditoCliente[index_voucher].novo_saldo - valor_a_ultilizar

      vouchersCreditoSelecionados.push({
        voucher_id: row.id,
        numero: row.numero,
        saldo: row.saldo,
        padrao: row.padrao,
        data_valid_prom: row.data_valid_prom,
        valor_a_ultilizar
      })

      dadosTelaVoucherCredito.diferenca = real(valor_total - totalVouchers)
      dadosTelaVoucherCredito.totalVouchers = real(totalVouchers)
    }else{

      vouchersCreditoCliente[index_voucher].novo_saldo_form = "R$ " + real(parseFloat(vouchersCreditoCliente[index_voucher].novo_saldo_form.replace('R$','').replaceAll(".", "").replace(",", ".")) + vouchersCreditoSelecionados[index].valor_a_ultilizar)
      vouchersCreditoCliente[index_voucher].novo_saldo= parseFloat(vouchersCreditoCliente[index_voucher].novo_saldo_form.replace('R$','').replaceAll(".", "").replace(",", ".")) + vouchersCreditoSelecionados[index].valor_a_ultilizar
      
      dadosTelaVoucherCredito.totalVouchers = real(parseFloat(dadosTelaVoucherCredito.totalVouchers.replace('R$','').replaceAll(".", "").replace(",", ".")) - vouchersCreditoSelecionados[index].valor_a_ultilizar)
      dadosTelaVoucherCredito.diferenca = real(valor_total - parseFloat(dadosTelaVoucherCredito.totalVouchers.replace('R$','').replaceAll(".", "").replace(",", ".")))
      
      conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersCreditoSelecionados.splice(index,1)
      vouchersCreditoSelecionados.splice(index,1)
    }

    let vouchersCreditoSelecionadosString = ''

    for (let i = 0; i < vouchersCreditoSelecionados.length; i++) {
      if((i + 1) === vouchersCreditoSelecionados.length){
        vouchersCreditoSelecionadosString += `${vouchersCreditoSelecionados[i].numero}`
      }else{
        vouchersCreditoSelecionadosString += `${vouchersCreditoSelecionados[i].numero} - `
      }
      
    }

    conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersCreditoSelecionados = vouchersCreditoSelecionados
    conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersCreditoSelecionadosString = vouchersCreditoSelecionadosString 
    
    if(vouchersCreditoSelecionados.length > 0){
      conta.parcelas[dadosTelaVoucherCredito.parcela_index].trava = true
      conta.parcelas[dadosTelaVoucherCredito.parcela_index].parcela_editada = true
    }else{
      conta.parcelas[dadosTelaVoucherCredito.parcela_index].trava = false
      conta.parcelas[dadosTelaVoucherCredito.parcela_index].parcela_editada = false
    }

    setState((prevState) => ({
      ...prevState,
      dadosTelaVoucherCredito,
      vouchersCreditoCliente,
      vouchersCreditoSelecionados,
      qtdParcelasEditado: 1,
      conta
    }))
  }

  const selecionarVoucherProduto = async (row) => {
    let {conta, dadosTelaVoucherCredito, vouchersCreditoCliente} = state
    
    let vouchersProdutosSelecionados = conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersProdutosSelecionados
      
    let index = vouchersProdutosSelecionados.findIndex(param => param.produto_voucher_id === row.id)
        
    let valor_total = parseFloat(dadosTelaVoucherCredito.totalConta.replaceAll(".", "").replace(",", "."))
    let totalVouchers = parseFloat(dadosTelaVoucherCredito.totalVouchers.replaceAll(".", "").replace(",", "."))
    // let diferenca = parseFloat(dadosTelaVoucherCredito.diferenca.replaceAll(".", "").replace(",", "."))
    
    // let index_voucher = vouchersCreditoCliente.findIndex(param => param.id === row.id)
        
    if(index === -1){
      let valor_a_ultilizar = 0
      totalVouchers += row.valor
      valor_a_ultilizar = row.valor
      // vouchersCreditoCliente[index_voucher].novo_saldo_form = 'R$ ' + real(vouchersCreditoCliente[index_voucher].novo_saldo - valor_a_ultilizar)
      // vouchersCreditoCliente[index_voucher].novo_saldo = vouchersCreditoCliente[index_voucher].novo_saldo - valor_a_ultilizar

      vouchersProdutosSelecionados.push({
        produto_voucher_id: row.id,
        numero: row.numero,
        valor: row.valor,
        valor_a_ultilizar
      })

      dadosTelaVoucherCredito.diferenca = real(valor_total - totalVouchers)
      dadosTelaVoucherCredito.totalVouchers = real(totalVouchers)
    }else{

      // vouchersCreditoCliente[index_voucher].novo_saldo_form = "R$ " + real(parseFloat(vouchersCreditoCliente[index_voucher].novo_saldo_form.replace('R$','').replaceAll(".", "").replace(",", ".")) + vouchersProdutosSelecionados[index].valor_a_ultilizar)
      // vouchersCreditoCliente[index_voucher].novo_saldo= parseFloat(vouchersCreditoCliente[index_voucher].novo_saldo_form.replace('R$','').replaceAll(".", "").replace(",", ".")) + vouchersProdutosSelecionados[index].valor_a_ultilizar
      
      dadosTelaVoucherCredito.totalVouchers = real(parseFloat(dadosTelaVoucherCredito.totalVouchers.replace('R$','').replaceAll(".", "").replace(",", ".")) - vouchersProdutosSelecionados[index].valor_a_ultilizar)
      dadosTelaVoucherCredito.diferenca = real(valor_total - parseFloat(dadosTelaVoucherCredito.totalVouchers.replace('R$','').replaceAll(".", "").replace(",", ".")))
      
      conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersCreditoSelecionados.splice(index,1)
      vouchersProdutosSelecionados.splice(index,1)
    }

    let vouchersProdutosSelecionadosString = ''

    for (let i = 0; i < vouchersProdutosSelecionados.length; i++) {
      if((i + 1) === vouchersProdutosSelecionados.length){
        vouchersProdutosSelecionadosString += `${vouchersProdutosSelecionados[i].numero}`
      }else{
        vouchersProdutosSelecionadosString += `${vouchersProdutosSelecionados[i].numero} - `
      }
    }

    conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersProdutosSelecionados = vouchersProdutosSelecionados
    conta.parcelas[dadosTelaVoucherCredito.parcela_index].vouchersProdutosSelecionadosString = vouchersProdutosSelecionadosString 
    
    if(vouchersProdutosSelecionados.length > 0){
      conta.parcelas[dadosTelaVoucherCredito.parcela_index].trava = true
      conta.parcelas[dadosTelaVoucherCredito.parcela_index].parcela_editada = true
    }else{
      conta.parcelas[dadosTelaVoucherCredito.parcela_index].trava = false
      conta.parcelas[dadosTelaVoucherCredito.parcela_index].parcela_editada = false
    }

    setState((prevState) => ({
      ...prevState,
      dadosTelaVoucherCredito,
      vouchersCreditoCliente,
      vouchersProdutosSelecionados,
      qtdParcelasEditado: 1,
      conta
    }))
  }

  const handleCloseModalBuscaAgendaCliente = () => {
    setState((prevState) => ({
      ...prevState,
      consultaRealizada: false,
      openModalBuscaAgendaCliente: false,
      clientesFiltrado: {
        list: []
      },
      cliente: {
        id: '',
        nome: '',
        cpf_cnpj: '',
        pessoa_id: ''
      },
      agendasCliente: {
        list: []
      },
      filtroAgendaCliente: {
        data_inicio: '',
        data_fim: ''
      }
    }))
  }

  const updateFieldClienteAgenda = async (event, value) => {
    if(!value){
      setState((prevState) => ({
        ...prevState,
        cliente: {
          cliente_id: '',
          nome: '',
          cpf: ''
        }
      }))

      return false
    }
        
    setState((prevState) => ({
      ...prevState,
      cliente: value
    }))
  }

  const buscarAgendaCliente = async () =>{
    const {cliente, filtroAgendaCliente} = state
  
    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      let dados = {
        pessoa_id: cliente.pessoa_id,
        unidade_id: acesso_atual[0].id
      }

      if(filtroAgendaCliente.data_inicio !== ''){
        if(filtroAgendaCliente.data_fim === ''){
          setState((prevState) => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a data final'
            }
          }))
          return
        }

        dados.data_inicio = filtroAgendaCliente.data_inicio
      }

      if(filtroAgendaCliente.data_fim !== ''){
        if(filtroAgendaCliente.data_inicio === ''){
          setState((prevState) => ({
            ...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a data inicial'
            }
          }))
          return
        }

        dados.data_fim = filtroAgendaCliente.data_fim
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/agendaAmbiente/buscaAgendaCliente`, dados, getToken())
    
      setState((prevState) => ({
        ...prevState,
        agendasCliente: {
          list: data.map(value => {
            return{
              id: value.id,
              os: value.item_pedido.pedido_servico.numero,
              status: value.situacao,
              servico: value.item_pedido.produto.descricao,
              sala: value.ambiente.nome,
              atendente: value.funcionario ? value.funcionario.fisica.nome : '',
              data: moment(value.data_hora_inicio).format('DD/MM/YYYY') + ' das ' + moment(value.data_hora_inicio).format('HH:mm') + ' as ' + moment(value.data_hora_fim).format('HH:mm'),
              data_hora_inicio: value.data_hora_inicio,
              data_hora_fim: value.data_hora_fim,
              tela: 'AGENDA',
              utilizado: true
            }
          })
        },
        consultaRealizada: true,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Consulta realizada!'
        }
      }))

      return
    } catch (error) {
      console.log(error)
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const viewAgenda = async (row) =>{
    let dia = moment(row.data_hora_inicio).format('YYYY-MM-DD')
    let hora = moment(row.data_hora_inicio).format('HH:mm')

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
    let agendas = await consultarAgendas(acesso_atual[0].id, dia, state.intervalo)

    montaListaAtendentes(agendas)

    localStorage.setItem('data_filtro_agenda', JSON.stringify(dia))
    localStorage.setItem('hora_filtro_agenda', JSON.stringify(hora))
    
    setState((prevState) => ({
      ...prevState,
      dataSelecionada: dia,
      dataSelecionadaTela: dia
    }))

    // document.getElementById(`hora-${moment(row.data_hora_inicio).format('HH:mm')}`).scrollIntoView()

    handleCloseModalBuscaAgendaCliente()
  }

  const updateFieldDataAgendaCliente = (event) => {
    const {filtroAgendaCliente} = state

    filtroAgendaCliente[event.target.name] = event.target.value

    setState((prevState) => ({
      ...prevState,
      filtroAgendaCliente
    }))
  }

  const updateFieldCancelamento = (event) =>{
    let {dadosCancelar} = state
    dadosCancelar[event.target.name] = event.target.value
    setState((prevState) => ({...prevState, dadosCancelar}))
  }

  const confirmarCortesia = (value) => {
    if(value){
      setState((prevState) => ({
        ...prevState,
        modal: {
          mensagem: '',
        },
        modalConfirmacaoCortesia: false
      }))
    }else{
      setState((prevState) => ({
        ...prevState,
        modal: {
          mensagem: '',
        },
        modalConfirmacaoCortesia: false
      }))
    }
  }

  const handleCloseValoresTabelaDif = () => {
    setState((prevState) => ({
      ...prevState,
      modalValoresTabelaDif: false
    }))
  }

  const confirmarValoresTabelaDif = (value) => {
    if(value){
      let {vouchersCreditoSelecionados, agenda, valor_servico_dif, conta, servicoAgenda} = state

      if(vouchersCreditoSelecionados.length === 1 && conta.parcelas.length === 1){
        for (let i = 0; i < agenda.servicos.length; i++) {
          const element = agenda.servicos[i];
          
          let index = valor_servico_dif.findIndex(param => param.servico_id === element.servico_id)
          
          if(index !== -1){
            element.valor = real(valor_servico_dif[index].valor_tabela_maximo)
            element.valor_total_maximo = real(valor_servico_dif[index].valor_tabela_maximo)
            element.valor_total_minimo = real(valor_servico_dif[index].valor_tabela_minimo)
          }

          agenda.servicos[i] = element
        }
                
        conta.valor_total = real(somarValores(agenda.servicos.filter(param => param.situacao !== 'DESMARCADO').map(value => {return parseFloat(value.valor.replaceAll(".", "").replace(",", "."))})))
        conta.valor_total_maximo = real(somarValores(agenda.servicos.filter(param => param.situacao !== 'DESMARCADO').map(value => {return parseFloat(value.valor_total_maximo.replaceAll(".", "").replace(",", "."))})))
        conta.valor_total_minimo = real(somarValores(agenda.servicos.filter(param => param.situacao !== 'DESMARCADO').map(value => {return parseFloat(value.valor_total_minimo.replaceAll(".", "").replace(",", "."))})))
        
        conta.parcelas[0].valorparcela = conta.valor_total
        conta.parcelas[0].valorparcela_anterior = conta.valor_total
        
        conta.parcelas[0].vouchersCreditoSelecionados[0].valor_a_ultilizar = conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")

        setState((prevState) => ({
          ...prevState,
          agenda
        }))
        
        if(servicoAgenda.servico_id !== ''){
          let index_diff = valor_servico_dif.findIndex(param => param.servico_id === servicoAgenda.servico_id)

          if(index_diff !== -1){
            servicoAgenda.valor = real(valor_servico_dif[index_diff].valor_tabela_maximo)
            servicoAgenda.valor_total_maximo = real(valor_servico_dif[index_diff].valor_tabela_maximo)
            servicoAgenda.valor_total_minimo = real(valor_servico_dif[index_diff].valor_tabela_minimo)
          }
        
          setState((prevState) => ({
            ...prevState,
            servicoAgenda
          }))
        }

      }else{
        setState((prevState) => ({
          ...prevState,
          tab: 0
        }))
      }

      handleCloseValoresTabelaDif()
    }else{
      handleCloseValoresTabelaDif()
    }
  }

  const confimarSelecaoVoucher = async (value) => {
    let {row, servicos_voucher_agendamento, agenda, servicoAgenda, conta} = state

    if(value){
      for (const servico of servicos_voucher_agendamento) {
        let index = agenda.servicos.findIndex(param => param.servico_id === servico.produto_id)

        if(index !== -1){
          agenda.servicos[index].voucher = true
        }

        if(servicoAgenda.servico_id === servico.produto_id){
          servicoAgenda.voucher = true
        }
      }

      agenda.informar_financeiro = true

      if(servicoAgenda.servico_id === ''){
        conta.parcelas = [
          {
            id: '',
            baixado: true,
            datavencimento: moment(new Date()).format('YYYY-MM-DD'),
            datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
            valorparcela: real(0),
            valorparcela_anterior: real(0),
            multajuros: real(0),
            formapagto_id: "",
            contfin_id: "",
            documento: "",
            habilita: "",
            num: 1,
            parcela_editada: false,
            index: 0,
            trava: false,
            vouchersCreditoSelecionados: [],
            vouchersCreditoSelecionadosString: '',
            vouchersProdutosSelecionados: [],
            vouchersProdutosSelecionadosString: ''
          }
        ]

        let valor_vouchers = 0
        let valor_nao_vouchers = 0 

        for (const servico of agenda.servicos) {
          if(servico.situacao !== 'DESMARCADO'){
            if(servico.voucher){
              valor_vouchers += parseFloat(servico.valor.replaceAll(".", "").replace(",", "."))
            }else{
              valor_nao_vouchers += parseFloat(servico.valor.replaceAll(".", "").replace(",", "."))
            }
          }
        }      

        if(valor_vouchers > 0){
          agenda.informar_financeiro = true
          if((parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) === 0)){
            conta.parcelas[0].valorparcela = real(valor_vouchers)
            conta.parcelas[0].valorparcela_anterior = real(valor_vouchers)
            conta.parcelas[0].trava = true
            conta.parcelas[0].parcela_editada = true
            conta.parcelas[0].formapagto_id = '9'
            conta.parcelas[0].baixado = true
            conta.parcelas[0].num = 1
            conta.parcelas[0].datavencimento = moment(new Date()).format('YYYY-MM-DD')
          }else if((parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) !== 0) && parseInt(conta.parcelas[0].formapagto_id) === 9){
            conta.parcelas[0].valorparcela = real(valor_vouchers)
            conta.parcelas[0].valorparcela_anterior = real(valor_vouchers)
          }else if((parseFloat(conta.parcelas[0].valorparcela.replaceAll(".", "").replace(",", ".")) === 0) && parseInt(conta.parcelas[0].formapagto_id) === 9){
            conta.parcelas[0].valorparcela = real(valor_vouchers)
            conta.parcelas[0].valorparcela_anterior = real(valor_vouchers)
          }else{
            conta.parcelas.unshift({
              id: '',
              baixado: true,
              datavencimento: moment(new Date()).format('YYYY-MM-DD'),
              datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
              valorparcela: real(valor_vouchers),
              valorparcela_anterior: real(valor_vouchers),
              multajuros: real(0),
              formapagto_id: 9,
              contfin_id: "",
              documento: "",
              habilita: "",
              num: 1,
              parcela_editada: true,
              index: 0,
              trava: true,
              vouchersCreditoSelecionados: [],
              vouchersCreditoSelecionadosString: '',
              vouchersProdutosSelecionados: [],
              vouchersProdutosSelecionadosString: ''
            })
  
            for (let i = 0; i < conta.parcelas.length; i++) {
              conta.parcelas[i].index = i
            }
          }

          setState((prevState) => ({
            ...prevState,
            qtdParcelasEditado: 1
          }))
        }

        if(valor_nao_vouchers > 0){
          if(conta.parcelas.length === 1){
            if(parseInt(conta.parcelas[0].formapagto_id) === 9){
              conta.parcelas.push({
                id: '',
                baixado: true,
                datavencimento: moment(new Date()).format('YYYY-MM-DD'),
                datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
                valorparcela: real(valor_nao_vouchers),
                valorparcela_anterior: real(valor_nao_vouchers),
                multajuros: real(0),
                formapagto_id: "",
                contfin_id: "",
                documento: "",
                habilita: "",
                num: conta.parcelas.length + 1,
                parcela_editada: false,
                index: conta.parcelas.length,
                trava: false,
                vouchersCreditoSelecionados: [],
                vouchersCreditoSelecionadosString: '',
                vouchersProdutosSelecionados: [],
                vouchersProdutosSelecionadosString: ''
              })
  
              for (let i = 0; i < conta.parcelas.length; i++) {
                conta.parcelas[i].index = i
              }
            }else{
              conta.parcelas[0].valorparcela = real(valor_nao_vouchers)
              conta.parcelas[0].valorparcela_anterior = real(valor_nao_vouchers)
            }
          }else{
            conta.parcelas[1].valorparcela = real(valor_nao_vouchers)
            conta.parcelas[1].valorparcela_anterior = real(valor_nao_vouchers)
          }
        }

        if(valor_vouchers > 0 & valor_nao_vouchers > 0){
          conta.parcelas = [
            {
              id: '',
              baixado: true,
              datavencimento: moment(new Date()).format('YYYY-MM-DD'),
              datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
              valorparcela: real(valor_vouchers),
              valorparcela_anterior: real(valor_vouchers),
              multajuros: real(0),
              formapagto_id: "9",
              contfin_id: "",
              documento: "",
              habilita: "",
              num: 1,
              parcela_editada: true,
              index: 0,
              trava: true,
              vouchersCreditoSelecionados: [],
              vouchersCreditoSelecionadosString: '',
              vouchersProdutosSelecionados: [],
              vouchersProdutosSelecionadosString: ''
            },
            {
              id: '',
              baixado: true,
              datavencimento: moment(new Date()).format('YYYY-MM-DD'),
              datavencimento_orig: moment(new Date()).format('YYYY-MM-DD'),
              valorparcela: real(valor_nao_vouchers),
              valorparcela_anterior: real(valor_nao_vouchers),
              multajuros: real(0),
              formapagto_id: "",
              contfin_id: "",
              documento: "",
              habilita: "",
              num: 2,
              parcela_editada: false,
              index: 1,
              trava: false,
              vouchersCreditoSelecionados: [],
              vouchersCreditoSelecionadosString: '',
              vouchersProdutosSelecionados: [],
              vouchersProdutosSelecionadosString: ''
            }
          ]
        }

        conta.qtd_parcelas = conta.parcelas.length
      }

      setState((prevState) => ({
        ...prevState,
        agenda,
        servicoAgenda,
        conta
      }))

      await continuaSelecionarVoucher(row, value, false)
    }else{
      await continuaSelecionarVoucher(row, value, false)
    }
  }

  const continuaSelecionarVoucher = async (row, utilizar_voucher, hidden) => {
    const {agenda, conta, servicoAgenda} = state
    
    agenda.voucher_id = row.id
    agenda.voucher = row.padrao_descricao + ' - Núm: ' + row.numero
    
    setState((prevState) => ({
      ...prevState,
      servicos: {
        list: row.produtos.filter(param => !param.utilizado && param.produto.servico).map(value => {
          return {
            id: value.produto.id,
            nome: value.produto.descricao,
            unidademedida: value.produto.unidademedida,
            itemCombo: value.produto.itemCombo,
            duracao: value.produto.servico.duracao,
            valor: value.valor,
            voucher: true
          }
        })
      },
      swapServicos: 'voucher',
      utilizar_voucher,
      agenda,
      conta,
      voucherSelecionado: row
    }))

    handleCloseSelecionarConfirmarUsoVoucher()
    setState((prevState) => ({
      ...prevState,
      openModalSelecionarVoucher: false
    }))

    await consultarFormaPagto(state.perm_alt_voucher_agenda)
    await consultarContasFinanceira()
      
    let produtos = row.produtos

    let produtos_diff = []

    console.log("utilizar_voucher: ", utilizar_voucher)

    if(utilizar_voucher){
      for (const servico of agenda.servicos) {
        const produto = produtos.filter(param => (param.produto_id === servico.servico_id && !param.utilizado))[0]

        console.log("produto: ", produto)

        if(produto){
          // servico.voucher = true
          if(parseFloat(servico.valor.replaceAll(".", "").replace(",", ".")) === produto.valor){
            // valor_voucher += produto.valor
          }else{
            produtos_diff.push({
              produto: servico.servico,
              valor_produto: servico.valor.replaceAll(".", "").replace(",", "."),
              valor_voucher: produto.valor,
              index: servico.index
            })
          }
        }else{

        }
      }
    }

    console.log(produtos_diff)

    if(produtos_diff.length > 0){
      let mensagem = '<p>Existem produtos de valores diferentes do voucher, deseja alterar os valores dos atendimentos?</p>'

      for (const produto of produtos_diff) {
        mensagem += `<p>Produto: ${produto.produto}, valor no voucher ${real(produto.valor_voucher)}, valor no atendimento ${real(produto.valor_produto)}</p>`
      }

      setState((prevState) => ({
        ...prevState,
        modalOpenConfirmacaoDifVoucher: true,
        modal: {
          mensagem
        },
        produtos_diff
      }))
    }

    if(servicoAgenda.servico_id !== ""){
      await addServico(hidden)
    }

    setState((prevState) => ({
      ...prevState,
      utilizar_voucher: true
    }))
  }

  const handleCloseModalAgendaAtendente = () => {
    setState((prevState) => ({
      ...prevState,
      openModalAgendaAtendente: false
    }))
  }

  const handleCloseSelecionarConfirmarAgendaIndisponivel = () => {
    setState((prevState) => ({
      ...prevState,
      modalConfirmarAgendaIndisponivel: !state.modalConfirmarAgendaIndisponivel,
      loadingSalva: false,
      travarBotao: false
    }))
  }

  const updateFieldSalaIndisponivel = (event, value, row) => {
    let {agendas_indisponivel} = state

    let index = agendas_indisponivel.findIndex(param => moment(param.data_agenda).format('DD/MM/YYYY') === row.data)

    if(!value){
      agendas_indisponivel[index].sala.id = ''
      agendas_indisponivel[index].sala.nome = ''
    }else{
      agendas_indisponivel[index].sala.id = value.id
      agendas_indisponivel[index].sala.nome = value.nome
    }
    
    setState((prevState) => ({
      ...prevState,
      agendas_indisponivel
    }))
  }

  const updateFieldAtendenteIndisponivel = (event, value, row) => {
    let {agendas_indisponivel} = state

    let index = agendas_indisponivel.findIndex(param => moment(param.data_agenda).format('DD/MM/YYYY') === row.data)

    if(!value){
      agendas_indisponivel[index].atendente.id = ''
      agendas_indisponivel[index].atendente.nome = ''
    }else{
      agendas_indisponivel[index].atendente.id = value.id
      agendas_indisponivel[index].atendente.nome = value.nome
    }
    
    setState((prevState) => ({
      ...prevState,
      agendas_indisponivel
    }))
  }

  const confirmarAgendamento = async (value) => {
    if(value){
      const {agenda, agendas_indisponivel } = state

      try {
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
  
        let dados = {
          ordem_id: agenda.ordem_id,
          unidade_id: acesso_atual[0].id,
          pessoa_id: agenda.cliente_id,
          observacao_geral: agenda.observacao_geral,
          servicos: agenda.servicos.map(servico => {
            return {
              agenda_id: servico.agenda_id,
              item_pedido_id: servico.item_pedido_id,
              produto_id: servico.servico_id,
              ambiente_id: servico.ambiente_id,
              atendente_id: servico.atendente_id,
              data_inicio: servico.data_inicio,
              hora_fim: servico.hora_fim,
              hora_inicio: servico.hora_inicio,
              duracao: servico.duracao,
              observacao: servico.observacao,
              obrigatoriedade: servico.obrigatoriedade,
              valor: parseFloat(servico.valor.replaceAll(".", "").replace(",", ".")),
              situacao: servico.situacao,
              editado: servico.editado,
              voucher: servico.voucher ? true : false,
              recorrencia: servico.recorrencia,
              enviar_alerta: servico.enviar_alerta,
              add_preparo: servico.add_preparo,
              add_limpeza: servico.add_limpeza,
              duracao_preparo: servico.duracao_preparo,
              duracao_limpeza: servico.duracao_limpeza,
              preparo_id: servico.preparo_id,
              limpeza_id: servico.limpeza_id,
              index_preparo: '',
              index_limpeza: '',
              dadosRecorrencia: {
                qtdRecorrencia: parseInt(servico.dadosRecorrencia.qtdRecorrencia),
                recorrenciaACada: parseInt(servico.dadosRecorrencia.recorrenciaACada),
                tipoRecorrencia: servico.dadosRecorrencia.tipoRecorrencia,
              },
            }
          }),
          agendas_indisponivel: agendas_indisponivel.map(value => {
            return {
              data_agenda: value.data_agenda,
              atendente_id: value.atendente.id,
              ambiente_id: value.sala.id,
              hora_inicio: value.hora_inicio,
              servico_id: value.servico_id 
            }
          })
        }

        if(dados.agendas_indisponivel.length === 0){
          delete dados.agendas_indisponivel
        }
    
        if(agenda.voucher_id !== ''){
          dados.voucher_id = agenda.voucher_id
        }
  
        if(dados.ordem_id === '' || !dados.ordem_id){
          delete dados.ordem_id
        }
  
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Cadastrando Agenda...'
          }
        }))

        const {data} = await axios.post(`${window._env_.REACT_APP_API_URL}/agendaAmbiente`, dados, getToken())
          
        localStorage.setItem('ultima_agenda', JSON.stringify(data.agendasResp[0].id))
  
        handleCloseModalAgendarServico()
  
        localStorage.setItem('data_filtro_agenda', JSON.stringify(dados.servicos[0].data_inicio))
        localStorage.setItem('hora_filtro_agenda', JSON.stringify(dados.servicos[0].hora_inicio))
        
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: 'Cadastro realizado com sucesso.'
          },
          modalConfirmarAgendaIndisponivel: false,
          agendas_indisponivel: [],
          loadingSalva: false
        }))
        
        handleModalCancelarNFSE()
        window.location.reload()
                
      } catch (error) {
        console.log(error)
        setState((prevState) => ({
          ...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          loadingSalva: false
        }))
      }
    }else{
      setState((prevState) => ({
        ...prevState,
        modalConfirmarAgendaIndisponivel: false,
        agendas_indisponivel: [],
        loadingSalva: false,
        travarBotao: false
      }))
    }
  }

  const editarPessoa = (url) => {
    window.open(url, '_blank');
  }

  const updateParcelaBaixado = (index) => {
    let {conta} = state

    if(conta.parcelas[index].formapagto_id === 9 || conta.parcelas[index].formapagto_id === 11){
      setState((prevState) => ({
        ...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Não é possivel remover o a baixa do voucher!'
        }
      }))

      return
    }

    if(conta.id === '' && !conta.parcelas[index].trava){
      conta.parcelas[index].baixado = !conta.parcelas[index].baixado
  
      setState((prevState) => ({
        ...prevState,
        conta
      }))
    }

  }

  const openPendenciasCliente = () => {
    setState((prevState) => ({
      ...prevState,
      openModalFinanceiroPendenteCliente: true
    }))
  }

  const handleCloseModalFinanceiroPendenteCliente = () => {
    setState((prevState) => ({
      ...prevState,
      openModalFinanceiroPendenteCliente: false
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/agendaAmbiente/AgendaAmbiente.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState((prevState) => ({...prevState,
            markdown: text
          }))
        })

      const helpPathCadastro = require("./../../help/agendaAmbiente/Cadastro.md")

      fetch(helpPathCadastro)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState((prevState) => ({...prevState,
            markdownCadastro: text
          }))
        })

      setState((prevState) => ({...prevState,
        alteraServico: false
      }))

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      let config = await consultarConfiguracao(acesso_atual[0].id)
      
      if(config){
        const perfil = JSON.parse(localStorage.getItem('perfil'))

        let permissoesContasPR = perfil.permissoes.filter(param => param.tela.modulo.slug === 'contas-pagar-receber')[0]
        let permissoesCaixa = perfil.permissoes.filter(param => param.tela.modulo.slug === 'caixa')[0]
        let perfilLogado = ''

        if (permissoesCaixa) {
          perfilLogado = 'caixa'
          setState((prevState) => ({...prevState,
            permissoesCaixa,
            perfilLogado: 'caixa'
          }))
        }

        if (permissoesContasPR) {
          perfilLogado = 'contas-pagar-receber'
          setState((prevState) => ({...prevState,
            permissoesContasPR,
            perfilLogado: 'contas-pagar-receber'
          }))
        }

        const caixa = JSON.parse(localStorage.getItem('caixa'))

        const ultimoCaixa = JSON.parse(localStorage.getItem('ultimoCaixa'))

        if(perfilLogado === 'contas-pagar-receber'){
          if(ultimoCaixa && !ultimoCaixa.fechado){
            setState((prevState) => ({...prevState, logado: { caixa: true } }))
          }else{
            setState((prevState) => ({...prevState,
              logado: {
                caixa: false
              }
            }))
          }

        }else{
          setState((prevState) => ({...prevState, logado: { caixa } }))
        }  

        if(ultimoCaixa && ultimoCaixa['status_atual.status'] === 'ABERTO'){
          setState((prevState) => ({...prevState,
            permissoesCaixa,
            perfilLogado: 'caixa'
          }))
        }

        const larguraColunas = JSON.parse(localStorage.getItem('larguraColunas'))

        if(larguraColunas){
          setState((prevState) => ({...prevState,
            larguraColunas
          }))
        }else{
          setState((prevState) => ({...prevState,
            larguraColunas: 150
          }))
        }

        setState((prevState) => ({...prevState,
          ultimoCaixa: ultimoCaixa || {}
        }))

        if(acesso_atual.length > 1){
          setState((prevState) => ({...prevState,
            travaUnidades: true,
            msgTrava: 'Selecionar apenas uma unidade!'
          }))
        }

        let pessoa_cadastrada =  JSON.parse(localStorage.getItem('pessoa_cadastrada'))
        
        if(pessoa_cadastrada){
          
          await consultaServicos()
          await consultarFormaPagto(config.perm_alt_voucher_agenda)
          await consultarContasFinanceira()
        
          setState((prevState) => ({...prevState,
            openModalAgendarServico: true,
            servicoAgenda: {
              servico: '',
              servico_id: '',
              atendente: '',
              atendente_id: '',
              ambiente: '',
              ambiente_id: '',
              data_inicio: moment().format('YYYY-MM-DD'),
              hora_inicio: '',
              hora_inicio_tela: '',
              hora_fim: '',
              hora_fim_tela: '',
              duracao: 0,
              duracao_tela: 0,
              background_color: '',
              color: '',
              observacao: '',
              index: '',
              obrigatoriedade: false,
              valor: real(0),
              valor_total_minimo: real(0),
              valor_total_maximo: real(0),
              itemCombo: [],
              situacao: 'CONFIRMADO',
              agenda_id: '',
              recorrencia: false,
              enviar_alerta: false,
              add_preparo: false,
              add_limpeza: false,
              duracao_preparo: 0,
              duracao_limpeza: 0,
              preparo_id: '',
              limpeza_id: '',
              index_preparo: '',
              index_limpeza: '',
              dadosRecorrencia: {
                recorrenciaACada: 1,
                tipoRecorrencia: 'd',
                qtdRecorrencia: ''
              }
            },
            agenda:{
              ordem_id: '',
              cliente_id: pessoa_cadastrada.pessoa_id,
              cliente: pessoa_cadastrada.cpf ? (pessoa_cadastrada.nome + (pessoa_cadastrada.sobrenome ? ` ${pessoa_cadastrada.sobrenome}` : '')) : pessoa_cadastrada.nomefantasia,
              situacao: 'CONFIRMADO',
              observacao_geral: '',
              servicos: [],
              informar_financeiro: false,
              voucher_id: '',
              voucher: ''
            },
            salas: {
              list: []
            },
            sala: {
              id: '',
              nome: '',
              descricao: ''
            },
            atendentes:{
              list: []
            },
            atendente:{
              nome: '',
              background_color: '',
              color: ''
            },
            cliente: {
              id: pessoa_cadastrada.pessoa_id,
              nome: pessoa_cadastrada.cpf ? (pessoa_cadastrada.nome + (pessoa_cadastrada.sobrenome ? ` ${pessoa_cadastrada.sobrenome}` : '')) : pessoa_cadastrada.nomefantasia,
              cpf_cnpj: pessoa_cadastrada.cpf ? pessoa_cadastrada.cpf : pessoa_cadastrada.cnpj,
              pessoa_id: pessoa_cadastrada.pessoa_id
            },
          }))

          localStorage.removeItem('pessoa_cadastrada');
          localStorage.removeItem('ultima_tela_cliente');
        }
        
        let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'agenda-ambiente')[0]
        
        setState((prevState) => ({...prevState,
          permissoes
        }))
    
        await consultarAmbientes()
  
        const data_filtro_agenda = JSON.parse(localStorage.getItem('data_filtro_agenda'))

        let {inicio_atendimento, fim_atendimento, intervalo} = config
      
        let dataSelecionada = ''
              
        if(data_filtro_agenda && data_filtro_agenda !== "Data inválida"){
          dataSelecionada = data_filtro_agenda
        }else{
          dataSelecionada = moment(new Date()).format('YYYY-MM-DD')
          
          localStorage.setItem('data_filtro_agenda', JSON.stringify(dataSelecionada))
          localStorage.setItem('hora_filtro_agenda', JSON.stringify(inicio_atendimento))
        }

        let agendas = await consultarAgendas(acesso_atual[0].id, dataSelecionada, intervalo)
        
        let arrayDias = []
        let data3Dias = moment(dataSelecionada).subtract(3, 'd').format('YYYY-MM-DD')

        arrayDias.push(data3Dias)

        for (let i = 0; i < 6; i++) {
          arrayDias.push(moment(data3Dias).add((i+1), 'd').format('YYYY-MM-DD'))
        } 
        
        let horas = []
        let hora = dataSelecionada + ' ' + inicio_atendimento
        let inicio = parseInt(inicio_atendimento.replace(':', ''))
        let fim = parseInt(fim_atendimento.replace(':', '')) 

        horas.push(inicio_atendimento) 
        
        for (let horaInicial = inicio; horaInicial < fim; horaInicial = parseInt(moment(hora).format('HH:mm').replace(':', ''))) {
          hora = moment(hora).add(intervalo, 'minutes')
          horas.push(moment(hora).format('HH:mm'))
        }

        const {servicoAgenda} = state

        servicoAgenda.data_inicio = moment().format('YYYY-MM-DD')

        setState((prevState) => ({...prevState,
          arrayDias,
          dataSelecionada,
          dataSelecionadaTela: dataSelecionada,
          horas,
          intervalo,
          servicoAgenda,
          loading: false
        }))

        if(inicio < parseInt(`${moment().format('HH')}:00`.replace(':', ''))){
          if(dataSelecionada === moment().format('YYYY-MM-DD')){
            // document.getElementById(`hora-${hora_filtro_agenda}`).scrollIntoView()
          }
        }

        setInterval(atualizaHora, 60000);

        const agenda_voucher = JSON.parse(localStorage.getItem('agenda_voucher'))

        if(agenda_voucher){
          let dia = moment(agenda_voucher.data_hora_inicio).format('YYYY-MM-DD')

          const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
          await consultarAgendas(acesso_atual[0].id, dia, intervalo)
      
          montaListaAtendentes(agendas)
      
          localStorage.setItem('data_filtro_agenda', JSON.stringify(dia))
          
          setState((prevState) => ({...prevState,
            dataSelecionada: dia,
            dataSelecionadaTela: dia
          }))
      
          // document.getElementById(`hora-${moment(agenda_voucher.data_hora_inicio).format('HH:mm')}`).scrollIntoView()
        
          localStorage.removeItem('agenda_voucher');
        }else{
          // var rolagem1 = document.querySelector('#rolagem1');
          // rolagem1.scrollTo({
          //   top: 0
          // })

          // var rolagem2 = document.querySelector('#rolagem2');
          // rolagem2.scrollTo({
          //   top: 0
          // })
        }   

        // setState((prevState) => ({...prevState,
        //   loading: false
        // }))

        const ultima_agenda = JSON.parse(localStorage.getItem('ultima_agenda'))
        
        if(ultima_agenda){
          if(document.getElementById(`agenda-${ultima_agenda}`)){
            document.getElementById(`agenda-${ultima_agenda}`).scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            })
            localStorage.removeItem('ultima_agenda')
          }
        }else{
          // console.log(`hora-${inicio_atendimento}`)
          // document.getElementById(`hora-${inicio_atendimento}`).scrollIntoView()
        }
        
      }else{
        setState((prevState) => ({...prevState,
          loading: false
        }))
      }
    };
    
    fetchData();
  }, []);

  const { arrayDias, dataSelecionada, ambientes, horas, ambientesFiltrado, permissoes, intervalo,
    filtro, atendentesFiltro, travaUnidades, coresStatus, dataSelecionadaTela, hora_atual, ambientesSelecionadas} = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState((prevState) => ({...prevState, openModalAjuda: true}))}}>
            <div>
              {state.msgTrava !== '' &&
                <Grid container spacing={1} direction="row">
                  <Grid item md={12} xs={12} sm={12}>
                    <h5>{state.msgTrava}</h5>
                  </Grid>
                </Grid>
              }
              {state.msgTrava === '' &&
                <React.Fragment>
                  <Grid container spacing={1} direction="row">
                    <Grid item md={2} xs={12} sm={12}>
                      <h1 className="titulo">Agenda</h1>
                    </Grid>
                    <Grid item md={8} xs={12} sm={12}>
                      <Grid container spacing={1} direction="row">
                        <Grid item md={12} xs={12} sm={12}>
                          <div className='centraliza'>
                            <div className='card_seta' onClick={() => voltar()}>
                              <ArrowBackIosIcon/>
                            </div>
                            {arrayDias.map((value, key) => {
                              return (
                                <div 
                                  className={`card_data ${value === dataSelecionadaTela ? 'selecionado' : ''}`}
                                  key={key} 
                                  onClick={() => selecionarDia(value)}
                                >
                                  <span>
                                    {moment(value).format('DD/MMM')}
                                  </span>
                                </div>
                              )
                            })}
                            <div className='card_seta' onClick={() => proximo()}>
                              <ArrowForwardIosIcon/>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid className='aling-center' item md={12} xs={12} sm={12}>
                          <h2 style={{fontWeight: 'bold'}} className="titulo">{moment(dataSelecionadaTela).format('dddd')}</h2>
                          <h6 style={{fontWeight: 'bold'}} className="titulo">{moment(dataSelecionadaTela).format('DD/MMM')}</h6>
                        </Grid>
                      </Grid>
                    </Grid>
                    {(permissoes.inserir && !travaUnidades) &&
                      <Grid item md={2} xs={12} sm={12}>
                        <Button color='primary' onClick={() => openCadServico()} size="small" className="btnCadastrar" variant="contained">
                          Agendar Serviço
                        </Button>
                      </Grid>
                    }
                  </Grid>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={2} xs={12} sm={3}>
                      <TextField 
                        type='date'
                        className="input" 
                        label="Data" 
                        variant="outlined" 
                        size="small" 
                        name="dataSelecionada" 
                        value={dataSelecionada} 
                        onChange={(e) => updateFieldData(e)} 
                        onBlur={(e) => carregarAgendas(e)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Atendente*"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="atendente"
                        value={filtro.atendente}
                        onChangeCapture={(e) => updateFiltroAtendente(e)}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value=''>Filtrar Atendente</option>
                        {atendentesFiltro.list.map((value, key) => {
                          return (
                            <option key={key} value={value.atendente_id}>{value.nome}</option>
                          )
                        })} 
                      </TextField>
                    </Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Status*"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="status"
                        value={filtro.status}
                        onChangeCapture={(e) => updateFiltroStatus(e)}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value=''>Filtrar Status</option>
                        {coresStatus.map((value, key) => {
                          return (
                            <option 
                              style={
                                {
                                  backgroundColor: value.cor_fundo,
                                  color: value.cor_fonte
                                }
                              } 
                              key={key} 
                              value={value.status}
                            >
                              {value.status}
                            </option>
                          )
                        })}
                      </TextField>
                    </Grid>
                    <Grid item md={4} xs={12} sm={3}>
                      <Autocomplete
                        multiple
                        limitTags={1}
                        id="multiple-limit-tags"
                        options={ambientes.list}
                        size="small"
                        value={ambientesSelecionadas}
                        onChange={(event, value) => updateFieldAmbiente(event, value)}
                        getOptionLabel={(option) => option.nome}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label="Ambientes*" InputLabelProps={{ shrink: true}} />
                        )}
                      />
                    </Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <Button 
                        onClick={() => openConsultaCliente()} 
                        size="small"
                        fullWidth
                        variant="contained" 
                        color="warning" 
                        startIcon={<PersonIcon />}
                      >
                        Buscar Clinte
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} direction="row">
                    {/* <Grid item md={1} xs={6} sm={3}>
                      <div className="cardApresentacao">
                        <p style={{fontSize: '12px', color: '#000', marginTop: '5px', fontWeight: 'bold'}}>LEGENDA:</p>
                      </div>
                    </Grid>
                    <Grid item md={1} xs={6} sm={3}>
                      <Tooltip title='AGENDADO' aria-label="add">
                        <div className="cardApresentacao" style={{ backgroundColor: '#ffff00' }}>
                          <p style={{fontSize: '12px', color: '#000', marginTop: '5px', fontWeight: 'bold'}}>AGENDADO</p>
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid item md={1} xs={6} sm={3}>
                      <Tooltip title='CONFIRMADO' aria-label="add">
                        <div className="cardApresentacao" style={{ backgroundColor: '#00a933' }}>
                          <p style={{fontSize: '12px', color: '#000', marginTop: '5px', fontWeight: 'bold'}}>CONFIRMADO</p>
                        </div>  
                      </Tooltip>
                    </Grid>
                    <Grid item md={2} xs={6} sm={3}>
                      <Tooltip title='EM ATENDIMENTO' aria-label="add">
                        <div className="cardApresentacao" style={{ backgroundColor: '#2a6099' }}>
                          <p style={{fontSize: '12px', color: '#FFF', marginTop: '5px', fontWeight: 'bold'}}>EM ATENDIMENTO</p>
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid item md={1} xs={6} sm={3}>
                      <Tooltip title='ATRASADO' aria-label="add">
                        <div className="cardApresentacao" style={{ backgroundColor: '#ff4000' }}>
                          <p style={{fontSize: '12px', color: '#FFF', marginTop: '5px', fontWeight: 'bold'}}>ATRASADO</p>
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid item md={1} xs={6} sm={3}>
                      <Tooltip title='FINALIZADO' aria-label="add">
                        <div className="cardApresentacao" style={{ backgroundColor: '#FFF', border: '1px solid #000' }}>
                          <p style={{fontSize: '12px', color: '#000', marginTop: '5px', fontWeight: 'bold'}}>FINALIZADO</p>
                        </div>
                      </Tooltip>
                    </Grid>
                    <Grid item md={1} xs={6} sm={3}>
                      <Tooltip title='FALTOU' aria-label="add">
                        <div className="cardApresentacao" style={{ backgroundColor: '#800000'}}>
                          <p style={{fontSize: '12px', color: '#FFF', marginTop: '5px', fontWeight: 'bold'}}>FALTOU</p>
                        </div>
                      </Tooltip>
                    </Grid> */}
                    <Grid item md={8} xs={6} sm={9}></Grid>
                    <Grid item md={4} xs={6} sm={3}>
                      <Grid container spacing={1} direction="row" style={{marginTop: 5}}>
                        <Grid item md={5} xs={6} sm={3} style={{textAlign: 'right'}}>
                          <p style={{fontWeight: 'bold', fontSize: 14, marginTop: 3}}>Tamanho da Agenda:</p>
                        </Grid>
                        <Grid item md={7} xs={6} sm={3}>
                          <Slider
                            size='small'
                            value={state.larguraColunas}
                            onChange={(event, newValue) => handleChangeLarguraAgenda(event, newValue)} 
                            aria-labelledby="continuous-slider"
                            valueLabelDisplay="auto"
                            min={50}
                            max={300}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className="grid-container">
                    <div className="grid">
                      <div style={{width: '70px', minWidth: '70px', maxWidth: '70px'}} className="grid-col grid-col--fixed-left">
                        <div className="grid-item grid-item-header">
                          <p style={{fontWeight: 'bold'}}>Horas</p>
                        </div>
                        {horas.map((hora, key) => {
                          return (
                            <div id={`hora-${hora}`} style={{
                              borderBottom: (
                                parseInt(hora.replace(':', '')) >= parseInt(hora_atual.replace(':','')) 
                                && 
                                parseInt(hora.replace(':', '')) <= (parseInt(hora_atual.replace(':','')) + (intervalo - 1))) 
                                ? (dataSelecionadaTela === moment().format('YYYY-MM-DD') ? '2px solid red' : '')  : '',
                            }} key={key} className="grid-item">
                              <p style={{fontWeight: 'bold'}}>{hora}</p>
                            </div>
                          )
                        })}
                      </div>
                      {ambientesFiltrado.list.sort((a, b) => (a.ordem > b.ordem) ? 1 : (b.ordem > a.ordem) ? -1 : 0).map((ambiente, index) => {
                        return (
                          <div 
                            key={index} 
                            className="grid-col"
                            style={{
                              width: state.larguraColunas,
                              minWidth: state.larguraColunas
                            }}
                          >
                            <div className="grid-item grid-item-header">
                              <p style={{fontWeight: 'bold'}}>{ambiente.nome}</p>
                            </div>
                            {horas.map((hora, key) => {
                              return (
                                <React.Fragment key={key}>
                                  {renderDiv(hora, ambiente, dataSelecionadaTela)}
                                </React.Fragment>
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </React.Fragment>
              }
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
        </div>
      }
      <Loading
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Listagem de Agendas"}
        handleClose={() => {setState((prevState) => ({...prevState, openModalAjuda: false}))}}
        markdown={state.markdown}
      />
      <ModalAjudaCadastro 
        open={state.openModalAjudaCadastro}
        tela={"Agendamento de Serviço"}
        handleClose={() => {setState((prevState) => ({...prevState, openModalAjudaCadastro: false}))}}
        markdown={state.markdownCadastro}
      />

      <ModalAgendarServico 
        open={state.openModalAgendarServico}
        handleClose={e => verificaFecharModal()} 
        agenda={state.agenda}
        clientes={state.clientesFiltrado.list}
        cliente={state.cliente}
        updateFieldCliente={(e, value) => updateFieldCliente(e, value)}
        servicos={state.servicos.list}
        servico={state.servico}
        updateFieldServico={(e, value) => updateFieldServico(e, value)}
        salas={state.salas.list}
        sala={state.sala}
        updateFieldSala={(e, value) => updateFieldSala(e, value)}
        updateFieldAtendente={(e, value) => updateFieldAtendente(e, value)}
        updateServicoAgenda={e => updateFieldServicoAgenda(e)}
        servicoAgenda={state.servicoAgenda}
        alteraServico={state.alteraServico}
        adicionarServico={e => addServico()}
        atendentes={state.atendentes.list}
        atendente={state.atendente}
        editarServico={(e) => editarServico(e, state.agenda) }
        removerServico={(e) => removerServico(e)}
        confirmar={(e) => salvarAgenda(e)}
        updateDuracao={(e) => updateDuracao(e)}
        updateFieldAgenda={(e) => updateFieldAgenda(e)}
        ajustaHoraFim={(e) => ajustaHoraFim(e)}
        buscarPessoa={(e) => buscarPessoa(e)}
        buscarSalasAtendentes={(e) => buscarSalasAtendentes(state.servicoAgenda, state.agenda)}
        updateFieldObrigatoriedade={(e) => updateFieldObrigatoriedade()}
        updateFieldEnviarAlerta={() => updateFieldEnviarAlerta()}
        inicio_atendimento={state.inicio_atendimento}
        fim_atendimento={state.fim_atendimento}
        handleChange={(event, newValue) => handleChange(event, newValue)}
        tab={state.tab}
        conta={state.conta}
        formasPagamento={state.formasPagamento.list}
        contasFinanceira={state.contasFinanceira.list}
        updateFieldParcela={(e, index) => updateFieldParcela(e, index)}
        marcaParcelas={(e, index) => marcaParcelas(e, index)}
        updateFieldConta={(e) => updateFieldConta(e)}
        recalcularParcela={(e, value) => recalcularParcela(e, value)}
        mudarParcelas={e => mudarParcelas(e)}
        verificaValor={(e) => verificaValor(e)}
        updateFieldInformarFinanceiro={e => updateFieldInformarFinanceiro()}
        situacoes={state.situacoes}
        openCadastroCliente= {e => openCadastroCliente()}
        verificaDataParcela={(e, index) => verificaDataParcela(e, index)}
        financeiro_fechado={state.financeiro_fechado}
        buscarVoucher={e => buscaVouchers(state.agenda.cliente_id, 'PRODUTO')}
        voucherSelecionado={state.voucherSelecionado}
        mostrarFinanceiro={state.mostrarFinanceiro}
        removerVoucher={e => removerVoucher()}
        updateFiltroPessoa={e => updateFiltroPessoa(e)}
        verificaCaixaInicial={e => verificaCaixaInicial(state)}
        consultarTabela={e => consultarTabela(e)}
        travarBotao={state.loadingSalva}
        logado={state.logado}
        permissoes={state.permissoes}
        vouchersCreditoSelecionadosString={state.vouchersCreditoSelecionadosString}
        openVouchers={(e) => openVouchers(e)}
        openVouchersProdutos={(e) => openVouchersProdutos(e)}
        intervalo={state.intervalo}
        possui_emissao_nfse={state.possui_emissao_nfse}
        updateFieldGerarNfseConta={(e) => updateFieldGerarNfseConta(e)}
        verificaCortesia={(e, index) => verificaCortesia(e, index)}
        valor_servico_dif={state.valor_servico_dif}
        swapServicos={() => swapServicos()}
        swapServicosValue={state.swapServicos}
        conta_anterior={state.conta_anterior}
        vouchersCreditoCliente={state.vouchersCreditoCliente}
        recorrencia={state.recorrencia}
        updateRecorrencia={() => updateRecorrencia()}
        dadosRecorrencia={state.dadosRecorrencia}
        updateFieldRecorrencia={(e) => updateFieldRecorrencia(e)}
        openModalAjudaCadastro={() => {setState((prevState) => ({...prevState, openModalAjudaCadastro: true}))}}
        perm_notificacao_agenda={state.perm_notificacao_agenda}
        editarPessoa={(e) => editarPessoa(e)}
        exibir_preparo_limpeza_agenda={state.exibir_preparo_limpeza_agenda}
        updateFieldAddPreparo={(e) => updateFieldAddPreparo(e)}
        updateFieldAddLimpeza={(e) => updateFieldAddLimpeza(e)}
        updateParcelaBaixado={(e) => updateParcelaBaixado(e)}
        financeiro_pendente={state.financeiro_pendente}
        openPendenciasCliente={() => openPendenciasCliente()}
        exibir_ids_pessoas={state.exibir_ids_pessoas}
      />

      <ModalSelecionaPessoa 
        open={state.modalSelecionaPessoa} 
        handleClose={e => handleCloseModalPessoa()} 
        updateFiltro={e => updateFiltro(e)}
        filtro={state.filtro}
        filtrocliente={state.filtrocliente}
        filtrarCliente={e => filtrarCliente()}
        loadingPessoas={state.loadingPessoas}
        pessoasFiltrado={state.clientesFiltrado.list}
        selecionarPessoa={e => selecionarPessoa(e)}
        desabilitaFlag={true}
        rows={state.rows}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
      />

      <ModalConfirmacao 
        open={state.modalOpen} 
        handleClose={e => handleClose(e)} 
        dados={state.modal} 
        confirmar={e => confirmarFecharModal(e)}
      />

      <ModalConfirmacaoData
        open={state.modalOpenConfirmacaoData} 
        handleClose={e => handleCloseData(e)} 
        dados={state.modal} 
        confirmar={e => confirmarFecharModalData(e)}
      />

      <ModalConfirmacaoDifVoucher 
        open={state.modalOpenConfirmacaoDifVoucher} 
        handleClose={e => handleClose(e)} 
        dados={state.modal} 
        confirmar={e => confirmarAlterarValores(e)}
      />

      <SelecionarVoucher 
        open={state.openModalSelecionarVoucher} 
        handleClose={e => handleCloseSelecionarVoucher(e)} 
        vouchersCliente={state.vouchersCliente}
        selecionarVoucher={e => selecionarVoucher(e)}
        voltar={e => handleCloseSelecionarVoucher(e)}
      />

      <SelecionarVoucherCredito
        open={state.openModalSelecionarVoucherCredito} 
        handleClose={e => finalizarSelecaoVoucher(e)} 
        voltar={e => finalizarSelecaoVoucher(e)}
        vouchersCliente={state.vouchersCreditoCliente}
        selecionarVoucherCredito={e => selecionarVoucherCredito(e)}
        vouchersCreditoSelecionados={state.vouchersCreditoSelecionados}
        dados={state.dadosTelaVoucherCredito}
      />

      <ModalBuscaAgendaCliente 
        open={state.openModalBuscaAgendaCliente}
        handleClose={e => handleCloseModalBuscaAgendaCliente(e)} 
        updateFieldCliente={(e, value) => updateFieldClienteAgenda(e, value)}
        updateFiltroPessoa={e => updateFiltroPessoa(e)}
        clientes={state.clientesFiltrado.list}
        cliente={state.cliente}
        buscarAgendaCliente={() => buscarAgendaCliente()}
        agendasCliente={state.agendasCliente.list}
        viewAgenda={e => viewAgenda(e)}
        filtroAgendaCliente={state.filtroAgendaCliente}
        updateFieldData={(e) => updateFieldDataAgendaCliente(e)}
        consultaRealizada={state.consultaRealizada}
      />

      <ModalCancelarNFSE 
        handleClose={e => handleModalCancelarNFSE()} 
        open={state.openModalCancelarNFSE}
        dadosCancelar={state.dadosCancelar}
        motivosCancelamento={state.motivosCancelamento.list}
        updateFieldCancelamento={(e) => updateFieldCancelamento(e)}
        // confirmar={(e) => continuarSalvar(e)}
      />
      
      <ModalConfirmacaoCortesia 
        open={state.modalConfirmacaoCortesia} 
        handleClose={e => handleClose(e)}
        dados={state.modal} 
        confirmar={e => confirmarCortesia(e)}
      />

      <ModalConfirmacaoCortesiaSalvar 
        open={state.modalConfirmacaoCortesiaSalvar} 
        handleClose={e => handleClose(e)}
        dados={state.modal} 
        confirmar={e => confirmarSalvar(e)}
      />

      <ModalValoresTabelaDif 
        open={state.modalValoresTabelaDif} 
        handleClose={e => handleCloseValoresTabelaDif(e)}
        dados={state.valor_servico_dif}
        confirmar={e => confirmarValoresTabelaDif(e)}
      />

      <ModalSelecionarProdutosVoucher 
        open={state.modalSelecionarProdutosVoucher } 
        handleClose={e => finalizarSelecaoVoucherProduto(e)} 
        voltar={e => finalizarSelecaoVoucherProduto(e)}
        voucherSelecionado={state.voucherSelecionado}
        produtos_voucher={state.voucherSelecionado.produtos || []}
        dados={state.dadosTelaVoucherCredito}
        vouchersProdutosSelecionados={state.vouchersProdutosSelecionados}
        selecionarVoucherProduto={e => selecionarVoucherProduto(e)}
      />

      <ModalConfirmarUsoVoucher 
        open={state.modalConfirmarUsoVoucher } 
        handleClose={e => handleCloseSelecionarConfirmarUsoVoucher(e)}
        dados={state.servicos_voucher_agendamento}
        confirmar={(e) => confimarSelecaoVoucher(e)}
      />

      <ModalAgendaAtendente 
        open={state.openModalAgendaAtendente } 
        handleClose={e => handleCloseModalAgendaAtendente(e)}
        agenda={state.agendaView}
      />

      <ModalConfirmacaoAgendaIndisponivel 
        open={state.modalConfirmarAgendaIndisponivel} 
        handleClose={e => handleCloseSelecionarConfirmarAgendaIndisponivel(e)}
        dados={state.modal} 
        agendas_indisponivel={state.agendas_indisponivel}
        atendentes={state.atendentes.list}
        salas={state.salas.list}
        updateFieldSala={(e, value, row) => updateFieldSalaIndisponivel(e, value, row)}
        updateFieldAtendente={(e, value, row) => updateFieldAtendenteIndisponivel(e, value, row)}
        confirmar={(e) => confirmarAgendamento(e)}
      />

      <ModalFinanceiroPendenteCliente 
        open={state.openModalFinanceiroPendenteCliente} 
        handleClose={e => handleCloseModalFinanceiroPendenteCliente(e)}
        financeiro_pendente={state.financeiro_pendente}
      />
    </React.Fragment>
  )
}

export default AgendaAmbiente